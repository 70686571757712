import * as pdfService from "@common/services/pdfService"
import envConfig from "@common/envConfig"

angular
    .module('DigiLean')
    .directive("improvementListResult", ['$filter', 'debounce', 'navigationService', 'suggestionService', '$uibModal', 'NgTableParams', 'excelService',
        function ($filter, debounce, navigationService, suggestionService, $uibModal, NgTableParams, excelService) {
            return {
                templateUrl: 'improvementListResult.html',
                restrict: 'E',
                scope: {
                    'filterParams': '<',
                    'options': '<',
                    'tableParamsChanged': '&?',
                    'columnListChanged': '&'
                },
                link: function (scope, elem, attrs) {
                    scope.columns = [];
                    scope.totalValues = 0;
                    scope.totalMatchingRecords = 0;
                    var isFilterLoaded = false;
                    var isOptionsLoaded = false;
                    // default values
                    scope.sorting = [];
                    scope.pageCount = 25;
                    scope.tableSorting = { lastModified: "desc" };
                    // Options
                    scope.showFilter = false;
                    scope.showFileGenerators = false;
                    scope.showArchived = false;
                    scope.isTeamsMode = envConfig.isTeamsMode;
                    
                    
                    function renderValue($scope, row) {
                        var item = this;
                        var field = item.field;
                        var value = row[field];
                        if (this.dataType === "date") {
                            return $filter('date')(value, "mediumDate");
                        }
                        if (this.dataType === "timestamp") {
                            return $filter('date')(value, "dd.MM.yyyy HH:mm:ss");
                        }
                        if (this.dataType === "bool") {
                            if (value === "true") {
                                return "<i class='far fa-check-square'></i>";
                            } else {
                                return "";
                            }
                        }
                        return value;
                    }
                     
                    scope.allColumns = suggestionService().getAllColumns(renderValue);

                    scope.hasProjectModule = navigationService().hasModule("PROJECT");
                    if (!scope.hasProjectModule) {
                        //remove project specific column
                        scope.allColumns = scope.allColumns.filter(c => c.field !== "projectName");
                    }


                    scope.timePeriod = null;
                    scope.$watch('filterParams', function (params) {
                        if (!params) return;
                        isFilterLoaded = true;
                        if (!params.filter) {
                            scope.filter = [];
                        } else {
                            scope.filter = params.filter;
                        }
                        if (params.timePeriod) {
                            scope.timePeriod = params.timePeriod;
                        }
                        if (params.sorting) {
                            scope.tableSorting = params.sorting;
                        }
                        if (params.listColumns) {
                            params.listColumns.forEach((c) => {
                                var column = $filter('filter')(scope.allColumns, { field: c.field })[0];
                                if (column) {
                                    column.visible = c.visible;
                                }
                            })
                        }

                        if (isFilterLoaded) {
                            scope.getValues();
                        }
                    });
                    function getTotalCount() {
                        suggestionService().getTotalNumberOfImprovements(scope.showArchived).then(function (count) {
                            scope.totalValues = count;
                        });
                    }

                    scope.$watch('options', function (options) {
                        if (!options) {
                            scope.showFileGenerators = true;
                            scope.showFilter = true;
                            scope.showArchived = true;
                        } else {
                            // Apply options
                            scope.showFileGenerators = options.showFileGenerators;
                            scope.showFilter = options.showFilter;
                            scope.showArchived = options.showArchived;
                        }
                        isOptionsLoaded = true;
                        getTotalCount();
                        scope.getValues();
                    });


                    scope.filterChanged = function (filter) {
                        scope.filter = filter
                        scope.getValues();
                    }

                    scope.openSuggestion = function (suggestionInfo) {
                        suggestionService().get(suggestionInfo.id).then(function (suggestion) {
                            var modalInstance = $uibModal.open({
                                backdrop: 'static',
                                templateUrl: 'fullImprovementInfoForm.html',
                                controller: 'fullImprovementModalController',
                                windowClass: 'fullSuggestion-modal-window',
                                resolve: {
                                    suggestion: function () {
                                        return suggestion;
                                    },
                                    activeTab: function () {
                                        return "Info";
                                    }
                                }
                            });
                            modalInstance.result.then(function (updatedSuggestion) {
                                scope.tableParams.reload();
                            });
                        });

                    }
                    scope.exportToPDF= function () {
                        var columns = getColumns();
                        var dbParams = {
                            page: 1,
                            count: 5000,
                            sorting: scope.sorting,
                            filters: scope.filter,
                            columns: columns,
                            timePeriod: scope.timePeriod
                        }
                        pdfService.createImprovementListReport(dbParams); 
                    }

                    scope.exportToExcel = function () {
                        var columns = getColumns();
                        var dbParams = {
                            page: 1,
                            count: 5000,
                            sorting: scope.sorting,
                            filters: scope.filter,
                            columns: columns,
                            timePeriod: scope.timePeriod
                        }
                        excelService().exportImprovementValues(dbParams); 
                    }

                    scope.getValueForElement = function (element, item) {
                        var sourceColumn = element.sourceColumn;
                        var value = item[sourceColumn];
                        return value;
                    }

                    scope.getValues = function (params) {
                        if (!isOptionsLoaded || !isFilterLoaded) return;
                        scope.buildColumns();
                        scope.tableParams = new NgTableParams({
                            sorting: scope.tableSorting,
                            count: scope.pageCount,
                        },
                        {
                            getData: scope.getTableValues
                        });
                    }

                    function removeArchivedInFilter() {
                        if(scope.filter) { 
                            scope.filter.forEach((f) => {
                                if(f.sourceColumn === "status") {
                                    f.items.forEach((item) => {
                                        if(item.value === "Archived") {
                                            f.items.splice(f.items.indexOf(item), 1);
                                        }
                                    })
                                } else {
                                    const archivedFilter = {
                                        sourceColumn: "status",
                                        operator: "InList",
                                        items: ["Suggested", "Planned", "InProgress", "Implemented", "Evaluated"]
                                    }
                                    scope.filter.push(archivedFilter)
                                }
                            })
                        } else {
                            scope.filter = [];
                            const archivedFilter = {
                                sourceColumn: "status",
                                operator: "InList",
                                items: ["Suggested", "Planned", "InProgress", "Implemented", "Evaluated"]
                            }
                            scope.filter.push(archivedFilter)
                        }
                    }

                    scope.getTableValues = function (params) {

                        var sorting = [];
                        // Build sortExpression for database to be able to deserialize, becuase NgTableParams.sorting is a dynamic object.
                        for (var propt in params._params.sorting) {
                            sorting.push({
                                property: propt,
                                direction: params._params.sorting[propt]
                            });
                        }
                        scope.sorting = sorting;
                        // Resolve paging;
                        if (scope.tableSorting != params._params.sorting) {
                            scope.tableSorting = params._params.sorting;
                            raiseTableParamsChanged();
                        }
                        //TODO test if both new and old filters works

                        if (!scope.showArchived) {
                            removeArchivedInFilter();
                        }

                        // We will build params based on the built-in NgTableParams + our sorting array
                        var dbParams = {
                            page: params._params.page,
                            count: params._params.count,
                            sorting: scope.sorting,
                            filters: scope.filter,
                            timePeriod: scope.timePeriod
                        }
                        return suggestionService().getTableValues(dbParams).then(function (data) { 
                            scope.totalMatchingRecords = data.total;
                            params.total(data.total); // recal. page nav controls
                            // check values
                            data.values.forEach(value => {
                                value.isOverdue = isOverdue(value.dueDate, value.statusImplementedDate);
                            });
                            return data.values;

                        });
                    }

                    var raiseTableParamsChanged = function () {
                        if (!scope.tableParamsChanged) return;
                        // Raise settings changeHandler
                        var tableParamsSettings = {
                            sorting: scope.tableSorting,
                            pageCount: scope.pageCount
                        }
                        scope.tableParamsChanged({ params: tableParamsSettings });

                    }

                    scope.buildColumns = function (datasource) {
                        scope.columns = $filter('filter')(scope.allColumns, { visible: true });
                    }

                    var getColumns = function() {
                        var columns = [];
                        scope.columns.forEach((c) => {
                            columns.push({
                                field : c.field
                            });
                        })
                        return columns;
                    }

                    scope.selectColumn = function (column) {
                        column.visible = !column.visible;
                        scope.columnListChanged({
                            listColumns: {
                                listColumns: scope.allColumns
                            }
                        });
                        scope.buildColumns();
                    }

                    function isOverdue(date, implementedDate) {
                        // Overdue check

                        if (!date) return false;
                        if (implementedDate) return false;
                        var duration = moment.duration(moment().diff(moment(date)));
                        var days = duration.asDays();
                        if (days > 1) {
                            return true;
                        }
                        return false;
                    }

                    // Event handling
                    const getDataDebounce = debounce(() => {
                        if (scope.tableParams) {
                            scope.tableParams.reload();
                        }
                    }, 1000, false);
                    scope.subscribe("ImprovementTitleChanged", function (improvementTitle) {
                        refreshIfExists(improvementTitle.suggestionId);
                    });
                    scope.subscribe("ImprovementResponsibleChanged", function (improvementResponsible) {
                        refreshIfExists(improvementResponsible.suggestionId);
                    });
                    scope.subscribe("ImprovementUpdated", function (suggestion) {
                        refreshIfExists(suggestion.id);
                    });
                    scope.subscribe("NewImprovement", function (suggestion) {
                        getDataDebounce();
                    });

                    scope.subscribe("ImprovementChangedStatus", async function (status) {
                        refreshIfExists(status.suggestionId);
                    });

                    scope.subscribe("ImprovementPriorityChanged", function (priority) {
                        refreshIfExists(priority.suggestionId);
                    });

                    scope.subscribe("ImprovementDeleted", function (suggestion) {
                        refreshIfExists(suggestion.suggestionId);
                    });

                    var refreshIfExists = function(suggestionId) {
                        var isItemInList = scope.tableParams.data.find((item) => item.id == suggestionId);
                        if (isItemInList) getDataDebounce();
                    }

                }

            }
        }]);



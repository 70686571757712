import type { DigiLeanAuthUser } from "@common/model/types"
import { authUser, getAuthUser } from "@common/stores/userStore"
import { handleErrorsAspNet } from "@common/services/error/aspNetErrorHandling"
import digiLeanOidc from "@common/services/auth/digileanOidc"

import envConfig from "@common/envConfig"
import { AspNetErrorResponse } from "@common/model/aspNetErrorResponse"
import { getConnectionId } from "@common/services/systemHub"

const baseUrl = envConfig.backendUrl

let authUserInfo: DigiLeanAuthUser
authUser.subscribe(auser =>  {
    authUserInfo = auser
})

const jsonContentType = "application/json"

const get = <T>(url: string) => {
    const req = createRequest(url, "get", jsonContentType)
    return http<T>(req)
}
const post = <T>(url: string, data: any) => {
    const req = createRequest(url, "post", jsonContentType, data)
    return http<T>(req)
}
const postFile = <T>(url: string, file: Blob) => {
    let fd = new FormData()
    fd.append('file', file)
    return postForm(url, fd)
}

const postForm = async <T>(url: string, formData: FormData) => {
    const fullUrl = `${baseUrl}/${url}`
    const bearer = getBearer()
    const connectionId = getConnectionId()
    
    const req: RequestInit = {
        method: "POST",
        headers: {
            "Authorization": bearer!,
            "Connectionid": connectionId
        },
        body: formData,
    }
    
    const res = await fetch(fullUrl, req).catch((error) => {
        let errorFetchMsg = "Error posting file"
        console.error(error.message)
        throw new Error(errorFetchMsg)
    })
    return resHandler(res)
}

const put = <T>(url: string, data?: any) => {
    const req = createRequest(url, "put", jsonContentType, data)
    return http<T>(req)
}

const remove = <T>(url: string) => {
    const req = createRequest(url, "delete")
    return http<T>(req)
}

const createRequest = (url: string, method: string, contentType?: string, data?: any) => {
    const bearer = getBearer()
    const connectionId = getConnectionId()
    const args: RequestInit = {
        method,
        headers: { "Connectionid": connectionId }
    }
    if (contentType) {
        if (args.headers)
            args.headers["Content-Type"] = contentType
    }
    if (bearer) {
        if (args.headers)
            args.headers["Authorization"] = bearer
    }
    if (data) {
        if (contentType === jsonContentType)
            args.body = JSON.stringify(data)
        else
            args.body = data
    }
    
    const fullUrl = `${baseUrl}/${url}`
    return new Request(fullUrl, args)
}

async function http<T>(request: RequestInfo): Promise<T> {
    let errorFetchMsg
    const res = await fetch(request)
    .catch((error) => {
        errorFetchMsg = "Error fetching"
        console.error(error.message)
        throw new Error(errorFetchMsg)
    })
    return resHandler(res)
}

const resHandler = async (res: Response) => {
    let errorFetchMsg: string
    if (res.ok) {
        const contentType = res.headers.get("content-type")
        if (res.status === 200 || res.status === 201) {
            
            if (contentType) {
                if (contentType.includes("application/json")) {
                    const json = await res.json()
                    return json
                }
                // pdf or octet-stream
                if (contentType.includes("application/")) {
                    const file = await res.arrayBuffer()
                    return file
                }
            }
            const text = await res.text()
            return text
        }
        else {
            return ""
        }
    } else {
        console.error(`${res.statusText} (${res.status})`)
        
        errorFetchMsg = "Server returned error"
        
        if (res.status == 401) {
            console.error("Status 401, token might be expired")
            digiLeanOidc.silentRenew()
            throw new BackendHttpError("Not authorized", res.status)
        }
        if (res.status >= 400 && res.status < 500) {
            try {
                const pd = await res.json() as AspNetErrorResponse
                console.log(pd)
                const errors = handleErrorsAspNet(pd)
                if (errors && errors.length > 0)
                    errorFetchMsg = errors.join(",")
            }
            catch (ex) {
                console.debug(ex);
            }
            
        } else {
            const message = await res.text()
            console.log(message)
        }
        
        throw new BackendHttpError(errorFetchMsg, res.status)
    }
}

const getBearer = () => {
    if (authUserInfo.token) {
        return `Bearer ${authUserInfo.token}`
    } else { // might be issues on initial load
        authUserInfo = getAuthUser()
        if (authUserInfo.token)
            return `Bearer ${authUserInfo.token}`
        else
            console.error("BackendHttp:: No token found")
    }
}

export class BackendHttpError extends Error {
    status = 0

    constructor(message: string, status: number) {
        super(message)
        this.status = status
    }
}

export default { get, post, postFile, postForm, put, delete: remove }
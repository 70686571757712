﻿import envConfig from "@common/envConfig";


angular.module("DigiLean").factory('fileService', ['$http',
    function ($http) {
        function fileService() {
            var resource = "/files";
            var resourceUrl = envConfig.webApiUrl + resource;

            return {
                getFilesForTask: function (taskId) {
                    resourceUrl = resourceUrl + "/getFilesForTask/" + taskId;
                    return $http.get(resourceUrl).then(function (result) {
                        return result.data;
                    });
                },

                getTest: function() {
                    return "test";
                }
            }
        }
        return fileService;
    }]);

import {LitElement, html} from "lit"
import {customElement} from "lit/decorators.js"
import envConfig from "@common/envConfig"
import toaster from "@common/components/toast"

@customElement('app-config-debug')
export class AppConfigDebug extends LitElement {
    render() {
        const configFormatted = JSON.stringify(envConfig, null, 4)
        
        return html`
            <label>config</label><br>
            <textarea cols="90" rows="15" .value=${configFormatted}></textarea>
            <br>
            <div>
                <button @click=${() => toaster.info("<strong>Test Info</strong><br>Testing lower case stuff and more", 100)}>Toast Info</button>
                <button @click=${() => toaster.loading("Test Toast Type Loading 🏃🏻")}>Toast Loading</button>
                <button @click=${() => toaster.success("Test Toast Type Success ❤️", 100)}>Toast Success</button>
                <button @click=${() => toaster.warning("Test Toast Type Warning")}>Toast Warning</button>
                <button @click=${() => toaster.error("Test Toast Type Error 🤮")}>Toast Error</button>
            </div>
        `
    }
}
﻿import envConfig from "@common/envConfig";


angular.module("DigiLean").factory('activityService', ['$rootScope', '$http',
    function ($rootScope, $http) {
        function activityService() {
            var resource = "/activitylog";
            var resourceUrl = envConfig.webApiUrl + resource;

            return {
                
                getLog: function () {
                    var url = resourceUrl + "/getlog";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getDailyFeed: function (filter) {
                    var url = resourceUrl + "/getDailyFeed";
                    return $http.post(url,filter).then(function (result) {
                        return result.data;
                    });
                },
                getLogForActivity: function (activityRequest) {
                    var url = resourceUrl + "/getLogForActivity";
                    return $http.post(url, activityRequest).then(function (result) {
                        return result.data;
                    });
                },
                getLogForStrategy: function (activityRequest) {
                    var url = resourceUrl + "/getLogForStrategy";
                    return $http.post(url, activityRequest).then(function (result) {
                        return result.data;
                    });
                },
                
                getActivityLast24hours: function () {
                    var url = resourceUrl + "/getActivityLast24hours";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                add: function (activity) {
                    return $http.post(resourceUrl, activity).then(function (result) {
                        return result.data;
                    });
                }

            }
        }
        return activityService;
    }]);

﻿import envConfig from "@common/envConfig";

angular.module('DigiLean').factory('signupService', ['$http','$translate',
    function ($http, $translate) {
        function signupService() {
            var resource = "/signup";
            var resourceUrl = envConfig.webApiUrl + resource;
            return {
                newTrialCustomerSetup: function (trialAccountSetup) {
                    var url = resourceUrl + "/NewTrialCustomerSetup/";
                    return $http.post(url, trialAccountSetup).then(function (result) {
                        return result.data;
                    });
                },
                getTrialStatus: function () {
                    var url = resourceUrl + "/GetTrialStatus";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getActiveTrialStatus: function () {
                    var url = resourceUrl + "/GetActiveTrialStatus";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getConvertedTrialStatus: function () {
                    var url = resourceUrl + "/GetConvertedTrialStatus";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },       

            }
        }
        return signupService;
    }]);

 
import envConfig from "@common/envConfig";

angular.module("DigiLean").factory('a3templateService', ['$rootScope', '$http',
    function ($rootScope, $http) {
        function a3Service() {
            var resource = "/a3template";
            var resourceUrl = envConfig.webApiUrl + resource;

            return {
                get: function(id) {
                    const url = resourceUrl + "/" + id;
                    return $http.get(url).then(function(result) {
                        return result.data;
                    })
                },

                getList: function() {
                    return $http.get(resourceUrl).then(function(result) {
                        return result.data;
                    });
                },

                getPublicTemplates: function() {
                      var url = resourceUrl + "/getPublicTemplates";
                    return $http.get(url).then(function(result) {
                        return result.data;
                    });
                },
                getPublicTemplate: function(id) {
                    const url = resourceUrl + "/getPublicTemplate/" + id
                    return $http.get(url).then(function(result) {
                        return result.data;
                    })
                },
                deleteTemplate: function(id) {
                    var url = resourceUrl + "/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                addTemplate: function(template) {
                    return $http.post(resourceUrl, template).then(function(result) {
                        return result.data;
                    });
                },
                downloadTemplate: function(id) {
                    var url = resourceUrl + "/downloadTemplate/" + id;
                    return $http.post(url).then(function(result) {
                        return result.data;
                    });
                },
                updateTemplate: function(template) {
                    var url = resourceUrl + "/updateTemplate/" + template.id;
                    return $http.put(url, template).then(function(result) {
                        return result.data;
                    });
                },

                publishTemplate: function(id) {
                    var url = resourceUrl + "/publish/" + id;
                    return $http.put(url).then(function(result) {
                        return result.data;
                    });
                },
                unPublishTemplate: function(id) {
                    var url = resourceUrl + "/unpublish/" + id;
                    return $http.put(url).then(function(result) {
                        return result.data;
                    });
                },
                updateSortOrder: function (templateId, sortOrders) {
                    var url = resourceUrl + "/updateSortOrder/" + templateId;
                    return $http.put(url, sortOrders).then(function (result) {
                        return result.data;
                    });
                },

                getVersion: function (versionId) {
                    var url = resourceUrl + "/getversion/" + versionId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                addStep: function (templateId, step) {
                    var url = resourceUrl + "/" + templateId + "/addstep/";
                    return $http.post(url, step).then(function (result) {
                        return result.data;
                    });
                },

                updateStep: function(step) {
                    var url = resourceUrl + "/updatestep/" + step.id;
                    return $http.put(url, step).then(function(result) {
                        return result.data;
                    });
                },

                deleteStep: function(stepid) {
                    var url = resourceUrl + "/DeleteStep/" + stepid;
                    return $http.delete(url).then(function(result) {
                        return result.data;
                    });
                },

                saveLayout: function(templateId, layout){
                    var url = resourceUrl + "/"+ templateId + "/saveLayout";
                    return $http.post(url, layout).then(function(result){
                        return result.data;
                    });
                }
            }
        }
        return a3Service;
    }]);                                

﻿import * as dashboardService from "@common/services/dashboardService"
import * as staticFileService from "@common/shared/staticFileService"
import * as boardService from "@common/services/boardService"
import toaster from "@common/components/toast"

var DigiLean = angular.module('DigiLean'); DigiLean.controller('newFlexiboardController', ['$scope', '$state', '$stateParams', '$translate', '$uibModal', 'navigationService', 'authService', 'deviationService','modalService',
    function ($scope, $state, $stateParams, $translate, $uibModal, navigationService, authService, deviationService, modalService) {

        $scope.static = staticFileService;
        $scope.selectedTemplate = null;
        $scope.templates = [];
        $scope.isAdmin = authService.hasRole("Admin");
        $scope.isBoardDesigner = authService.hasRole("BoardDesigner");
        var assetId = parseInt($stateParams.assetid); //default 0
        var strategyGoalId = parseInt($stateParams.strategygoalid); //default 0
        $scope.source = $stateParams.source;
        
        function changeTemplate(template) {

            $scope.selectedTemplate = template;
        }

        $scope.smartActionListTemplate = createSmartActionListTemplate();
        $scope.weeklyBoardTemplate = createWeeklyBoardTemplate();
        $scope.yearlyBoardTemplate = createYearlyTemplate();
        $scope.kanbanBoardTemplate = createKanbanBoardTemplate();
        $scope.emptyBoardTemplate = createEmptyTemplate();
        $scope.eightWasteTemplate = {
            boardType: "EightWasters"
        };
        $scope.performanceBoardTemplate = {
            boardType: "Performance"
        };

        // Used for performanceboard
        deviationService().getAllDeviationTypes().then(function(deviationTypes) {
            $scope.deviationTypes = _.sortBy(deviationTypes, 'id');
        });

        if ($scope.isAdmin) {
            changeTemplate($scope.emptyBoardTemplate);
        } else {
            changeTemplate($scope.weeklyBoardTemplate);
        }

        var loadtemplates = function () {
            $scope.isLoading = true;
            $scope.templates = [];
            boardService.getTemplates().then(function (templates) {
                templates.forEach((t) => {
                    if(t.boardType == 'Dashboard'){
                        if($scope.isAdmin || $scope.isBoardDesigner){
                            $scope.templates.push(t);
                        }
                    } else {
                        $scope.templates.push(t);
                    }
                });
                $scope.isLoading = false;
            });

        }
        loadtemplates();


        $scope.selectedTemplateChanged = function (template) {
            changeTemplate(template);
        }
        $scope.selectedEightWastersTemplate = function () {
            $scope.selectedTemplate = $scope.eightWasteTemplate;
        }

        $scope.selectedPerformanceBoardTemplate = function () {
            $scope.selectedTemplate = $scope.performanceBoardTemplate;
        }

        $scope.message = "";

        function associateWithStrategyGoal(boardId) {
            if (strategyGoalId && strategyGoalId > 0 && boardId) {
                boardService.addStrategyGoalToBoard(boardId, strategyGoalId).then(function (saved) {
                });
            }
        }

        function associateDashboardWithStrategyGoal(id) {
            if (strategyGoalId && strategyGoalId > 0 && id) {
                dashboardService.addStrategyGoalToDashboard(id, strategyGoalId).then(function (saved) {
                });
            }
        }

        function createSmartActionListTemplate() {
            return {
                boardType: "SmartActionList",
                name: $translate.instant("BOARD_SMARTACTIONLIST"),
                description: $translate.instant("BOARD_SMARTACTIONLIST_DESCRIPTION"),
            }
        }

        function createTaskBoard() {
            var template = $scope.selectedTemplate;
            var templateId = null;
            var operation = "newTaskBoard"
            if (template.id) {
                operation = "newTaskBoardFromTemplate"
                templateId = template.id;
            }
            var savetemplate = {
                templateId: templateId,
                board: {
                    name: template.name,
                    boardType: template.boardType,
                    settings: template.settings
                },
                columns: [],
                rows: []
            };
            // set attributes
            if (template.attributes) savetemplate.attributes = template.attributes;
            // Parse columns
            angular.forEach(template.columns, function (col) {
                savetemplate.columns.push(
                    {
                        name: col.name,
                        weekdayName: col.weekdayName,
                        sortOrder: col.sortOrder,
                        categoryStyle: JSON.stringify(col.categoryStyle)
                    });
            });
            angular.forEach(template.rows, function (row) {
                savetemplate.rows.push(
                    {
                        name: row.name,
                        sortOrder: row.sortOrder,
                        categoryStyle: JSON.stringify(row.categoryStyle),
                        weekdayName: row.weekdayName
                    });
            });
            
            var options = {
                dashboardAsTemplateId: null,
                assetId: assetId,
                operation: operation,
                template: savetemplate,
            }
            var modalInstance = $uibModal.open({
                backdrop: 'static',
                animation: true,
                templateUrl: 'newFlexiboardConfiguration.html',
                controller: 'newFlexiboardConfigurationController',
                resolve: {
                    options: function () {
                        return options;
                    }
                }
            });
            modalInstance.result.then(function (result) {
                if (operation == "newTaskBoard") {
                    associateWithStrategyGoal(result.board.id);
                    $scope.publish("SendBoardCreated", result);
                    $state.go('boarddesigner', { id: result.board.id, mode: "admin" });
                } else {
                    associateWithStrategyGoal(result.id);
                    $scope.publish("SendBoardCreated", result);
                    $state.go('boarddesigner', { id: result.id, mode: "admin" });
                }

            });
        }

        function getDeviationTypeId(index) {
            if (!$scope.deviationTypes || $scope.deviationTypes.length == 0) return 0;
            if ($scope.deviationTypes.length > index) {
                return $scope.deviationTypes[index].id;
            } else {
                return $scope.deviationTypes[0].id;
            }
        }
        function createPerformanceBoard() {
            if(navigationService().hasModule("DEVIATION")){
                var template = $scope.selectedTemplate;
                var savetemplate = {
                    board: {
                        name: "Performance Board",
                        assetId: null,
                        boardType: template.boardType,
                        settings: template.settings
                    },
                    columns: [],
                    rows: []
    
                };
                savetemplate.columns.push(
                    {
                        name: "Safety",
                        sortOrder: 0,
                        categoryStyle: JSON.stringify({ // settings for column
                            deviationTypeId: getDeviationTypeId(0),
                            apps: [
                                {
                                    type: "greencross",
                                },
                                {
                                    type: "pareto",
                                },
                                {
                                    type: "deviationActionListResult",
                                },
                            ]
                        })
                    }
                );
                savetemplate.columns.push(
                    {
                        name: "Quality",
                        sortOrder: 1,
                        categoryStyle: JSON.stringify({ // settings for column
                            deviationTypeId: getDeviationTypeId(1),
                            apps: [
                                {
                                    type: "Q",
                                },
                                {
                                    type: "pareto",
                                },
                                {
                                    type: "deviationActionListResult",
                                },
                            ]
                        })
                    }
                );
                savetemplate.columns.push(
                    {
                        name: "Cost",
                        sortOrder: 2,
                        categoryStyle: JSON.stringify({ // settings for column
                            deviationTypeId: getDeviationTypeId(2),
                            apps: [
                                {
                                    type: "C",
                                },
                                {
                                    type: "pareto",
                                },
                                {
                                    type: "deviationActionListResult",
                                },
                            ]
                        })
                    }
                );
                savetemplate.columns.push(
                    {
                        name: "Delivery",
                        sortOrder: 3,
                        categoryStyle: JSON.stringify({ // settings for column
                            deviationTypeId: getDeviationTypeId(3),
                            apps: [
                                {
                                    type: "D",
                                },
                                {
                                    type: "pareto",
                                },
                                {
                                    type: "deviationActionListResult",
                                },
                            ]
                        })
                    }
                );
                savetemplate.columns.push(
                    {
                        name: "People",
                        sortOrder: 4,
                        categoryStyle: JSON.stringify({ // settings for column
                            deviationTypeId: getDeviationTypeId(4),
                            apps: [
                                {
                                    type: "P",
                                },
                                {
                                    type: "pareto",
                                },
                                {
                                    type: "deviationActionListResult",
                                },
                            ]
                        })
                    }
                );
                var options = {
                    dashboardAsTemplateId: null,
                    assetId: assetId,
                    operation: "newPerformanceBoard",
                    template: savetemplate,
                    includeCustomGroup: false,
                    includeProject: false
                }
                var modalInstance = $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    templateUrl: 'newFlexiboardConfiguration.html',
                    controller: 'newFlexiboardConfigurationController',
                    resolve: {
                        options: function () {
                            return options;
                        }
                    }
                });
                modalInstance.result.then(function (result) {
                    $state.go('board', { id: result.board.id, mode: 'admin' }, { reload: false });
                });
            } else {
            swal({
                title: "",
                text: $translate.instant('BOARD_ACTIVATE_DEVIATION_MODULE'),
                icon: "warning",
                buttons: { ok: { text: $translate.instant('COMMON_OK'), className: "sa-digilean-button" } },
                dangerMode: true,
                closeOnClickOutside: true,
                closeOnEsc: true
            })
        }
            
            
        }

        function createYearlyBoard() {
            var template = $scope.selectedTemplate;
            var templateId = null;
            var operation = "newTaskBoard"
            if (template.id) {
                operation = "newTaskBoardFromTemplate"
                templateId = template.id;
            }
            var savetemplate = {
                templateId: templateId,
                board: {
                    name: template.name,
                    boardType: template.boardType,
                    settings: template.settings
                },
                columns: [],
                rows: []
            };
            // set attributes
            if (template.attributes) savetemplate.attributes = template.attributes;

            angular.forEach(template.rows, function (row) {
                savetemplate.rows.push(
                    {
                        name: row.name,
                        sortOrder: row.sortOrder,
                        categoryStyle: JSON.stringify(row.categoryStyle),
                        weekdayName: row.weekdayName
                    });
            });
            var options = {
                dashboardAsTemplateId: null,
                assetId: assetId,
                operation: operation,
                template: savetemplate,
            }
            var modalInstance = $uibModal.open({
                backdrop: 'static',
                animation: true,
                templateUrl: 'newFlexiboardConfiguration.html',
                controller: 'newFlexiboardConfigurationController',
                resolve: {
                    options: function () {
                        return options;
                    }
                }
            });
            modalInstance.result.then(function (result) {
                if (operation == "newTaskBoard") {
                    associateWithStrategyGoal(result.board.id);
                    $scope.publish("SendBoardCreated", result);
                    $state.go('boarddesigner', { id: result.board.id, mode: "admin"});
                } else {
                    associateWithStrategyGoal(result.id);
                    $scope.publish("SendBoardCreated", result);
                    $state.go('boarddesigner', { id: result.id, mode: "admin" });
                }
            });
        }


        
        $scope.deleteTemplate = function (template) {

            var modalOptions = {
                closeButtonText: $translate.instant("COMMON_CANCEL"),
                actionButtonText: $translate.instant("COMMON_DELETE"),
                headerText: $translate.instant("COMMON_DELETE") + ' ' + template.name + '?',
                bodyText: $translate.instant("ADMIN_DELETED_BOARD_TEMPLATE_DESCRIPTION")
            };

            modalService.showModal({}, modalOptions).then(function (result) {
                if (template.boardType == "Dashboard") {
                    dashboardService.deleteTemplate(template.id).then(function () {
                        $translate('ADMIN_MESSAGE_TEMPLATE_DELETED').then(function (msg) {
                            toaster.success(msg);
                        });
                        $scope.selectedTemplate = null;
                        loadtemplates();
                        //$scope.publish("SendBoardDeleted", board.id);
                    });
                } else {
                    boardService.deleteTemplate(template.id).then(function () {
                        $translate('ADMIN_MESSAGE_TEMPLATE_DELETED').then(function (msg) {
                            toaster.success(msg);
                        });
                        $scope.selectedTemplate = null;
                        loadtemplates();
                        //$scope.publish("SendBoardDeleted", board.id);
                    });
                }

            });
        };



        $scope.ok = function () {

            switch ($scope.selectedTemplate.boardType) {
                case "Dashboard":
                    if ($scope.selectedTemplate.id) {
                        createFlexiboard($scope.selectedTemplate.id, "createFromTemplate");
                    }
                    break;
                case "SmartActionList":
                    if ($scope.selectedTemplate.id) {
                        createSmartActionListFromTemplate($scope.selectedTemplate.id);
                    } else {
                        createSmartActionList(null);
                    }

                    break;
                case "EmptyFlexiboard":
                    createFlexiboard(null);
                    break;
                case "Yearly":
                    createYearlyBoard();
                    break;
                case "EightWasters":
                    createFlexiboard(222);
                    break;
                case "Performance":
                    createPerformanceBoard();
                    break;
                default:
                    createTaskBoard();
                    break;
            }

        }

        function createSmartActionListFromTemplate(templateId) {

            var options = {
                dashboardAsTemplateId: null,
                templateId: templateId,
                assetId: null,
                operation: "newSmartActionListFromTemplate",
                template: null,
            }
            var modalInstance = $uibModal.open({
                backdrop: 'static',
                animation: true,
                templateUrl: 'newFlexiboardConfiguration.html',
                controller: 'newFlexiboardConfigurationController',
                resolve: {
                    options: function () {
                        return options;
                    }
                }
            });
            modalInstance.result.then(function (result) {
                associateWithStrategyGoal(result.board.id);
                $state.go('board', { id: result.board.id, mode: 'admin' }, { reload: false });
            });
        }

        function createSmartActionList() {
            var savetemplate = {
                board: {
                    name: $translate.instant('DASHBOARD_SMARTACTIONLIST'),
                    assetId: null,
                    boardType: "SmartActionList",
                    settings: JSON.stringify({
                        IsWeeklyBoard: false
                    })
                },
                columns: [
                    {
                        name: $translate.instant("COMMON_TITLE"),
                        sortOrder: 0,
                        attributeType: "title"
                    },
                    {
                        name: $translate.instant("COMMON_RESPONSIBLE"),
                        sortOrder: 1,
                        attributeType: "responsible"
                    },
                    {
                        name: $translate.instant("COMMON_SUBTASKS"),
                        sortOrder: 2,
                        attributeType: "subtasks"
                    },
                    {
                        name: $translate.instant("COMMON_DUE_DATE"),
                        sortOrder: 3,
                        attributeType: "duedate"
                    },
                    {
                        name: $translate.instant("COMMON_STATUS"),
                        sortOrder: 4,
                        attributeType: "status"
                    },
                    {
                        name: $translate.instant("IMPROVEMENT_PRIORITY"),
                        sortOrder: 5,
                        attributeType: "text",
                        settings: JSON.stringify({
                            usePredefinedLabels: true,
                            predefinedLabels: [
                                { title: "default", fillColor: "#ffffff", background: "#f1f1f1", color: "#f1f1f1" },
                                { title: $translate.instant("COMMON_HIGH"), fillColor: "#FEE4DF", background: "#E9573F", color: "#ffffff" },
                                { title: $translate.instant("COMMON_MEDIUM"), fillColor: "#FFF6DF", background: "#FCBB42", color: "#ffffff" },
                                { title: $translate.instant("COMMON_LOW"), fillColor: "#DFF3FB", background: "#3BAFDA", color: "#ffffff" }
                            ]
                        })
                    }],

                tasks: [
                    {
                        sortOrder: 0,
                        title: $translate.instant("BOARD_EXAMPLE_TASK_1")
                    },
                    {
                        sortOrder: 1,
                        title: $translate.instant("BOARD_EXAMPLE_TASK_2")
                    }
                ],
                cells: [
                ]
            };

            var options = {
                dashboardAsTemplateId: null,
                assetId: assetId,
                operation: "newSmartActionList",
                template: savetemplate,
            }
            var modalInstance = $uibModal.open({
                backdrop: 'static',
                animation: true,
                templateUrl: 'newFlexiboardConfiguration.html',
                controller: 'newFlexiboardConfigurationController',
                resolve: {
                    options: function () {
                        return options;
                    }
                }
            });
            modalInstance.result.then(function (result) {
                associateWithStrategyGoal(result.board.id);
                $state.go('board', { id: result.board.id, mode: 'admin' }, { reload: false });
            });
        }
        // 222
        function createFlexiboard(templateId, operation) {
            var options = {
                assetId: assetId,
                operation: "newFlexiboard"
            }
            if (templateId) {
                options = {
                    dashboardAsTemplateId: templateId,
                    assetId: assetId,
                    operation: operation,
                    includeCustomGroup: false,
                    includeProject: true
                }
            }
            var modalInstance = $uibModal.open({
                backdrop: 'static',
                animation: true,
                templateUrl: 'newFlexiboardConfiguration.html',
                controller: 'newFlexiboardConfigurationController',
                resolve: {
                    options: function () {
                        return options;
                    }
                }
            });
            modalInstance.result.then(function (result) {
                associateDashboardWithStrategyGoal(result.id);
                $state.go('flexiboard', { id: result.id, mode: 'admin' }, { reload: false });
                $scope.publish("SendDashboardCreated", result);
            });
        }


        function createEmptyTemplate() {
            var translations = {
                FLEXIBOARD_EMPTY_TEMPLATE_DESCRIPTION: $translate.instant("FLEXIBOARD_EMPTY_TEMPLATE_DESCRIPTION"),
                FLEXIBOARD_EMPTY_TEMPLATE: $translate.instant("FLEXIBOARD_EMPTY_TEMPLATE")
            }
            var template = {

                boardType: "EmptyFlexiboard",
                name: translations.FLEXIBOARD_EMPTY_TEMPLATE,
                description: translations.FLEXIBOARD_EMPTY_TEMPLATE_DESCRIPTION


            };
            return template;
        }

        function createYearlyTemplate() {
            var translations = {
                BOARD_YEARLY_BOARD: $translate.instant("BOARD_YEARLY_BOARD"),
                BOARD_YEARLY_BOARD_DESCRIPTION: $translate.instant("BOARD_YEARLY_BOARD_DESCRIPTION")
            }


            var template = {
                boardType: "Yearly",
                name: translations.BOARD_YEARLY_BOARD,
                description: translations.BOARD_YEARLY_BOARD_DESCRIPTION,
                settings: JSON.stringify({
                    IsWeeklyBoard: false
                }),
                columns: [],
                rows: []
            }

            template.rows.push(
                {
                    name: $translate.instant("COMMON_CATEGORY") + " A",
                    sortOrder: 0,
                    categoryStyle: {
                        backgroundColor: "#f9f9f9",
                        foregroundColor: "#676a6c"
                    }
                });
            template.rows.push(
                {
                    name: $translate.instant("COMMON_CATEGORY") + " B",
                    sortOrder: 1,
                    categoryStyle: {
                        backgroundColor: "#f9f9f9",
                        foregroundColor: "#676a6c"
                    }
                });

            template.rows.push(
                {
                    name: $translate.instant("COMMON_CATEGORY") + " C",
                    sortOrder: 2,
                    categoryStyle: {
                        backgroundColor: "#f9f9f9",
                        foregroundColor: "#676a6c"
                    }
                });
            return template;
        }

        function createWeeklyBoardTemplate() {
            var translations = {
                BOARD_WEEKLY_BOARD: $translate.instant("BOARD_WEEKLY_BOARD"),
                BOARD_WEEKLY_BOARD_DESCRIPTION: $translate.instant("BOARD_WEEKLY_BOARD_DESCRIPTION"),
                BOARD_NEW_BOARD: $translate.instant("BOARD_NEW_BOARD"),
                COMMON_CATEGORY: $translate.instant("COMMON_CATEGORY"),
                COMMON_MONDAY: $translate.instant("COMMON_MONDAY"),
                COMMON_TUESDAY: $translate.instant("COMMON_TUESDAY"),
                COMMON_WEDNESDAY: $translate.instant("COMMON_WEDNESDAY"),
                COMMON_THURSDAY: $translate.instant("COMMON_THURSDAY"),
                COMMON_FRIDAY: $translate.instant("COMMON_FRIDAY"),
            }


            var template = {
                boardType: "Weekly",
                name: translations.BOARD_WEEKLY_BOARD,
                description: translations.BOARD_WEEKLY_BOARD_DESCRIPTION,
                settings: JSON.stringify({
                    IsWeeklyBoard: true
                }),
                columns: [],
                rows: []
            }
            template.columns.push(
                {
                    name: translations.COMMON_MONDAY,
                    weekdayName: "Monday",
                    sortOrder: 0,
                    categoryStyle: {
                        backgroundColor: "#0182BD",
                        foregroundColor: "#FFFFFF"
                    }
                });
            template.columns.push(
                {
                    name: translations.COMMON_TUESDAY,
                    weekdayName: "Tuesday",
                    sortOrder: 1,
                    categoryStyle: {
                        backgroundColor: "#0182BD",
                        foregroundColor: "#FFFFFF"
                    }
                });
            template.columns.push(
                {
                    name: translations.COMMON_WEDNESDAY,
                    weekdayName: "Wednesday",
                    sortOrder: 2,
                    categoryStyle: {
                        backgroundColor: "#0182BD",
                        foregroundColor: "#FFFFFF"
                    }
                });
            template.columns.push(
                {
                    name: translations.COMMON_THURSDAY,
                    weekdayName: "Thursday",
                    sortOrder: 3,
                    categoryStyle: {
                        backgroundColor: "#0182BD",
                        foregroundColor: "#FFFFFF"
                    }
                });
            template.columns.push(
                {
                    name: translations.COMMON_FRIDAY,
                    weekdayName: "Friday",
                    sortOrder: 4,
                    categoryStyle: {
                        backgroundColor: "#0182BD",
                        foregroundColor: "#FFFFFF"
                    }
                });
            template.rows.push(
                {
                    name: translations.COMMON_CATEGORY + " A",
                    sortOrder: 0,
                    categoryStyle: {
                        backgroundColor: "#DBEDCD",
                        foregroundColor: "#0182BD"
                    }
                });
            template.rows.push(
                {
                    name: translations.COMMON_CATEGORY + " B",
                    sortOrder: 1,
                    categoryStyle: {
                        backgroundColor: "#FBEED2",
                        foregroundColor: "#0182BD"
                    }
                });
            template.rows.push(
                {
                    name: translations.COMMON_CATEGORY + " C",
                    sortOrder: 2,
                    categoryStyle: {
                        backgroundColor: "#EED2D7",
                        foregroundColor: "#0182BD"
                    }
                });


            return template;

        }

        function createKanbanBoardTemplate() {

            var template = {
                boardType: "Kanban",
                name: $translate.instant("BOARD_KANBAN_BOARD"),
                description: $translate.instant("BOARD_KANBAN_BOARD_DESCRIPTION"),
                settings: JSON.stringify({
                    IsWeeklyBoard: false
                }),
                columns: [],
                rows: []
            }
            template.columns.push(
                {
                    name: $translate.instant("BOARD_TO_DO"),
                    sortOrder: 0,
                    categoryStyle: {
                        backgroundColor: "#E3EDF8",
                        foregroundColor: "#2E404F"
                    }
                });
            template.columns.push(
                {
                    name: $translate.instant("COMMON_IN_PROGRESS"),
                    sortOrder: 1,
                    categoryStyle: {
                        backgroundColor: "#C5DBF0",
                        foregroundColor: "#2E404F"
                    }
                });
            template.columns.push(
                {
                    name: $translate.instant("BOARD_WAITING"),
                    sortOrder: 2,
                    categoryStyle: {
                        backgroundColor: "#3480BD",
                        foregroundColor: "#E7E6E6"
                    }
                });
            template.columns.push(
                {
                    name: $translate.instant("BOARD_DONE"),
                    sortOrder: 3,
                    categoryStyle: {
                        backgroundColor: "#15507f",
                        foregroundColor: "#E7E6E6"
                    }
                });
            template.rows.push(
                {
                    name: $translate.instant("COMMON_HIGH"),
                    sortOrder: 0,
                    categoryStyle: {
                        backgroundColor: "#EFA390",
                        foregroundColor: "#264653"
                    }
                });
            template.rows.push(
                {
                    name: $translate.instant("COMMON_NORMAL"),
                    sortOrder: 1,
                    categoryStyle: {
                        backgroundColor: "#8B8B8B",
                        foregroundColor: "#E3EDF8"
                    }
                });

            template.rows.push(
                {
                    name: $translate.instant("COMMON_LOW"),
                    sortOrder: 2,
                    categoryStyle: {
                        backgroundColor: "#b1b1b1",
                        foregroundColor: "#E3EDF8"
                    }
                });

            return template;
        }

    }]);

import envConfig from "@common/envConfig";

angular.module("DigiLean").factory('threeCService', [ '$http',
    function ( $http) {
        function threeCService() {
            var resource = "/threec";
            var resourceUrl = envConfig.webApiUrl + resource;

            return {

                getLogInfo: function (boardId) {
                    var url = resourceUrl + "/getLogInfo/" + boardId;               
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getItems: function (boardId, tableParams) {
                    var url = resourceUrl + "/getItems/" + boardId;               
                    return $http.post(url, tableParams).then(function (result) {
                        return result.data;
                    });
                },

                getItem: function (id) {
                    var url = resourceUrl + "/getItem/" + id;               
                    return $http.post(url).then(function (result) {
                        return result.data;
                    });
                },

                createItem: function (boardId, item) {
                    var url = resourceUrl + "/createItem/" + boardId;
                    return $http.post(url, item).then(function (result) {
                        return result.data;
                    });
                },

                createLog: function (options) {
                    var url = resourceUrl + "/createLog";
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },

                updateConcern: function (id, concern) {
                    var url = resourceUrl + "/updateConcern/" + id;
                    return $http.put(url, concern).then(function (result) {
                        return result.data;
                    });
                },
                updateStatus: function (id, status) {
                    var url = resourceUrl + "/updateStatus/" + id;
                    return $http.put(url, status).then(function (result) {
                        return result.data;
                    });
                },
                updateCell: function (cell) {
                    var url = resourceUrl + "/updateCell";
                    return $http.put(url, cell).then(function (result) {
                        return result.data;
                    });
                },
            }
        }
        return threeCService;
    }]);
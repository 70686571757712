﻿import envConfig from "@common/envConfig";

angular.module('DigiLean').factory('learningService', ['$http',
    function ($http) {

        function learningService() {
            var resource = "/learning";
            var resourceUrl = envConfig.webApiUrl + resource;

            return {
                /* Learning Material */
                getPublishedMaterials: function () {
                    var url = envConfig.webApiUrl + "/learningPublic/getPublishedMaterials";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                // Admin
                getLearningMaterials: function () {
                    var url = resourceUrl;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getLearningMaterial: function (id) {
                    var url = resourceUrl + "/getById/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getVisitedItems: function (id) {
                    var url = envConfig.webApiUrl + "/learningPublic/GetVisitedItems";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                createLearningMaterial: function (material) {
                    var url = resourceUrl;
                    return $http.post(url, material).then(function (result) {
                        return result.data;
                    });
                },
                editLearningMaterial: function (material) {
                    var url = resourceUrl;
                    return $http.put(url, material).then(function (result) {
                        return result.data;
                    });
                },
                deleteLearningMaterial: function (id) {
                    var url = resourceUrl + "/deleteMaterial/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                updatePublishStatus: function (materialId, status) {
                    var item = {
                        status: status
                    }
                    var url = resourceUrl + "/updatePublishStatus/" + materialId;
                    return $http.put(url, item).then(function (result) {
                        return result.data;
                    });
                },


                updateSummarySortOrder: function (summarySortOrder) {
                    var url = resourceUrl + "/updateSummarySortOrder/" + summarySortOrder.materialId;
                    return $http.put(url, summarySortOrder).then(function (result) {
                        return result.data;
                    });
                },

                updateChapterSortOrder: function (chapterSortOrder) {
                    var url = resourceUrl + "/updateChapterSortOrder/" + chapterSortOrder.chapterId;
                    return $http.put(url, chapterSortOrder).then(function (result) {
                        return result.data;
                    });
                },

                updatePageSectionSortOrder: function (pageSectionSortOrder) {
                    var url = resourceUrl + "/updatePageSectionSortOrder/" + pageSectionSortOrder.pageId;
                    return $http.put(url, pageSectionSortOrder).then(function (result) {
                        return result.data;
                    });
                },

                /* Items */
                getItems: function (materialId) {
                    var url = resourceUrl + "/getItems/" + materialId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                createItem: function (item) {
                    var url = resourceUrl + "/createItem";
                    return $http.post(url, item).then(function (result) {
                        return result.data;
                    });
                },

                deleteItem: function (id) {
                    var url = resourceUrl + "/deleteItem/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                createSection: function (section) {
                    var url = resourceUrl + "/createSection";
                    return $http.post(url, section).then(function (result) {
                        return result.data;
                    });
                },
                updateSection: function (section) {
                    var url = resourceUrl + "/updateSection";
                    return $http.put(url, section).then(function (result) {
                        return result.data;
                    });
                },
                deleteSection: function (id) {
                    var url = resourceUrl + "/deleteSection/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                updateItemTitle: function (id, title) {
                    var itemTitle = {
                        itemId: id,
                        title: title
                    }
                    var url = resourceUrl + "/updateItemTitle";
                    return $http.put(url, itemTitle).then(function (result) {
                        return result.data;
                    });
                },

                getLearningMaterialStats: function (materialId) {
                    var url = resourceUrl + "/getLearningMaterialStats/" + materialId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                checkRepublish: function (materialId) {
                    const url = resourceUrl + "/checkRepublish/" + materialId
                    return $http.get(url).then(function (result) {
                        return result.data;
                    })
                }
            }
        }
        return learningService;
    }]);
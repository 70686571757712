import angular from "angular"
import FroalaEditor, { FroalaEvents, FroalaOptions} from "froala-editor"
import * as documentService from "@common/services/documentService"
import { NgComponent } from "@common/model/angularModel"

angular
    .module('DigiLean')
    .component("froalaEditor", {
        template: '<p></p>',
        bindings: {
            content: '<',
            options: '<',
            onUpdate: '&',
            uploadToBlob: '<'
        },
        controller: ['$element', '$translate',
            function (this: NgComponent, $element: JQuery, $translate) {
                let $ctrl = this
                let froalaEditor: FroalaEditor
                let froalaOptions: Partial<FroalaOptions>

                // "#FCBB42","#3BAFDA", "#8CC152","#ED5565","#E9573F", "#D770AD", "#c18852", "#5254c1", 
                //"#fcea42", "#52c1c0", "#c1bf52", "#449d44","#8b8d92", "#202020", "#13105e", "#AC92EC"
                const defaultOptions: Partial<FroalaOptions> = {
                    app: "DigiLEAN",
                    key: "CTD5xD3E1G2A1A8B2wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4B3C6E2C1F4F1F1==",
                    attribution: false,
                    theme: 'gray', 
                    toolbarButtons: ['bold', 'italic', 'underline', 
                        '|', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineStyle', 'paragraphStyle', 
                        '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 
                        '|', 'insertLink', 'insertImage', 'insertVideo', 'insertTable'],
                    // Colors list.
                    colorsBackground: [
                        '#fcea42','#FCBB42', '#E9573F','#ED5565','#D770AD', 'REMOVE',
                        '#8CC152', '#449d44','#52c1c0','#3BAFDA', '#249CB8', '#FFFFFF',
                        '#008cc9','#5254c1','#13105e','#c18852','#8b8d92', '#202020',
                    ],
                    colorsStep: 6,
                    colorsText: [
                        '#fcea42','#FCBB42', '#E9573F','#ED5565','#D770AD', 'REMOVE',
                        '#8CC152', '#449d44','#52c1c0','#3BAFDA', '#249CB8', '#FFFFFF',
                        '#008cc9','#5254c1','#13105e','#c18852','#8b8d92', '#202020',
                    ],
                    pastePlain: true,
                    tabSpaces: 4,
                    placeholderText: $translate.instant('COMMON_ENTER_TEXT'),
                    toolbarInline: false,
                    toolbarSticky: false,
                    videoUpload: false,
                    quickInsertTags: [],
                    imageUploadRemoteUrls: false,
                    videoMaxSize: 50 * 1024 * 1024,
                    imagePaste: true,
                    htmlAllowedTags: ['use','svg','a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo', 'blockquote', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'header', 'hgroup', 'hr', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'keygen', 'label', 'legend', 'li', 'link', 'main', 'map', 'mark', 'menu', 'menuitem', 'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param', 'pre', 'progress', 'queue', 'rp', 'rt', 'ruby', 's', 'samp', 'script', 'style', 'section', 'select', 'small', 'source', 'span', 'strike', 'strong', 'sub', 'summary', 'sup', 'table', 'tbody', 'td', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title', 'tr', 'track', 'u', 'ul', 'var', 'video', 'wbr'],
                    htmlAllowedEmptyTags: ['use','svg', 'textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hr']
                }
                const events: Partial<FroalaEvents> = {
                    initialized: function() {
                        froalaEditor.html.set($ctrl.content)
                    },
                    'contentChanged': function () {
                        const currentHtml = froalaEditor.html.get();
                        const pastedImage = currentHtml.search("data-fr-image-pasted")
                        console.log("contentChanged, pastedImage=", pastedImage)
                        if (pastedImage == -1) {
                            $ctrl.onUpdate({val: currentHtml})
                        }
                        //
                    },
                    'paste.after': function () {
                        const currentHtml = froalaEditor.html.get();
                        console.log("paste.after")
                        const pastedImage = currentHtml.search("data-fr-image-pasted")
                        console.log("paste.after, pastedImage=", pastedImage)
                        if (pastedImage == -1) {
                            $ctrl.onUpdate({val: currentHtml})
                        } 
                    },
                    ///@ts-ignore
                    'image.beforeUpload': async function (images) {
                        console.log("image.beforeUpload. images.length", images.length)
                        if (images.length===0)
                            return false
                        
                        if ($ctrl.uploadToBlob) {
                            const imageToUpload = images[0] as File
                            
                            // Different handling of inserted single image vs pasted image(s)
                            const isInsertImage = imageToUpload.name ? true : false
                            console.log("image.beforeUpload::isInsertImage=", isInsertImage)
                            if (isInsertImage)
                                await uploadInsertedImage(imageToUpload) // must be awaited for image.inserted to kick
                            
                        } else {
                            const reader = new FileReader()
                            reader.onload = function (e: any) {
                                const result = e.target.result
                                const image = froalaEditor.image.get()
                                froalaEditor.image.insert(result, true, {}, image)
                            };
                            reader.readAsDataURL(images[0])
                        }
                        
                        froalaEditor.popups.hideAll()

                        // Stop default upload chain.
                        return false
                    },
                    'image.uploaded': function (response) {
                        console.log("image.uploaded", response)
                        return true
                    },
                    'image.inserted': function ($img) {
                        console.log("image.inserted: START")
                        uploadBlobSrcImage($img)
                    },
                    'image.replaced': function ($img) {
                        console.log("image.replaced", $img)
                    },
                    'image.error': function (error, response) {
                        console.error("image.error", error)
                        console.error("image.error", response)
                    }
                }


                async function uploadBlobSrcImage($img: any) {
                    const src = $img[0].src
                    console.log("image.inserted::current src", src)
                    let currentHtml = froalaEditor.html.get()
                    console.log("currentHtml", currentHtml)
                    // check for blob source
                    if (src && !src.includes("blob:"))
                        return

                    const res = await fetch(src) // reads the file from upload
                    const blob = await res.blob()
                    console.log("Got blob", blob.size, blob.type)

                    const uploadedFile = await documentService.uploadImageToBlobStorageNoPreview(blob)
                    const url = await documentService.getFileUrl(uploadedFile.fileName, uploadedFile.id)
                    
                    currentHtml = froalaEditor.html.get()
                    const newHtml = currentHtml.replace(src, url)
                    froalaEditor.html.set(newHtml)
                    // debounce?
                    $ctrl.onUpdate({val: newHtml})
                }

                /// working - for insert image function only
                async function uploadInsertedImage(imageToUpload: File) {
                    console.log("single File upload:: filename=", imageToUpload.name)
                    froalaEditor.image.get() // do this will trigger image.inserted
                }

                $ctrl.$onInit = function() {
                    if(document.body.classList.contains('dark-theme')){
                        defaultOptions.theme = 'dark'
                    }
                    if ($ctrl.options && $ctrl.options instanceof Object) {
                        froalaOptions = angular.extend({}, defaultOptions, $ctrl.options)
                    } else {
                        froalaOptions = angular.extend({}, defaultOptions);
                    }
                    froalaOptions.events = events
                    
                    froalaEditor = new FroalaEditor($element[0], froalaOptions)
                }

                $ctrl.$onChanges = function (changes: any) {
                    if (changes.content) {
                        if (froalaEditor && froalaEditor.html) {
                            if (changes.content.currentValue) {
                                const isEditorActive = document.activeElement?.isSameNode(froalaEditor.el)

                                const editorCurrentVal = froalaEditor.html.get()
                                const isChanges = editorCurrentVal !== changes.content.currentValue
                                
                                if (isChanges && !isEditorActive) { // don't set while the user is typing
                                    froalaEditor.html.set($ctrl.content)
                                }
                            } else {
                                froalaEditor.html.set("")
                            }
                        }
                    }
                };

                $ctrl.$onDestroy = function() {
                    froalaEditor.destroy()
                }
            }]
    })
import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import { hasDefaultAccess } from "@common/components/dashboard/designer/common/userAccessService"
angular
    .module('DigiLean')
    .directive("textEditor", ['$translate', 'debounce','authService',
        function ($translate, debounce, authService) {
            return {
                templateUrl: 'texteditor.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'settingsChangeHandler': '&',
                    'hideHeader': '@',
                    'options': '=',
                    'layoutFactor': '<',
                    'userAccess': '<'
                },
                link: function (scope) {
                    scope.theme = getDefaultAppTheme()
                    scope.themeChangedEvent = function($event) {
                        scope.theme = $event.detail
                        scope.updateSettings()
                    }
                    scope.editInViewMode = false;
                    scope.canEdit = true;
                    scope.isBoardDesigner = authService.hasRole("BoardDesigner");
                    scope.froalaStyle = {
                        "height": "100%",
                        "font-size": "1.4rem"
                    }
                    scope.appStyle = getDefaultAppStyle()
                    function calcStyles() {
                        scope.froalaStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                        scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                    }
                    calcStyles()
                    scope.$watch('layoutFactor', calcStyles)

                    scope.title = ""
                    scope.titleChangedEvent = function($event) {
                        scope.title = $event.detail
                        scope.updateSettings()
                    }
                    // Dashboard Settings handling
                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        checkSettings();
                    });
                    scope.$watch('isAdminMode', function (isAdminMode) {
                        if (isAdminMode) {
                            scope.editContent = scope.content;
                        }
                    })

                    scope.$watch('userAccess', function (userAccess) {
                        scope.canEdit = hasDefaultAccess(userAccess) || scope.isBoardDesigner;
                    });
                    
                    scope.updateSettings = function () {
                        var componentSettings = {
                            title: scope.title,
                            content: scope.content,
                            theme: scope.theme,
                            editInViewMode: scope.editInViewMode
                        };
                        scope.settingsChangeHandler({ settings: componentSettings });
                    };

                    scope.commitChangesDebounced = debounce(scope.updateSettings, 1000, false);
                    

                    function checkSettings() {
                        // apply
                        if (scope.settings) {
                            if (scope.settings.title) {
                                scope.title = scope.settings.title;
                            }
                            if (scope.settings.content) {
                                scope.content = scope.settings.content;
                                scope.editContent = scope.content;
                            }
                            if (scope.settings.theme && scope.settings.theme.fillColor) {
                                scope.theme = scope.settings.theme;
                            }
                            if (scope.settings.editInViewMode) {
                                scope.editInViewMode = scope.settings.editInViewMode;
                            }
                        }
                    }
                    scope.changeTheme= function(theme){
                        scope.theme = theme;
                        scope.updateSettings();
                    };

                    scope.toggleEditMode = function () {
                        // scope.editInViewMode = !scope.editInViewMode
                        scope.updateSettings();
                    }
                    
                    scope.froalaOptionsToolbar = {
                        key: "CTD5xD3E1G2A1A8B2wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4B3C6E2C1F4F1F1==",
                        attribution: false,
                        toolbarButtons: ['bold', 'italic', 'underline', 
                        '|', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineStyle', 'paragraphStyle', 
                        '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 
                        '|', 'insertLink', 'insertVideo', 'insertTable'],
                        pastePlain: false,
                        imagePaste: false,
                        toolbarSticky: false,
                        toolbarInline: false,
                        placeholderText: $translate.instant('COMMON_DESCRIPTION'),
                        // heightMin: 160,
                        zIndex:2501,
                        linkAlwaysBlank: true,
                        quickInsertTags: []
                    };

                    scope.froalaOptionsEditInView = {
                        key: "CTD5xD3E1G2A1A8B2wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4B3C6E2C1F4F1F1==",
                        attribution: false,
                        toolbarButtons: ['bold', 'italic', 'underline', 
                        '|', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineStyle', 'paragraphStyle', 
                        '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 
                        '|', 'insertLink', 'insertVideo', 'insertTable'],
                        pastePlain: false,
                        imagePaste: false,
                        toolbarInline: true,
                        charCounterCount: false,
                        placeholderText: "",
                        zIndex:2501,
                        linkAlwaysBlank: true,
                        quickInsertTags: []
                    };
                    
                    // hook up to froala-editor
                    scope.contentChanged = function(val) {
                        scope.content = val;
                        scope.commitChangesDebounced(val);
                    };
                }
            };
        }]);

﻿import envConfig from "@common/envConfig";

angular.module("DigiLean").factory('areaService', ['$http',
    function ($http) {
        function areaService() {
            var resource = "/area";
            var resourceUrl = envConfig.webApiUrl + resource;
            return {
                getList: function (useCache) {
                    if (!useCache) useCache = false;
                    return $http.get(resourceUrl, { cache: useCache }).then(function (result) {
                        return result.data;
                    });
                },
                getAreasWithDeviation: function () {
                    var url = resourceUrl + "/getAreasWithDeviation";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getById: function (assetId) {
                    var url = resourceUrl + "/getById/" + assetId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                add: function (asset) {
                    return $http.post(resourceUrl, asset).then(function (result) {
                        return result.data;
                    });
                },
                update: function (asset) {
                    return $http.put(resourceUrl, asset).then(function (result) {
                        return result.data;
                    });
                },
                delete: function (id) {
                    var url = resourceUrl + "/deletearea/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                }
            }
        }
        return areaService;
    }]);
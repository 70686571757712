import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
import toaster from "@common/components/toast"

angular
.module("DigiLean")
.directive('quickIncidentRegistrationApp', ['$translate', '$filter','$uibModal','deviationService','projectService', 'areaService','dataListService','authService','debounce',
    function ($translate, $filter, $uibModal,deviationService, projectService, areaService, dataListService,authService,debounce) {
        return {
            templateUrl: 'quickIncidentRegistrationApp.html',
            restrict: 'E',
            scope: {
                'isAdminMode': '=',
                'settings': '=',
                'settingsChangeHandler': '&',
                'layoutFactor': '<',
                'userAccess': '<',
            },
            link: function (scope, elem, attrs) {

                scope.canEdit = false;

                scope.configuration = {};
                scope.filters = [];
                scope.allAssets = [];
                scope.users = [];
                var userid = authService.getCurrentUser().userId
                scope.customfields = [];
                scope.selectedCategories = [];
                scope.categories = null;
                scope.selectedArea = null;
                scope.selectedSeverity = {};
                scope.selectedAsset = null;
                scope.selectedFollowUpAsset = null;
                scope.selectedProject = null;
                scope.selectedDataListItem = null;
                scope.showTime = false;
                scope.isValid = true;
                scope.isDeviationAdmin = authService.hasRole("DeviationAdmin");

                scope.deviation = {title: "", severity:0};
                scope.severities = [];

                function setDefaultSeverity(){
                    scope.selectedSeverity = {};
                    scope.severities = deviationService().getAllSeverities();
                }
                setDefaultSeverity();
                
                scope.theme = getDefaultAppTheme()

                scope.title = $translate.instant("COMMON_TITLE");
                scope.titleChangedEvent = function($event) {
                    scope.title = $event.detail
                    scope.updateSettings()
                }

                scope.themeChangedEvent = function($event) {
                    scope.theme = $event.detail
                    scope.updateSettings()
                }
                scope.appStyle = getDefaultAppStyle()
                scope.incidentStyle = {
                    "font-size": "3.2rem"
                }
                function calcStyles() {
                    let fontIncident = "3.2rem"
                    if(scope.layoutFactor)
                        fontIncident = 3.2 * scope.layoutFactor + "rem"
                    
                    scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                    scope.incidentStyle["font-size"] = fontIncident
                }
                calcStyles()
                scope.$watch('layoutFactor', calcStyles)
                scope.$watch('settings', function (settings) {
                    if (settings) {
                        scope.settings = settings;
                        scope.title = settings.title;
                        scope.style = settings.style;
                        if (settings.theme) {
                            scope.theme = settings.theme;
                        }
                        if (settings.deviationTypeId) {
                            scope.deviationTypeId = settings.deviationTypeId;
                            getConfiguration();
                            if (settings.filters) {
                                scope.filters = settings.filters;
                            }
                            if(settings.registrationsetup){
                                scope.registrationsetup = angular.copy(settings.registrationsetup);
                                initRegistration();
                            }
                        }
                    }
                    checkConfiguration();
                });

                function checkConfiguration() {
                    if (scope.deviationTypeId && scope.registrationsetup) {
                        scope.configurationChallenges = false;
                    } else {
                        scope.configurationChallenges = true;
                    }
                }

                scope.$watch('userAccess', function (userAccess) {
                    scope.canEdit = userAccess.isAdmin || scope.isDeviationAdmin || userAccess.assetRole == "Member" || userAccess.assetRole == "Owner";
                });
                
                scope.incidentDateChanged = function ($event) {
                    const date = $event.originalEvent.detail
                    scope.deviation.incidentDate = date
                }

                scope.setTime = function (time) {
                    scope.deviation.incidentDate.setHours(time.getHours());
                    scope.deviation.incidentDate.setMinutes(time.getMinutes());
                };

                function checkFields(validFields) {
                    if (validFields) {
                        for (var f = 0; f < validFields.length; f++) {
                            var customRegistrationField;
                            if(validFields[f].deviationCustomFieldId){
                                var customSourceColumn = "customField" + validFields[f].deviationCustomFieldId; 
                                customRegistrationField = scope.registrationsetup.find(l => l.sourceColumn == customSourceColumn)   
                            } else {
                                customRegistrationField = scope.registrationsetup.find(l => l.type == validFields[f].type) 
                            }
                           if(customRegistrationField) {
                                switch (validFields[f].type) {
                                    case "PROJECT":
                                        loadProjects();
                                        break;
                                    case "AREA":
                                        loadAreas();
                                        break;
                                    case "DEVIATION_INCIDENT_TIME":
                                        scope.showTime = true;
                                        break;
                                    case "list":
                                        setCustomList(customRegistrationField);                    
                                        break;
                                    default:
                                        scope.customfields.push(customRegistrationField);
                                }
                            }                         
                        }
                    }
                    // remove earlier added fields area or project that are not valid anymore
                    var isAreaValid = validFields.find(l => l.type == "AREA");
                    if(!isAreaValid && scope.registrationsetup && scope.registrationsetup.find(l => l.type == "AREA")){
                        scope.registrationsetup.splice(scope.registrationsetup.findIndex(l => l.type == "AREA"), 1);
                    }
                    var isProjectValid = validFields.find(l => l.type == "PROJECT");
                    if(!isProjectValid && scope.registrationsetup && scope.registrationsetup.find(l => l.type == "PROJECT")){
                        scope.registrationsetup.splice(scope.registrationsetup.findIndex(l => l.type == "PROJECT"), 1);
                    }
                }

                function setCustomList(field){
                    const hasBeenAdded = scope.customfields.find(l => l.sourceColumn == field.sourceColumn)
                    if(hasBeenAdded) return; //already added
                    if(!field.dataListId) return;
                    var listOptions = {includeDeletedItems:false};
                    dataListService().getItems(field.dataListId, listOptions).then(function (list) {
                        const hasFilterValues = scope.filters.find(l => l.sourceColumn == field.sourceColumn)
                        if(field.value){
                            field.selectedDataListItem = list.find(a => a.id == field.value);
                        }
                        if (hasFilterValues && hasFilterValues.items.length > 0){
                            setFilteredSelectors(field, hasFilterValues.items)
                        } else {
                            field.dataList = list;
                        }                      
                        scope.customfields.splice(field.sortOrder, 0, field);
                    });
                }

                function setFilterOrDefaultValue(element){
                    if(!element) return;
                    if(element.show && scope.filters && scope.filters.length > 0){
                        var hasFilterValues = scope.filters.find(l => l.sourceColumn == element.sourceColumn)
                        if(hasFilterValues && hasFilterValues.items.length > 0){
                            if(hasFilterValues.items.length == 1){
                                element.value = hasFilterValues.items[0]; //preselect the first filtered item of this filterelement
                                setFilteredSelectors(element, hasFilterValues.items);
                                return; //should not proceed checking default value since it has already been set. 
                            } else { //multiple selected elements
                                setFilteredSelectors(element, hasFilterValues.items)
                            }
                        }
                    } 
                    if(element.value){
                        switch (element.type.toLowerCase()) {
                            case "asset":
                                if(scope.allAssets && scope.allAssets.length > 0){
                                    scope.selectedAsset = scope.allAssets.find(a => a.id == element.value);
                                } else {
                                    assetService.getAllAssets(true).then(function (data) {
                                        scope.allAssets = data;
                                        scope.assets = data;
                                        scope.selectedAsset = data.find(a => a.id == element.value);
                                    });
                                }
                                break;
                            case "followupasset":
                                if(scope.allAssets && scope.allAssets.length > 0){
                                    scope.selectedFollowUpAsset = scope.allAssets.find(a => a.id == element.value);
                                } else {
                                    assetService.getAllAssets(true).then(function (data) {
                                        scope.allAssets = data;
                                        scope.followupassets = data;
                                        scope.selectedFollowUpAsset = data.find(a => a.id == element.value);
                                    });
                                }
                                break;
                            case "area":
                                if(scope.areas && scope.areas.length > 0){
                                    scope.selectedArea = scope.areas.find(a => a.id == element.value);
                                } else {
                                    areaService().getList(true).then(function (data) {
                                        scope.areas = data;
                                        scope.selectedArea = data.find(a => a.id == element.value);
                                    });
                                }
                                break;
                            case "project":
                                if(scope.projects && scope.projects.length > 0){
                                    scope.selectedProject = scope.projects.find(a => a.id == element.value);
                                } else {
                                    projectService().getOngoingProjects().then(function (data) {
                                        scope.projects = data;
                                        scope.selectedProject = data.find(a => a.id == element.value);
                                    });
                                }
                                break;

                            case "user":
                                if(scope.allUsers && scope.allUsers.length > 0){
                                    scope.selectedUser = scope.allUsers.find(a => a.userId == element.value);
                                } else {
                                    customerService.getAllUsers().then(function (data) {
                                        scope.allUsers = data;
                                        scope.selectedUser = data.find(a => a.userId == element.value);
                                    });
                                }
                                break;
                            case "deviationseverity":
                                    scope.selectedSeverity = scope.severities.find(s => s.severity == element.value);            
                                break;
                            case "list":
                                if(element.dataList && element.dataList.length > 0) return; //already loaded
                                var listOptions = {includeDeletedItems:false};
                                dataListService().getItems(element.dataListId, listOptions).then(function (list) {
                                    if (list) {
                                        element.dataList = list;
                                        element.selectedDataListItem = list.find(a => a.id == element.value);
                                    }
                                });
                                break;
                            case "problemcategory":
                                if(scope.categories && scope.categories.length > 0){                     
                                    //Deviation support multiple selected categories
                                    const hasFilterValues = scope.filters.find(l => l.sourceColumn == element.sourceColumn)
                                    if(hasFilterValues && hasFilterValues.items.length > 0){
                                        if(hasFilterValues.items.length == 1){ //preselect the first filtered item of this filterelement
                                            const hasElement = scope.categories.find(a => a.id == hasFilterValues.items[0]);
                                                if(hasElement){
                                                    scope.selectedCategories.push(hasElement);
                                                } 
                                        } else { //multiple selected elements
                                            for(var index = 0; index < hasFilterValues.items.length; index++){
                                                const hasElement = scope.categories.find(a => a.id == hasFilterValues.items[index]);
                                                if(hasElement){
                                                    scope.selectedCategories.push(hasElement);
                                                }
                                            }
                                        }
                                    } else {
                                        const hasElement = scope.categories.find(a => a.id == element.value[index]);
                                        if(hasElement){
                                            scope.selectedCategories.push(hasElement);
                                        }
                                    }
                                }
                                break;
                            case "consequence":
                                if(scope.consequences && scope.consequences.length > 0){                     
                                    //Deviation support multiple selected consequences
                                    const hasFilterValues = scope.filters.find(l => l.sourceColumn == element.sourceColumn)
                                    if(hasFilterValues && hasFilterValues.items.length > 0){
                                        for(var index = 0; index < hasFilterValues.items.length; index++){
                                            const hasElement = scope.consequences.find(a => a.id == hasFilterValues.items[index]);
                                            if(hasElement){
                                                scope.modifyConsequence(hasElement);
                                            }
                                        }
                                    } else if (element.value && element.value.length > 0){
                                        for(var index = 0; index < element.value.length; index++){
                                            const hasElement = scope.consequences.find(a => a.id == element.value[index]);
                                            if(hasElement){
                                                scope.modifyConsequence(hasElement);
                                            }
                                        }
                                    }
                                }
                                break;
                            case "problemreason":
                                if(scope.problemReasons && scope.problemReasons.length > 0){                     
                                    //Deviation support multiple selected problem reasons
                                    const hasFilterValues = scope.filters.find(l => l.sourceColumn == element.sourceColumn)
                                    if(hasFilterValues && hasFilterValues.items.length > 0){
                                        for(var index = 0; index < hasFilterValues.items.length; index++){
                                            const hasElement = scope.problemReasons.find(a => a.id == hasFilterValues.items[index]);
                                            if(hasElement){
                                                scope.modifyReason(hasElement);
                                            }
                                        }
                                    } else if (element.value && element.value.length > 0){
                                        for(var index = 0; index < element.value.length; index++){
                                            const hasElement = scope.problemReasons.find(a => a.id == element.value[index]);
                                            if(hasElement){
                                                scope.modifyReason(hasElement);
                                            }
                                        }
                                    }
                                }
                                break;                 
                        }
                    }
                }
                

                function initRegistration() {
                    scope.isProcessing = false;
                    resetDeviation();
                    setDefaultSeverity();
                    scope.description = "";
                    scope.selectedDate = moment().startOf('day').toDate();
                    scope.selectedDataListItem = null;
                    angular.forEach(scope.registrationsetup, function (element) {
                        const hasFilterValues = scope.filters.find(l => l.sourceColumn == element.sourceColumn)
                        if(element.value || (hasFilterValues && hasFilterValues.items.length > 0)){
                            setFilterOrDefaultValue(element);
                        }
                        else {
                            switch (element.type) {
                                case "asset":
                                    if(scope.allAssets && scope.allAssets.length > 0){
                                        scope.assets = scope.allAssets;
                                    } else {
                                        assetService.getAllAssets(true).then(function (data) {
                                            scope.allAssets = data;
                                            scope.assets = data;
                                        });
                                    }
                                    break;
                                case "followupasset":
                                    if(scope.allAssets && scope.allAssets.length > 0){
                                        scope.followupassets = scope.allAssets;
                                    } else {
                                        assetService.getAllAssets(true).then(function (data) {
                                            scope.allAssets = data;
                                            scope.followupassets = data;
                                        });
                                    }
                                    break;
                                case "area":
                                    areaService().getList(true).then(function (data) {
                                        scope.areas = data;
                                    });
                                    break;
                                case "project":
                                    projectService().getOngoingProjects().then(function (data) {
                                        scope.projects = data;
                                    });
                                    break;
                                case "user":
                                    setCurrentUser(element);
                                    break;
                                case "deviationseverity":
                                    if(element.value){
                                        scope.selectedSeverity = scope.severities.find(s => s.severity == element.value);
                                    }
                                    break;
                                case "list":
                                    var listOptions = {includeDeletedItems:false};
                                    dataListService().getItems(element.dataListId, listOptions).then(function (list) {
                                        if (list) {
                                            element.dataList = list;
                                            element.selectedDataListItem = {};
                                        }
                                    });
                                    break;

                            }
                        }
                    });

                    scope.isLoadingConfig = false;
                
                }

                function getSelectableElements(listOfAllElements, filteritems){
                    var listOfSelectableElements = [];
                    if(!listOfAllElements || listOfAllElements.length == 0) return listOfSelectableElements;
                    if(!filteritems || filteritems.length == 0){
                        listOfSelectableElements = listOfAllElements;
                    } else {
                        for(var i=0; i<filteritems.length; i++){                 
                            const hasElement = listOfAllElements.find(e => e.id == filteritems[i])
                            if (hasElement) {
                                listOfSelectableElements.push(hasElement);
                            }
                        }
                        if (listOfSelectableElements.length == 0){ //if no filtered items are available then let all be selectable
                            listOfSelectableElements = listOfAllElements;
                        }
                    }
                    return listOfSelectableElements;
                }


                function setFilteredSelectors(element, filteritems){
                    switch (element.type.toLowerCase()) {
                        case "asset":
                            if(scope.allAssets && scope.allAssets.length > 0){
                                scope.assets = getSelectableElements(scope.allAssets, filteritems);
                            } else {
                                assetService.getAllAssets(true).then(function (data) {
                                    scope.allAssets = data;
                                    scope.assets = getSelectableElements(data, filteritems);
                                    if(scope.assets && scope.assets.length == 1){
                                        scope.selectedAsset = scope.assets[0];
                                    }
                                });
                            }
                            break;
                        case "followupasset":
                            if(scope.allAssets && scope.allAssets.length > 0){
                                scope.followupassets = getSelectableElements(scope.allAssets, filteritems);
                            } else {
                                assetService.getAllAssets(true).then(function (data) {
                                    scope.allAssets = data;
                                    scope.followupassets = getSelectableElements(data, filteritems);
                                    if(scope.followupassets && scope.followupassets.length == 1){
                                        scope.selectedFollowUpAsset = scope.followupassets[0];
                                    }
                                });
                            }
                            break;         
                        case "area":
                            areaService().getList(true).then(function (data) {
                                scope.areas = [];
                                for(var i=0; i<filteritems.length; i++){
                                    var hasElement = data.find(e => e.id == filteritems[i]) 
                                    if (hasElement) {
                                        scope.areas.push(hasElement);
                                    }
                                    if(scope.areas && scope.areas.length == 1){
                                        scope.selectedArea = scope.areas[0];
                                    }
                                }
                            });
                            break;
                        case "project":
                            if(scope.projects && scope.projects.length > 0){
                                scope.projects = getSelectableElements(scope.projects, filteritems);
                            } else {
                                projectService().getOngoingProjects().then(function (data) {
                                    scope.projects = getSelectableElements(data, filteritems);
                                });
                            }
                            break;
                        case "user":
                            customerService.getAllUsers().then(function (data) {
                                scope.allUsers = [];
                                for(var i=0; i<filteritems.length; i++){
                                    var hasElement = data.find(e => e.id == filteritems[i]) 
                                    if (hasElement) {
                                        scope.allUsers.push(hasElement);
                                    }
                                }
                            });
                            break;     
                        case "problemcategory":
                            scope.categories = getSelectableElements(scope.categories, filteritems);
                            break;
                        case "consequence":
                            scope.consequences = getSelectableElements(scope.consequences, filteritems);
                            break;    
                        case "problemreason":
                            var filteredvalues = [];
                                for(var i=0; i<filteritems.length; i++){
                                    var hasElement = scope.problemReasons.find(e => e.id == filteritems[i]) 
                                    if (hasElement) {
                                        filteredvalues.push(hasElement);
                                    }
                                }
                                if(filteredvalues.length > 0){
                                    scope.problemReasons = filteredvalues;
                                }
                            break;
                        case "deviationseverity":
                            var filteredseverities = [];
                                for(var i=0; i<filteritems.length; i++){
                                    var hasElement = scope.severities.find(e => e.severity == filteritems[i]) 
                                    if (hasElement) {
                                        filteredseverities.push(hasElement);
                                    }
                                }
                                if(filteredseverities.length > 0){
                                    scope.severities = filteredseverities;
                                }
                            break;
                        case "list":
                            var listOptions = {includeDeletedItems:false};
                            dataListService().getItems(element.dataListId, listOptions).then(function (list) {
                                if (list) {
                                    element.dataList = [];
                                    for(var i=0; i<filteritems.length; i++){
                                        var hasElement = list.find(e => e.id == filteritems[i])
                                        if (hasElement) {
                                            element.dataList.push(hasElement);
                                        }
                                    }
                                    element.selectedDataListItem = {};
                                }
                            });
                            break;
                        default:
                            element.value = null;
                        }
                }

                function setCurrentUser(element){
                    if(scope.allUsers && scope.allUsers.length > 0){
                        var hasElement = scope.allUsers.find(e => e.userId == userid)
                        if (hasElement) {
                            element.user = hasElement;
                            element.value = hasElement.userId;
                        }
                    } else {
                        customerService.getAllUsers().then(function (users) {
                            scope.allUsers = users;
                            var hasElement = scope.allUsers.find(e => e.userId == userid)
                            if (hasElement) {
                                element.user = hasElement;
                                element.value = hasElement.userId;
                            }
                        });
                    }
                }

                scope.modifyConsequence = function (consequence) {
                    if (scope.deviation.consequences) {
                        var match = $filter('filter')(scope.deviation.consequences, { lossTypeId: consequence.id }, true);
                        if (match.length === 1) {
                            if (consequence.checked) {
                                match[0].amount = consequence.amount;
                            } else {
                                consequence.amount = null;
                                var index = scope.deviation.consequences.indexOf(match[0]);
                                scope.deviation.consequences.splice(index);
                            }
                        } else { //consequences is not allready selected
                            let deviationConsequence = {
                                lossTypeId: consequence.id,
                                amount: consequence.amount,
                                unit: consequence.unit
                            };
                            scope.deviation.consequences.push(deviationConsequence);
                        }   
                    } else {
                        scope.deviation.consequences = [];
                        let deviationConsequence = {
                            lossTypeId: consequence.id,
                            amount: consequence.amount,
                            unit: consequence.unit
                        };
                        scope.deviation.consequences.push(deviationConsequence);
                    }
                };

                scope.setConsequence = function (consequence) {
                    if (consequence.amount) {
                        consequence.checked = true;
                        
                        var valueString = consequence.amount.toString();
                        var valuereplacecomma = valueString.replace(/,/g, '.');
                        consequence.amount = valuereplacecomma;
                        if (consequence.amount == '' || Number(consequence.amount)) {
                            consequence.notvalidnumber = false;
                            scope.modifyConsequence(consequence);
                        } else {
                            consequence.notvalidnumber = true;
                        }   
                    } else {
                        consequence.notvalidnumber = false;
                    }
                };



                function getConfiguration(){
                    if(!scope.deviationTypeId) return;
                    if(scope.configuration && scope.categories) return; //already loaded 
                    deviationService().getDeviationTypeConfiguration(scope.deviationTypeId).then(function (configuration) {
                        scope.configuration = configuration;
                        checkFields(scope.configuration.fields);
                        scope.categories = scope.configuration.categories;
                        scope.consequences = scope.configuration.consequences;
                        scope.problemReasons = scope.configuration.causes;

                        var element = scope.registrationsetup.find(l => l.sourceColumn == "problemCategoryId")
                        setFilterOrDefaultValue(element);

                        element = scope.registrationsetup.find(l => l.sourceColumn == "lossTypeId")
                        setFilterOrDefaultValue(element);

                        element = scope.registrationsetup.find(l => l.sourceColumn == "problemReasonId")
                        setFilterOrDefaultValue(element);
                    });
                }

                scope.selectConfiguration = function () {
   
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'quickIncidentRegistrationConfiguration.html',
                        controller: 'quickIncidentRegistrationConfigurationController',
                        resolve: {
                            configuration: function () {
                                return scope.settings;
                            }
                        }
                    });
                    modalInstance.result.then(function (configuration) {
                        scope.deviationTypeId = configuration.deviationTypeId;
                        scope.registrationsetup = configuration.registrationsetup;
                        if(!scope.settings) scope.settings = {};
                        scope.settings.registrationsetup = configuration.registrationsetup;
                        scope.filters = configuration.filters;
                        scope.updateSettings()
                        initRegistration();
                    }, function(res){});
                };

                function resetDeviation(){
                    scope.deviation = {
                        title: "",
                        severity:0,
                        evaluationText: "",
                        deviationTypeId: scope.deviationTypeId 
                    };
                    scope.deviation.incidentDate = moment().toDate();
                    scope.deviation.incidentDate.setHours(0);
                    scope.deviation.incidentDate.setMinutes(0);

                    scope.selectedAsset = null;
                    scope.selectedFollowUpAsset = null;
                    scope.selectedCategories = [];
                    scope.selectedArea = null;
                    scope.selectedSeverity = {};

                    scope.customfields = [];
                    scope.selectedDataListItem = null;

                    if(scope.settings && scope.settings.registrationsetup){
                        scope.registrationsetup = angular.copy(scope.settings.registrationsetup);

                        angular.forEach(scope.registrationsetup, function (element) {
                            const hasFilterValues = scope.filters.find(l => l.sourceColumn == element.sourceColumn)
                            if(element.value || (hasFilterValues && hasFilterValues.items.length > 0)){
                                setFilterOrDefaultValue(element);
                            }
                        });
                    }            
                }

                scope.validate = function () {
                    // Rules
                    scope.validationMessages = [];


                    // Has categories
                    if (scope.selectedCategories.length == 0) {
                        scope.validationMessages.push($translate.instant("DEVIATION_VALIDATION_CATEGORIES_REQUIRED"));
                        const categoryField = scope.registrationsetup.find(l => l.sourceColumn == "problemCategoryId");
                        if(categoryField) {
                            categoryField.show = true;
                        }
                    }
        
                    //Check mandatory custom fields
                    if(mandatoryFieldsMissing(scope.registrationsetup)){
                        scope.validationMessages.push($translate.instant("ADMIN_DATA_FIELD_MISSING"));
                    }
        
                    if (scope.validationMessages.length > 0) {
                        scope.isValid = false;
                    } else {
                        scope.isValid = true;
                    }
                    return scope.isValid;
                };

                function mandatoryFieldsMissing(lisOfFields){
                    if(!lisOfFields) return;
                    for(let index = 0; index < lisOfFields.length; index++){
                        if(lisOfFields[index].isMandatory){
                            if(lisOfFields[index].type == 'list'){
                                if(!(lisOfFields[index].selectedDataListItem && lisOfFields[index].selectedDataListItem.id)){
                                   return true;
                                }
                            } else if(lisOfFields[index].type == 'number'){
                                if(isNaN(lisOfFields[index].value)){
                                    return true;
                                 }
                            } else if(lisOfFields[index].type == 'text'){
                                if(!lisOfFields[index].value){
                                    return true;
                                 }
                            }
                        }
        
                    }
                    return false;
                }

                function loadAreas() {
                    if(!scope.areas || scope.areas.length == 0){
                        areaService().getList().then(function (areas) {
                            scope.areas = areas;
                        });
                    }
                }
                scope.areaChanged = function (area) {
                    scope.deviation.areaId = area.id;
                };
                
                function loadProjects() {
                    if(!scope.projects || scope.projects.length == 0){
                        projectService().getOngoingProjects().then(function (projects) {
                            scope.projects = projects;
                        });
                    }
                }

                scope.selectUser = function (user, item) {
                    item.user = user;
                    if (user && user.userId) {
                        item.value = user.userId;
                    } else {
                        item.value = null;
                    }          
                };

                scope.selectAsset = function (asset, item) {        
                    if (asset) {
                        scope.selectedAsset = asset;
                        item.value = asset.id;
                        customerService.getUsersForAsset(asset.id).then(function (assetUsers) {
                            scope.allUsers = assetUsers;
                            angular.forEach(scope.allUsers, function (user, key) {
                                user.fullName = user.firstName + " " + user.lastName;
                            });
                            const userSetup = scope.registrationsetup.find(l => l.sourceColumn == "createdByUserId");
                            if (userSetup && userSetup.value) {
                                var match = $filter('filter')(scope.allUsers, { userId: userSetup.value.userId });
                                if (match.length == 0) { //user is not member of selected asset     
                                    userSetup.value = null;
                                }
                            }
                        });      
                    }
                }
                scope.selectFollowupAsset = function (asset, item) {
                    scope.selectedFollowupAsset = asset;
                    item.value = asset.id;
                }
                scope.selectArea = function (area, item) {
                    scope.selectedArea = area;
                    item.value = area.id;
                }
                scope.selectProject = function (project, item) {
                    scope.selectedProject = project;
                    item.value = project.id;
                }
                scope.selectListItem = function (selectedDataListItem, item) {
                    scope.selectedDataListItem = selectedDataListItem;
                    item.value = selectedDataListItem.id;
                }
                scope.selectSeverity = function (severity, item) {
                    scope.selectedSeverity = severity;
                    item.value = severity.severity;
                }

                function saveCustomFieldsValues(deviationId){
                    if(deviationId){
                        var deviationtypefieldvalues = [];
                        for(let index = 0; index < scope.customfields.length; index++){
                            if(scope.customfields[index].type == 'list'){
                                if(scope.customfields[index].selectedDataListItem && scope.customfields[index].selectedDataListItem.id){
                                    var valueData = {
                                        DeviationId: deviationId,
                                        DeviationTypeFieldId: scope.customfields[index].id,
                                        Value:  scope.customfields[index].selectedDataListItem.id
                                    }
                                    deviationtypefieldvalues.push(valueData);
                                }
                            } else {
                                var valueData = {
                                    DeviationId: deviationId,
                                    DeviationTypeFieldId: scope.customfields[index].id,
                                    Value: scope.customfields[index].value
                                }
                                deviationtypefieldvalues.push(valueData);
                            }
                        }
                        if(deviationtypefieldvalues.length > 0 ){
                            deviationService().addCustomFields(deviationId, deviationtypefieldvalues).then(function (result) {
                            });
                        }
                    }
                }

                scope.modifyReason = function (reason) {
                    if (scope.deviation.problemReasons) {
                        var i = scope.deviation.problemReasons.indexOf(reason.id);
                        if (i == -1) { //reason is not allready selected
                            scope.deviation.problemReasons.push(reason.id);
                        } else {
                            scope.deviation.problemReasons.splice(i);
                        } 
                    } else {
                        scope.deviation.problemReasons = [];
                        scope.deviation.problemReasons.push(reason.id);
                    }
                }

                scope.isSaving = false;
                scope.addDeviation = function () {
                    if (!scope.deviationTypeId) return;
                    if (!scope.registrationsetup) return;
                    for (let index = 0; index < scope.registrationsetup.length; index++) {
                        const element = scope.registrationsetup[index];
                        switch (element.sourceColumn) {
                            case "title":
                                scope.deviation.title = element.value;
                                break;
                            case "incidentDate":
                                break;
                            case "text":
                                scope.deviation.text = element.value;
                                break;
                            case "assetId":
                                scope.deviation.assetId = element.value;
                                break;
                            case "followUpAssetId":
                                scope.deviation.followUpAssetId = element.value;
                                break;
                            case "createdByUserId":
                                scope.deviation.createdByUserId = element.value;
                                if(element.user){
                                    scope.deviation.createdByUser = element.user.firstName + " " + element.user.lastName;
                                }
                                break;
                            case "projectId":
                                scope.deviation.projectId = element.value;
                                break;
                            case "areaId":
                                scope.deviation.areaId = element.value;
                                break;
                            case "lossTypeId":
                                scope.deviation.lossTypeId = element.value;
                                break;
                            case "problemReasonId":
                                if(scope.selectedProblemReason && scope.selectedProblemReason.length > 0){
                                    scope.deviation.problemReasons = [];
                                    for (let index = 0; index < scope.selectedProblemReason.length; index++) {
                                        const reason = scope.selectedProblemReason[index];
                                        scope.deviation.problemReasons.push(reason.id);
                                    }
                                }
                                break;
                            case "severity":
                                if(scope.selectedSeverity && scope.selectedSeverity.severity){
                                    scope.deviation.severity = parseInt(scope.selectedSeverity.severity);
                                }
                                break;
                            case "problemCategoryId":
                                scope.deviation.categories = [];
                                if(scope.selectedCategories && scope.selectedCategories.length > 0){
                                    for (let index = 0; index < scope.selectedCategories.length; index++) {
                                        const cat = scope.selectedCategories[index];
                                        scope.deviation.categories.push({
                                            problemCategoryId: cat.id
                                        });
                                    }
                                }            
                                break; 
                        }
                    }
                    if (!scope.validate()) return;
        
                    scope.isSaving = true;
                    
                    deviationService().addDeviation(scope.deviation).then(function (createdDeviation) {
                        saveCustomFieldsValues(createdDeviation.id);
                        scope.isSaving = false;
                        scope.isPopupOpen = false;
                        $translate('COMMON_DEVIATION_REGISTERED').then(function (msg) {
                            toaster.success(msg);
                        });
                        resetDeviation();
                    });
                };
        
                scope.cancel = function () {
                    scope.isSaving = false;
                    scope.isPopupOpen = false;
                    resetDeviation();
                };
                scope.updateSettings = function () {
                    var componentSettings = {
                        title: scope.title,
                        style: scope.style,
                        deviationTypeId: scope.deviationTypeId,
                        registrationsetup: scope.settings?.registrationsetup,
                        filters: scope.filters,
                        theme: scope.theme
                    };
                    scope.settings = componentSettings;
                    scope.settingsChangeHandler({ settings: componentSettings });
                }

                scope.commitChangesDebounced = debounce(scope.updateSettings, 2000, false);


            }
        }
    }]);


import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import envConfig from "@common/envConfig"

export let appInsights:  ApplicationInsights | null = null
if (envConfig.appInsightsConnectionString) {
    appInsights = new ApplicationInsights({
        config: {
            connectionString: envConfig.appInsightsConnectionString,
            disableAjaxTracking: true,
            disableFetchTracking: true
        }
    })
    appInsights.loadAppInsights()
}

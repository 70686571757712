import envConfig from "@common/envConfig"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('inboxController', ['$scope','$stateParams', '$filter', '$translate',
    function ($scope, $stateParams, $filter, $translate) {

        $scope.tabs = [
            {
                name: "notifications",
                isActive: false,
                title: $translate.instant("COMMON_NOTIFICATIONS")
            },
            {
                name: "messages",
                isActive: false,
                title: $translate.instant("COMMON_MESSAGES")
            }
        ]

        if (!envConfig.disableFeedbackToDigiLean) {
            $scope.tabs.push({
                name: "feedbacks",
                isActive: false,
                title: $translate.instant("NAV_FEEDBACK_TO_DIGILEAN")
            })
        }

        $scope.selectedTab = null;
        $scope.selectedTabName = "";
        
        $scope.selectTab = function (tab) {
            if ($scope.selectedTab) {
                $scope.selectedTab.isActive = false;
            }
            tab.isActive = true;
            $scope.selectedTab = tab
            $scope.selectedTabName = tab.name;
        }

        // Select tab based on input or default first.
        if ($stateParams.tab) {
            var tab = $stateParams.tab;
            var hasTab = $filter('filter')($scope.tabs, { name: tab });
            if (hasTab.length == 1) {
                $scope.selectTab(hasTab[0]);
            } else {
                $scope.selectTab($scope.tabs[0]);
            }

        } else {
            $scope.selectTab($scope.tabs[0]);
        }
        if ($stateParams.notificationid) {
            $scope.selectedNotificationId = parseInt($stateParams.notificationid);
        }
    }]);
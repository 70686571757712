import { AssetWithRole } from "@api"

export interface DigiLeanSettings extends Record<string, string | boolean | undefined> {
    digiLeanBuildVersion: string
    backendUrl: string
    authServerUrl: string
    websiteUrl: string
    signalrUrl: string
    webApiUrl: string
    fileStoreUrl: string
    diagramsUrl: string
    pdfExportUrl: string
    isTeamsMode: boolean
    teamsAppId?: string
    digiLeanUrl?: string
    appInsightsConnectionString?: string
    disableFeedbackToDigiLean: boolean
}

export type DigiLeanRole = "KaizenAdmin" | "Admin" | "Support" | "BoardDesigner" | "A3Admin" | "DataAdmin" | "DeviationAdmin" | "ImprovementAdmin" | "ProjectAdmin" | "StrategyAdmin" | "LearningAdmin" | "MessageWriter"

/*
    RoleArray, just an array with our own constructor that suits the input from user role claims
*/
export class RoleArray extends Array<string> {

    constructor(role? : string | string[]) {
        if (role) {
            const roles = Array.isArray(role) ? role : [role]
            super(...roles)
        }
        else
            super()
    }

    hasRole(role: DigiLeanRole) {
        let has = false
        if (this.length > 0) {
            ///@ts-ignore
            role = role.trim()
            const roles = this as string[]
            has = roles.includes(role)
        }

        return has
    }
}


export interface DigiLeanAuthUser {
    loggedIn: boolean
    customerId?: number
    userId?: string
    userName?: string
    email?: string
    name?: string
    displayName?: string
    roles: RoleArray
    initials?: string
    photoURL?: string
    token?: string
    tokenExpire?: number 
    idToken?: string
    refreshToken?: string
    idp?: string
    language?: string
}

export enum DigiLeanAuthRoles {
    KaizenAdmin = "KaizenAdmin",
    Admin = "Admin",
    Support = "Support",
    BoardDesigner = "BoardDesigner",
    A3Admin = "A3Admin",
    DeviationAdmin = "DeviationAdmin",
    ImprovementAdmin = "ImprovementAdmin",
    ProjectAdmin = "ProjectAdmin",
    StrategyAdmin = "StrategyAdmin",
    LearningAdmin = "LearningAdmin"
}

export interface WidgetSettings {
    title?: string
    content?: string
    theme?: string
    editInViewMode?: boolean
}

export interface Theme {
    background: string
    fillColor?: string
    color?: string
}

export type DateTimeViewMode = "regular" | "short" | "long"

export interface AssetChangeInfo {
    asset: AssetWithRole
    model: any
}

export interface RectangleObject {
    width: number
    height: number
}

export interface UserMention {
    fullName: string
    id: string,
    initials: string,
    imageUrl: string
}

export interface DashboardApp {
    name: string
    component: string
    category: string
    imageUrl: string
    previewImageUrl: string
    description: string
    defaultXSize: number
    defaultYSize: number
}
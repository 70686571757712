import {LitElement, html} from "lit"
import {customElement, state} from "lit/decorators.js"
import { authUser } from "@common/stores/userStore"
import { parseToken, parseExpire } from "@common/services/tokenService"
import { DigiLeanAuthUser } from "@common/model/types"
import { Subscription } from "rxjs"

@customElement('user-token-viewer')
export class UserTokenViewer extends LitElement {

    sub?: Subscription

    @state()
    authUser?: DigiLeanAuthUser

    connectedCallback(): void {
        super.connectedCallback()
        this.sub = authUser.subscribe(u => this.authUser = u)
    }
    disconnectedCallback(): void {
        super.disconnectedCallback()
        if (this.sub)
            this.sub.unsubscribe()
    }

    render() {
        if (!this.authUser)
            return html`<span>loading</span>`

        const token = parseToken(this.authUser.token!)
        const tokenJsonString = JSON.stringify(token, null, 2)
        const expired = parseExpire(token.exp)
        return html`
            <textarea cols="60" rows="30" .value=${tokenJsonString}></textarea><br>
            <p>Expire date: <datetime-viewer date="${expired.expiredDate.toISOString()}"></datetime-viewer></p>
            <p>Expire In: ${expired.parsedDiff}</p>
        `
    }
}
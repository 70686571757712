
import * as dashboardService from "@common/services/dashboardService"
import { userFullProfile } from "@common/stores/userStore"
import * as employeeService from "@common/services/employeeService"
import envConfig from "@common/envConfig"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('navigationController', ['$scope', '$filter', '$translate', 'authService', 'navigationService', 'learningPublicService', 'modalService','folderService',
    function ($scope, $filter, $translate, authService, navigationService, learningPublicService, modalService, folderService) {

        $scope.boards = [];
        $scope.folders = [];
        $scope.modules = [];
        $scope.dashboards = [];
        $scope.customDashboards = [];
        $scope.favorites = [];
        var movedRootBoard = null;

        $scope.isTrial = false;

        $scope.disableFeedbackToDigiLean = envConfig.disableFeedbackToDigiLean
        
        $scope.authentication = authService
        function getLearningMaterialByLanguage(userlanguage){
            if (!$scope.isTrial) return; // Only needs to fetch when isTrial
            learningPublicService.getUserHelpLearningMaterial(userlanguage).then(function(result){
                var learningmaterial = result;
                $scope.learningMaterialLink = learningmaterial.link;
            });   
        }
        getLearningMaterialByLanguage("en");
     
        
        // Board folder drag and drop handlers
        $scope.dropSuccessHandler = function($event,index,source){
            if(source.boards){
                source.boards.splice(index,1);

                folderService().update(source).then(function (result) {
                });
            } else {
                if(movedRootBoard){
                    var matchingBoard = $filter('filter')($scope.boards, { id: movedRootBoard }, true);
                    if (matchingBoard.length == 0) {
                        $scope.boards.splice(index,1);
                    }
                }         
            }  
            movedRootBoard = null;  
        };

        $scope.dropValidateRoot = function(target, source) {
            if(target == "root" && !source.boards){
                movedRootBoard = source.id;
                return true;
            } else {
                return false;
            }      
        };

        
        $scope.onRootDrop = function($event,$data){

            //prevent duplicate boards 
            var matchingBoard = $filter('filter')($scope.boards, { id: $data.id }, true);
            if (matchingBoard.length == 0) {
                $scope.boards.push($data);
            }
        };

        
        $scope.onFolderDrop = function($event,$data,folder){
            removeBoardFromCurrentLocation($data.id);
            if(!folder.boards){
                folder.boards = [];
            }
            //prevent duplicate boards in folder
            var matchingBoard = $filter('filter')(folder.boards, { id: $data.id }, true);
            if (matchingBoard.length > 0) {
                $scope.boards.push($data);
            } else {
                folder.boards.push($data);
                folderService().update(folder).then(function (result) {
                });
            }
            
        };

        function removeBoardFromCurrentLocation(boardid){
            var boardOnRoot = $filter('filter')($scope.boards, { id: boardid }, true);
            if (boardOnRoot.length > 0) {
                var i = $scope.boards.indexOf(boardOnRoot[0]);
                $scope.boards.splice(i, 1);
            } else {
                for(var f=0; f<$scope.folders.length; f++){
                    var boardInFolder = $filter('filter')($scope.folders[f].boards, { id: boardid }, true);
                    if (boardInFolder.length > 0) {
                        var b = $scope.folders[f].boards.indexOf(boardInFolder[0]);
                        $scope.folders[f].boards.splice(b, 1);
                        folderService().update($scope.folders[f]).then(function (result) {
                        });
                        break;
                    }
                }
            }       
        }

        // TODO - finish implementation: Right click menu on links
        //$(document).on('contextmenu', function (e) {
        //    if (!$(e.target).is("[ui-sref]")) { // If not "draggable" link, then regular menu
        //        return true;
        //    }

        //    // This is a draggable link so add contextmenu
        //    alert('#special right clicked');
        //    return false;
        //    // you may want e.preventDefault() here
        //});

        function loadData(profile) {
            // Map modules
            MapModules(profile.modules);
            // Boards
            getBoards();
            $scope.dashboards = navigationService().getList(profile.user.customerId);
            $scope.dashboards.forEach(dashboard => {
                dashboard.module = "dashboard";
            });
            getDashboards();
        }

        $scope.subscribe('BoardCreated', function () {
            getBoards();
        });
        $scope.subscribe('BoardDeleted', function () {
            getBoards();
        });
        $scope.subscribe('DashboardCreated', function () {
            getDashboards();
        });
        $scope.subscribe('DashboardDeleted', function () {
            getDashboards();
        });
        $scope.subscribe('UserSettingsChanged', function (userSettings) {
            loadSettings(userSettings);
        });

        $scope.subscribe('UserLanguageUpdated', function (settings) {
            getLearningMaterialByLanguage(settings.locale);
        });


        async function getBoards() {
            const folderstructure = await folderService().getList()
            $scope.boards = folderstructure.boardsWithoutFolder;
            $scope.folders = folderstructure.folderWithBoards;

            $scope.boards.forEach(board => {
                board.module = "board";
                board.uiRef = "board({id:" + board.id + "})";
            });
            
            $scope.folders.forEach(folder => {
                folder.boards.forEach(board => {
                    board.module = "board";
                    board.uiRef = "board({id:" + board.id + "})";
                });
            });

            setTimeout(function () {
                var sideMenu = $('#side-menu');
                sideMenu.metisMenu('dispose');
                sideMenu.metisMenu({
                    toggle: false // disable the auto collapse. Default: true.
                });
            }, 200);
            
        }

        async function getDashboards() {
            if (!$scope.hasModule.Board)
                return;
            
            const dashboards = await dashboardService.getAll()
            $scope.customDashboards = [];
            dashboards.forEach(dashboard => {
                dashboard.name = dashboard.title;
                dashboard.module = "dashboard";
                dashboard.uiRef = "dashboard({id:" + dashboard.id + "})";
                $scope.customDashboards.push(dashboard);
            });
            
        }

        function setAdminPrivilege(){
            $scope.anyAdminPrivilege = false;
            $scope.isAdmin = authService.hasRole("Admin");
            $scope.isBusinessunitAdmin = authService.hasRole("BusinessunitAdmin");
            $scope.isDataAdmin = authService.hasRole("DataAdmin");
            $scope.isImprovementAdmin = authService.hasRole("ImprovementAdmin");
            $scope.isDeviationAdmin = authService.hasRole("DeviationAdmin");
            $scope.isProjectAdmin = authService.hasRole("ProjectAdmin");
            $scope.isA3Admin = authService.hasRole("A3Admin");
            $scope.isLearningMaterialEditor = authService.hasRole("LearningAdmin");

            if($scope.isAdmin || $scope.isBusinessunitAdmin ||$scope.isImprovementAdmin || $scope.isDeviationAdmin 
                || $scope.isProjectAdmin || $scope.isA3Admin || $scope.isLearningMaterialEditor || $scope.isDataAdmin) {
                $scope.anyAdminPrivilege = true
            }
        }

        $scope.hasModule = {
            Board: false,
            Improvement: false,
            Deviation: false,
            Project: false,
            A3: false,
            Learning: false,
            Competenceball: false,
            Hours: false,
            Strategy: false,
        }
        // Modules
        function MapModules(modules) {
            $scope.hasModule.Board = hasModule(modules, "BOARD");
            $scope.hasModule.Improvement = hasModule(modules, "IMPROVEMENT");
            $scope.hasModule.Deviation = hasModule(modules, "DEVIATION");
            $scope.hasModule.Project = hasModule(modules, "PROJECT");
            $scope.hasModule.A3 = hasModule(modules, "A3");
            $scope.hasModule.Learning = hasModule(modules, "LEARNING");
            $scope.hasModule.Competenceball = hasModule(modules, "COMPETENCEBALL");
            $scope.hasModule.Hours = hasModule(modules, "HOURREGISTRATION");
            $scope.hasModule.Strategy = hasModule(modules, "STRATEGY");
        }

        function hasModule(modules, moduleName) {
            if (!modules || !Array.isArray(modules))
                return false
            return modules.some(m => m.name === moduleName)
        }

        /* Favorite navigation */
        function updateSettings() {
            $scope.userSettings.favorites = $scope.favorites;
            employeeService.updateUserSettings($scope.userSettings).then(function () {
                $scope.publish("UserSettingsChanged", $scope.userSettings);
            });
        }

        $scope.onDrop = function (target, source) {
            if (target === 'favorite') {
                if (typeof source === "string" && source.startsWith("index")) {
                    addFavoriteFromUiRef(source);
                } else {
                    var newFavorite = {
                        name: source.name,
                        module: source.module,
                        uiRef: source.uiRef
                    }
                    if (!$scope.favorites) $scope.favorites = [];
                    $scope.favorites.push(newFavorite);
                    updateSettings();
                }
                $("#favoritemenu").toggleClass("active", true);
                $("#favoritemenu ul").toggleClass("in", true);
            }
        };
        $scope.dropValidate = function (target, source) {
            if (target === 'remove') {
                $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'DASHBOARD_DELETE_FAVORITE', 'DASHBOARD_DELETE_FAVORITE_DESCRIPTION']).then(function (translations) {
                    var modalOptions = {
                        closeButtonText: translations.COMMON_CANCEL,
                        actionButtonText: translations.COMMON_DELETE,
                        headerText: translations.DASHBOARD_DELETE_FAVORITE,
                        bodyText: translations.DASHBOARD_DELETE_FAVORITE_DESCRIPTION
                    };

                    modalService.showModal({}, modalOptions).then(function (result) {
                        if (source) {
                            var matchingUser = $filter('filter')($scope.favorites, { uiRef: source }, true);
                            if (matchingUser.length > 0) {
                                var i = $scope.favorites.indexOf(matchingUser[0]);
                                $scope.favorites.splice(i, 1);
                                updateSettings();
                                return true;
                            }
                        }
                    });
                });
            } else
                return target !== source;
        };
        function addFavoriteFromUiRef(source) {
            var module = "";
            var translate = "";
            switch (source) {
                case "index.personal":
                    module = "personal";
                    translate = "BOARD_PERSONAL_BOARD";
                    break;
                case "index.myspace":
                    module = "dashboard";
                    translate = "COMMON_MY_SPACE";
                    break;
                case "index.improvement":
                    module = "improvement";
                    translate = "IMPROVEMENT_PRIORITIZE";
                    break;
                case "index.improvementongoing":
                    module = "improvement";
                    translate = "IMPROVEMENT_ONGOING";
                    break;
                case "index.improvementstatus":
                    module = "improvement";
                    translate = "COMMON_STATUS";
                    break;
                case "index.improvementsearch":
                    module = "improvement";
                    translate = "COMMON_SEARCH";
                    break;
                case "index.deviationongoing":
                    module = "deviation";
                    translate = "IMPROVEMENT_ONGOING";
                    break;
                case "index.deviationstatus":
                    module = "deviation";
                    translate = "COMMON_STATUS";
                    break;
                case "index.deviationprojectstatus":
                    module = "deviation";
                    translate = "PROJECT_PROJECT_STATUS";
                    break;
                case "index.deviationevaluation":
                    module = "deviation";
                    translate = "IMPROVEMENT_EVALUATION";
                    break;
                case "index.deviationsearch":
                    module = "deviation";
                    translate = "COMMON_SEARCH";
                    break;
                case "index.A3":
                    module = "a3";
                    translate = "COMMON_A3";
                    break;
                case "index.a3templates":
                    module = "a3";
                    translate = "A3_TEMPLATES";
                    break;
                case "index.users":
                    module = "admin";
                    translate = "ADMIN_USERS";
                    break;
                case "index.assets":
                    module = "admin";
                    translate = "COMMON_ASSETS";
                    break;
                case "index.adminboards":
                    module = "admin";
                    translate = "COMMON_BOARDS";
                    break;
                case "index.adminflexiboards":
                    module = "admin";
                    translate = "COMMON_BOARDS";
                    break;
                case "index.admindeviations":
                    module = "admin";
                    translate = "COMMON_DEVIATION";
                    break;
                case "index.adminprojects":
                    module = "admin";
                    translate = "COMMON_PROJECT";
                    break;
                case "index.admina3":
                    module = "admin";
                    translate = "COMMON_A3";
                    break;
                case "index.adminimprovement":
                    module = "admin";
                    translate = "COMMON_IMPROVEMENT";
                    break;
                case "index.admindashboards":
                    module = "admin";
                    translate = "COMMON_DASHBOARDS";
                    break;
            }

            $translate(translate).then(function (trans) {
                var newFavorite = {
                    name: trans,
                    module: module,
                    uiRef: source
                }
                if (!$scope.favorites) $scope.favorites = [];
                $scope.favorites.push(newFavorite);
                updateSettings();
            });
        }

        $scope.userSettings = {}

        var loadSettings = function (settings) {
            if (!settings) return;
            $scope.userSettings = settings;
            if (settings.favorites) {
                $scope.favorites = settings.favorites;
            }

        }

        // subscribe to userFullProfile
        userFullProfile.subscribe(profile => {
            $scope.isTrial = profile.isTrial;
            if (!profile || !profile.user)
                return
            loadData(profile);
            loadSettings(profile.settings);
            getLearningMaterialByLanguage(profile.user.preferredLanguage);
            setAdminPrivilege();
        })
    }]);
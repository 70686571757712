import envConfig from "@common/envConfig"
import * as boardService from "@common/services/boardService"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('boardDesignerResolverController', ['$scope', '$stateParams', '$state',
    function ($scope, $stateParams, $state) {
        $scope.boardid = parseInt($stateParams.id);
        $scope.isLoading = true;
        $scope.boardData = null;
        boardService.get($scope.boardid).then(function (boardData) {
            $scope.boardData = boardData;
            
            var board = boardData.board;
            if (board.boardType == "Project") {
                $state.go('board', { id: board.id }, { reload: false });
            }
            
            $scope.isLoading = false;
            
        }).finally(() => {
            if (envConfig.isTeamsMode)
                $scope.$apply()
        })
    }]);
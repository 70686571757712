﻿import envConfig from "@common/envConfig";

angular.module("DigiLean").service('navigationService', ['$http', 'moduleCache',
    function ($http, moduleCache) {
        function navigationService() {
            var dashBoardLinks = [
                {
                    customerId: 1,
                    uiRef: "systemdashboard",
                    name: "Customers"
                },
                {
                    customerId: 1,
                    uiRef: "dashboardKS",
                    name: "Kaizen Solutions"
                },
                , {
                    customerId: 8,
                    uiRef: "dashboardCharts",
                    name: "Charts"
                },
                {
                    customerId: 8,
                    uiRef: "dashboardtest",
                    name: "Miscellaneous"
                },
                {
                    customerId: 1,
                    uiRef: "dashboardConnectors",
                    name: "Connector Monitoring"
                }
            ];


            return {
                clearCache: function () {
                    moduleCache.removeAll();
                },

                updateCache: function (userProfile) {
                    moduleCache.put('userAssets', userProfile.assets);
                    moduleCache.put('customerModules', userProfile.modules);
                },

                hasAccessToAsset: function (assetId) {
                    var modules = moduleCache.get('userAssets');
                    if (!modules) return false;
                    var hasAccess = modules.filter(module => module.id === assetId);
                    return hasAccess.length > 0;
                },

                isViewer: function (assetId) {
                    var modules = moduleCache.get('userAssets');
                    if (!modules) return false;
                    var hasRole = modules.filter(module => module.id === assetId && module.assetRole === 'Viewer');
                    return hasRole.length > 0;
                },

                isBoardAdmin: function (boardId) {
                    var resourceUrl = envConfig.webApiUrl + "/user/isBoardAdmin/" + boardId;
                    var url = resourceUrl;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                hasModule: function (moduleName) {
                    const modules = moduleCache.get('customerModules');
                    if (!modules)
                        return false
                    const hasAccess = modules.some(m => m.name == moduleName)
                    return hasAccess
                },

                getList: function (customerId) {
                    var customerLinks = dashBoardLinks.filter(l => l.customerId == customerId);
                    return customerLinks;
                }
            }
        }
        return navigationService;
    }]);
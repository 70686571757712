import {LitElement, html} from "lit"
import {customElement, state} from "lit/decorators.js"
import { authUser } from "@common/stores/userStore"
import { DigiLeanAuthUser } from "@common/model/types"
import { Subscription } from "rxjs"

@customElement('user-debug')
export class UserDebug extends LitElement {

    sub?: Subscription

    @state()
    authUser?: DigiLeanAuthUser

    connectedCallback(): void {
        super.connectedCallback()
        this.sub = authUser.subscribe(u => this.authUser = u)
    }
    disconnectedCallback(): void {
        super.disconnectedCallback()
        if (this.sub)
            this.sub.unsubscribe()
    }

    render() {
        if (!this.authUser)
            return html`<span>loading</span>`

        const userJsonString = JSON.stringify(this.authUser, null, 2)
        return html`
            <label>auth user</label><br>
            <textarea cols="100" rows="30" .value=${userJsonString}></textarea>
        `
    }
}
angular
    .module('DigiLean')
    .controller("goalCreatePopup", ['$scope', '$uibModalInstance', 'strategyGoalService', 'options',
    function ($scope, $uibModalInstance, strategyGoalService, options) 
    {
        
        if(options){
            $scope.goalId = options.goalId;
            $scope.options = options;
        }
        $scope.isValid = false;
        $scope.goalCreateChangedHandler = function(goalCreate) {
            $scope.isValid = false;
            $scope.goalCreate = goalCreate;
            if (!goalCreate) return;
            if (!goalCreate.color) return;
            $scope.color = goalCreate.color;
            if (!goalCreate.name) return;
            $scope.isValid = true;
        }
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.save = function () {
            if($scope.goalCreate.assetList && $scope.goalCreate.assetList.length >0){
                var strategyGoal = {
                    name: $scope.goalCreate.name,
                    color: $scope.goalCreate.color,
                    assetId: null,
                    parentId: $scope.goalId
                };
                var strategyGoals = [];
                for(var i=0; i < $scope.goalCreate.assetList.length; i++){
                    var strategyGoal = {
                        name: $scope.goalCreate.name,
                        color: $scope.goalCreate.color,
                        assetId: $scope.goalCreate.assetList[i].id,
                        parentId: $scope.goalId
                    };
                    strategyGoals.push(strategyGoal);
                }
                strategyGoalService().createGoals(strategyGoals).then(function (result) {
                    $uibModalInstance.close();
                });
            } else {
                var strategyGoal = {
                    name: $scope.goalCreate.name,
                    color: $scope.goalCreate.color,
                    assetId: null,
                    parentId: $scope.goalId
                };
                strategyGoalService().createGoal(strategyGoal).then(function (created) {
                    $uibModalInstance.close(created);
                });
            }
        };
    }]);

import {LitElement, html, css, PropertyValues} from "lit"
import {customElement, property, query, state} from "lit/decorators.js"
import { debounce } from "lodash"
import { PdfRenderer, PdfZoomOptions } from "./pdfRenderer"

interface zoomSelectOption {
    option: PdfZoomOptions
    label: string
}
const viewOptions: zoomSelectOption[] = []

for (const opt in PdfZoomOptions) {
    const option = PdfZoomOptions[opt]
    viewOptions.push({option, label: option.replace("page", "")})
}

let threshold = 20

@customElement('pdf-viewer')
export class PdfViewer extends LitElement {
    static styles = css`
        :host {
            display: block;
        }
        .wrapper {
            position: relative;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            height: 100%;
            max-height: 100%;
            background: var(--digilean-secondary-background);
            color: var(--digilean-secondary-text);
            overflow: auto;
        }
        .canvas-wrapper {
            box-sizing: border-box;
            flex: 1 1 auto;
            overflow-y: auto;
            height: 0;
        }
        
        .menu {
            padding: 0.4rem 1.4rem;
            display: inline-flex;
            justify-content: space-between;
            flex-direction: row;
            gap: 0.5rem;
        }
        .menu-item {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
        }
        .separator {
            margin: 0 0.3rem;
        }
        .div-spinner {
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background-color: rgba(100,100,100,0.2);;
            display: inline-flex;
            box-sizing: border-box;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            vertical-align: middle;
        }
    `
    // ********
    // props / state
    // 
    private _url = ""
    @property({attribute: true})
    set url(value) {
        if (value) {
            this.debounceLoadPdf()
        }
        this._url = value
    }
    get url() {
        return this._url
    }

    private _zoomoption = PdfZoomOptions.pageFit
    @property({attribute: true})
    set zoomoption(value: PdfZoomOptions) {
        if (value && value !== this._zoomoption && !value.startsWith("{"))
        {
            this._zoomoption = value
            this.checkZoomOptionProp()
        }
    }
    get zoomoption() {
        return this._zoomoption
    }
    

    @state()
    loading = true

    @query("div.canvas-wrapper")
    canvasWrapperEl?: HTMLDivElement

    @query("canvas")
    canvasEl?: HTMLCanvasElement

    pdfRenderer?: PdfRenderer
    
    viewOptionSelected = viewOptions[0]

    @state()
    currentPageNo = 1

    @state()
    totalNumOfPages = 1

    // ********
    // lifecycle
    //
    protected firstUpdated(_changedProperties: PropertyValues): void {
        if (this.canvasWrapperEl)
            this.resizeObserver.observe(this.canvasWrapperEl)
        this.checkZoomOptionProp()
        this.debounceLoadPdf()
    }
    disconnectedCallback(): void {
        if (this.canvasWrapperEl)
            this.resizeObserver.unobserve(this.canvasWrapperEl)
        if (this.pdfRenderer)
            this.pdfRenderer.dispose()
    }
    // ******
    // internal functions
    //
    nextPage() {
        if (this.currentPageNo == this.totalNumOfPages)
            return
        this.currentPageNo += 1
        this.renderPdfPage()
    }
    prevPage() {
        if (this.currentPageNo == 1)
            return
        this.currentPageNo -= 1
        this.renderPdfPage()
    }

    async loadPdf() {
        if (!this.url || this.url.startsWith("{")) { // safe-guarding angular early
            return
        }
        if (!this.canvasEl) {
            return
        }
        console.log("Load new pdf", this.url)
        const canvas = this.canvasEl
        this.currentPageNo = 1
        if (this.pdfRenderer)
            this.pdfRenderer.dispose()
        this.pdfRenderer = new PdfRenderer(this.url, canvas)
        await this.renderPdfPage()
    }
    debounceLoadPdf = debounce(this.loadPdf, 1000)

    resizePage() {
        if (!this.canvasWrapperEl) {
            return
        }
        const wrapper = this.canvasWrapperEl
        const wrapperRectangle = wrapper.getBoundingClientRect()

        if (this.pdfRenderer)
            this.pdfRenderer.resizeCurrentPage(wrapperRectangle, this.viewOptionSelected.option)
        this.loading = false
    }

    debouncePdfResizeActual = debounce(this.resizePage, 200)
    renderPdfPage = async () => {
        if (!this.canvasWrapperEl) {
            return
        }
        const wrapper = this.canvasWrapperEl
        const wrapperRectangle = wrapper.getBoundingClientRect()
        
        if (this.pdfRenderer) {
            await this.pdfRenderer.renderPage(this.currentPageNo, wrapperRectangle, this.viewOptionSelected.option)
            this.totalNumOfPages = this.pdfRenderer.numberOfPages
        }
        this.loading = false
    }
    debouncePdfResize () {
        if (!this.pdfRenderer)
            return
        this.loading = true
        this.debouncePdfResizeActual()
    }
    previousWrapperBoxSize: DOMRect | null = null

    viewOptionChanged = (e: Event) => {
        const selectEl = e.target as HTMLSelectElement

        const value = selectEl.value as PdfZoomOptions
        if (value) {
            this.zoomoption = value
            this.dispatchEvent(new CustomEvent("zoom-option-changed", {
                bubbles: false,
                composed: true,
                detail: this.viewOptionSelected.option
            }))
    
            this.debouncePdfResize()
        }
    }

    resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
            if (entry.target.nodeName == "DIV" && entry.target.classList.contains("canvas-wrapper") && entry.contentBoxSize) {
                const wrapper = entry.target as HTMLDivElement
                const contentBoxSize = wrapper.getBoundingClientRect()
                if (this.previousWrapperBoxSize) {
                    const diffWidth = this.previousWrapperBoxSize.width - contentBoxSize.width
                    const diffHeight = this.previousWrapperBoxSize.height - contentBoxSize.height
                    // console.log(`diff = ${diffWidth}, ${diffHeight}`)
                    let passThreshold = Math.abs(diffWidth) > threshold || Math.abs(diffHeight) > threshold
                    if (!passThreshold)
                        continue
                }
                this.debouncePdfResize()
                this.previousWrapperBoxSize = contentBoxSize
            }
        }
    })
    checkZoomOptionProp() {
        if (this.zoomoption) {
            const option = viewOptions.find(o => o.option == this.zoomoption)
            if (option) {
                this.viewOptionSelected = option
                this.debouncePdfResize()
            }
        }
    }
    
    render() {
        return html`
            <link href="/assets/global.css" rel="stylesheet">
            <div class="wrapper">
                <div class="menu">
                    <div class="menu-item">
                        <span>
                            <span>${this.currentPageNo}</span>
                            <span class="separator">/</span>
                            <span>${this.totalNumOfPages}</span>
                        </span>
                        <digilean-button @click=${this.prevPage}> 
                            <fa-icon icon="fa fa-angle-left"></fa-icon>
                        </digilean-button>
                        <digilean-button @click=${this.nextPage}>
                            <fa-icon icon="fa fa-angle-right"></fa-icon>
                        </digilean-button>
                    </div>
                    <div class="menu-item">
                        <select class="form-control" id="viewOptions" @change=${this.viewOptionChanged}>
                        ${viewOptions.map(v => {
                            return html`<option
                                .value=${v.option} 
                                ?selected=${v.option == this.zoomoption}>
                                ${v.label}
                            </option>`
                        })}
                            
                        </select>
                    </div>
                    <div class="menu-item"></div>
                </div>
                <div class="canvas-wrapper">
                    ${this.loading ? html`
                        <div class="div-spinner">
                            <spinner-icon .spin=${this.loading}></spinner-icon>
                        </div>
                    ` : html``}
                    <canvas ref="canvasEl"></canvas>
                </div>
            </div>
        `
    }
}
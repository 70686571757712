import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"
import * as employeeService from "@common/services/employeeService"

angular
    .module('DigiLean')
    .directive("yearlyBoard", ['$filter', '$translate', '$uibModal', '$state', 'boardTaskService', 'navigationService', 'dragulaService', 'yearlyboardRenderService', 'monthlyRenderService', 'weeklyRenderService', 'modalService', 'boardRowProvider', 'actionListRowProvider','debounce',
        function ($filter, $translate, $uibModal, $state, boardTaskService, navigationService, dragulaService, yearlyboardRenderService, monthlyRenderService, weeklyRenderService, modalService, boardRowProvider, actionListRowProvider, debounce) {
            return {
                templateUrl: 'yearlyboard.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'options': '=',
                    'newTask': '<?',
                    'smartActionListCellUpdated': '<?',
                    'smartActionListTasks': '<?',
                    'optionsChangeHandler': '&?'
                },
                link: function (scope, elem, attrs) {
                    var yearlyViewModeProvider = {
                        viewMode: "year",
                        renderService: yearlyboardRenderService,
                        load: function () { loadYear() }
                    }
                    var monthlyViewModeProvider = {
                        viewMode: "month",
                        renderService: monthlyRenderService,
                        load: function () { loadMonth() }
                    }
                    var weeklyViewModeProvider = {
                        viewMode: "week",
                        renderService: weeklyRenderService,
                        load: function () { loadWeek() }
                    }

                    scope.isStar = false;
                    scope.quickAccess = [];

                    // Row provider
                    var rowProvider = boardRowProvider; // Default provider

                    // Possible categories to group by => Category should provide rows
                    scope.categories = []
                    scope.selectedCategory = []
                    scope.useCategorySelection = false; // Active when smartActionList

                    scope.months = timeService.getMonths();
                    scope.data = {
                        rows: [],
                        tasks: []
                    }

                    // Task detail level
                    scope.taskDetailLevel = "compact";
                    scope.showPostIts = true;
                    scope.postIts = null;
                    // --------- SET DEFAULT TiMES -----------
                    scope.selectedYear = moment().year(); // Get currentYear if not set
                    var currentMonth = moment().month();
                    var month = scope.months[currentMonth];
                    month.weeks = timeService.getWeeksInMonth(scope.selectedYear, month.id);
                    scope.selectedMonth = month;
                    setMonthSelection();
                    var currentWeek = moment().isoWeek();
                    var hasWeek = $filter('filter')(scope.selectedMonth.weeks, { number: currentWeek }, true);
                    if (hasWeek.length > 0) {
                        scope.selectedWeek = hasWeek[0];
                    } else {
                        scope.selectedWeek = scope.selectedMonth.weeks[0];
                    }
                    setWeekSelection();
                    var getDisplayPeriods = function () {
                        return {
                            year: scope.selectedYear,
                            month: scope.selectedMonth,
                            week: scope.selectedWeek,
                        }
                    }

                    // --------- SET VIEW MODES -----------
                    scope.selectedViewMode = yearlyViewModeProvider;
                    scope.viewModeSelection = 'year';
                    var currentViewMode = "year";



                    // SETUP AND OPTIONS
                    scope.showTeamMembers = false;
                    scope.showHeader = false;
                    scope.useActionList = false;
                    scope.showWrench = true;
                    scope.isUnprocessedFilterActive = false;

                    // filter
                    scope.filterUser = function (user) {
                        for (let index = 0; index < scope.data.tasks.length; index++) {
                            const task = scope.data.tasks[index];

                            if (!user) { // If no user is filter, then all are visible
                                task.isVisible = true;
                                task.animate("fadeInLeft");
                                continue;
                            }
                            // If selected user, than apply filter
                            if (task.responsibleUserId == user.userId) {
                                task.isVisible = true;
                                task.animate("fadeInLeft");
                            } else {
                                task.isVisible = false;
                                task.animate("fadeOutRight");
                            }

                        }

                    }

                    scope.resetFilter = function () {
                        scope.isUnprocessedFilterActive = false;
                        for (let index = 0; index < scope.data.tasks.length; index++) {
                            const task = scope.data.tasks[index];                        
                                task.isVisible = true;
                                task.animate("fadeInLeft");
                        }
                    }

                    scope.filterOnStatusBlank = function () {
                        scope.isUnprocessedFilterActive = true;
                        for (let index = 0; index < scope.data.tasks.length; index++) {
                            const task = scope.data.tasks[index];

                            // If status is not blank, than apply filter
                            if (task.status == 'blank') {
                                task.isVisible = true;
                                task.animate("fadeInLeft");
                            } else {
                                task.isVisible = false;
                                task.animate("fadeOutRight");
                            }

                        }
                    }

                    scope.$watch("options", function (options) {
                        if (!options) return;
                        if (options.showTeamMembers) {
                            scope.showTeamMembers = options.showTeamMembers;
                        }
                        if (options.showHeader) {
                            scope.showHeader = options.showHeader;
                        }
                        if (options.useActionList) {
                            scope.useActionList = options.useActionList;
                            scope.useCategorySelection = true;
                            // Set actionlist rowProvider
                            rowProvider = actionListRowProvider;
                        }

                        if (options.taskDetailLevel) {
                            scope.taskDetailLevel = options.taskDetailLevel;
                        }
                        if (options.viewModeSelection) {
                            scope.viewModeSelection = options.viewModeSelection;
                        }
                        if (options.hideWrench) {
                            scope.showWrench = false;
                        }
                        if (options.showArchive) {
                            scope.showArchive = true;
                        } else {
                            scope.showArchive = false;
                        }
                        scope.selectedViewMode.load();
                        
                    })

                    scope.$watch("viewModeSelection", function (viewMode) {
                        // View Mode 
                        if (viewMode == currentViewMode) return;
                        if (viewMode == "year") {
                            scope.selectedViewMode = yearlyViewModeProvider;
                            scope.taskDetailLevel = "compact";
                            // setMonthSelection(null);
                            // setWeekSelection(null);
                        }
                        if (viewMode == "month") {
                            setMonthSelection(scope.selectedMonth);
                            // setWeekSelection(null);
                            scope.selectedViewMode = monthlyViewModeProvider;
                        }
                        if (viewMode == "week") {
                            setWeekSelection(scope.selectedWeek);
                            scope.selectedViewMode = weeklyViewModeProvider;
                            scope.taskDetailLevel = "normal";
                        }
                        if (scope.options && scope.options.taskDetailLevel) {
                            scope.taskDetailLevel = scope.options.taskDetailLevel;
                        }
                        currentViewMode = viewMode;
                        scope.selectedViewMode.load();
                    })

                    scope.notfiyOptionsChanged = function () {
                        if (!scope.optionsChangeHandler) return;
                        scope.options.taskDetailLevel = scope.taskDetailLevel;
                        scope.options.viewModeSelection = scope.viewModeSelection;
                        scope.optionsChangeHandler({ options: scope.options });
                    }

                    var getSelectedTimeOptions = function () {
                        var timeOptions = {
                            year: scope.selectedYear,
                            month: scope.selectedMonth,
                            week: scope.selectedWeek
                        }
                        if (scope.selectedMonth) {
                            timeOptions.firstWeek = timeOptions.month.weeks[0];
                            timeOptions.lastWeek = timeOptions.month.weeks[timeOptions.month.weeks.length - 1];
                        }
                        if (scope.selectedWeek) {
                            timeOptions.firstDay = timeOptions.week.days[0];
                            timeOptions.lastDay = timeOptions.week.days[timeOptions.week.days.length - 1];
                        }
                        return timeOptions;
                    }

                    var renderService = function () {
                        return scope.selectedViewMode.renderService;
                    }

                    scope.selectedCategoryChanged = function () {
                        // create rows and load data;
                        scope.createLayoutAndLoad();
                        // alert(category.changed);
                    }

                    scope.selectMonth = function (month, refreshData) {
                        scope.selectedMonth = month;
                        scope.selectedMonth.weeks = timeService.getWeeksInMonth(scope.selectedYear, month.id);
                        setMonthSelection(month);
                        // Also adjust week to be in correct month
                        scope.selectedWeek = month.weeks[0];
                        setWeekSelection(scope.selectedWeek);
                        if (scope.viewModeSelection == "year") {
                            scope.viewModeSelection = "month";
                        } else {
                            if (refreshData) {
                                scope.selectedViewMode.load();
                            }
                        }
                    };

                    function setMonthSelection(selectedMonth) {
                        for (let index = 0; index < scope.months.length; index++) {
                            const element = scope.months[index];

                            if (selectedMonth && element.id == selectedMonth.id) {
                                element.state = "month-selected"
                            } else {
                                element.state = "month-unselected"
                            }
                        }
                    }

                    scope.selectWeek = function (week, refreshData) {
                        scope.selectedWeek = week;
                        setWeekSelection(week);

                        if (scope.viewModeSelection == "month") {
                            scope.viewModeSelection = "week";
                        } else {
                            if (refreshData) {
                                scope.selectedViewMode.load();
                            }
                        }


                    };
                    function setWeekSelection(selectedWeek) {
                        for (let index = 0; index < scope.selectedMonth.weeks.length; index++) {
                            const element = scope.selectedMonth.weeks[index];
                            if (selectedWeek && element.number == selectedWeek.number) {
                                element.state = "week-selected"
                            } else {
                                element.state = "week-unselected"
                            }
                        }
                    }

                    /* Currently yearly board subscribe the event SmartActionListCellUpdated and do not use this logic since it was not reliable                       
                    scope.$watch("smartActionListCellUpdated", function (cell) {
                        if (!cell) return;
                        // Check if task should
                        if (!scope.useCategorySelection) return;
                        if (cell.smartTableColumnId == scope.selectedCategory.model.id) {
                            for (let index = 0; index < scope.data.rows.length; index++) {
                                const row = scope.data.rows[index];
                                row.taskRows = [];
                            }
                            var renderOptions = {
                                animationClass: "slideInLeft"
                            }
                            renderTasks(scope.data.tasks, renderOptions);
                        }
                    });*/

                    scope.subscribe("SmartActionListCellUpdated", function (cell) {
                        if (!cell) return;
                        // Check if task should
                        if (!scope.useCategorySelection) return;
                        if (cell.smartTableColumnId == scope.selectedCategory.model.id) {
                            scope.selectedViewMode.load();
                        }
                    });
                    
                    scope.subscribe("TaskAssigned", function (status) {
                        if (!scope.useActionList) return;
                        if (!scope.useCategorySelection) return;
                        if (!scope.selectedCategory) return;
                        var task = getTask(status.taskId);
                        if (!task) return;
                        if (scope.selectedCategory.model.attributeType == "responsible") {
                            setTimeout(function () {
                                scope.createLayoutAndLoad();
                            }, 500);
                        }
                    });
                    // subscribe to events
                    scope.subscribe("NewTask", function (task) {
                        if (scope.useActionList) return;
                        if (!task.startTime) task.startTime = task.endTime;
                        task.startTime = moment.utc(task.startTime).local();
                        task.endTime = moment.utc(task.endTime).local();
                        addTask(task);
                    });

                    scope.$watch("newTask", function (task) {
                        if (!task) return;
                        addTask(task);
                    });

                    scope.subscribe('TaskCloned', function (task) {
                        if (task.boardId === scope.boardId) {
                            if (!task.startTime) task.startTime = task.endTime;
                            task.startTime = moment.utc(task.startTime).local();
                            task.endTime = moment.utc(task.endTime).local();
                            addTask(task);
                        }
                    });


                    function addTask(task) {
                        var exist = $filter('filter')(scope.data.tasks, { id: task.id });
                        if (exist.length > 0) {
                            return;
                        }
                        if (scope.board.id == task.boardId) {
                            initTask(task);
                            task.animate("bounce");
                            scope.data.tasks.push(task);
                            var service = renderService();
                            service().calculateDuration(task, getSelectedTimeOptions());
                            if (scope.useActionList) {
                                task.attributes = task.cellModels;
                                // var row = rowProvider().getRowForTask(scope.model, scope.selectedCategory, scope.data.rows, task);
                                renderTask(task, null);
                                // service().reCalculateRowByIndex(scope.data, row.index, getSelectedTimeOptions());
                            } else {
                                service().reCalculateRow(scope.data, task.rowCategoryId, getSelectedTimeOptions());
                            }

                        }
                    }
                    scope.subscribe('TaskChangedBoard', function (change) {
                        if(change.toBoardId === null || change.toBoardId === 0 
                            || (change.fromBoardId === scope.board.id && change.toBoardId !== scope.board.id)){
                            var task = getTask(change.taskId);
                            if (task) {
                                var service = renderService();
                                task.animate("bounce");
                                var index = scope.data.tasks.indexOf(task);
                                scope.data.tasks.splice(index, 1);
                                service().reCalculateRow(scope.data, task.rowCategoryId, getSelectedTimeOptions());
                            }
                        } 
                    });
                    scope.subscribe('ActionItemMovedToTask', function (task) {
                        if (!task) return;
                        var existingtask = getTask(task.id);
                        if (existingtask) {
                            var service = renderService();
                            var fromRowId = existingtask.rowCategoryId;
                            existingtask.rowCategoryId = task.rowCategoryId;
                            existingtask.animate("bounce");
                            service().reCalculateRow(scope.data, fromRowId, getSelectedTimeOptions());
                            service().reCalculateRow(scope.data, task.rowCategoryId, getSelectedTimeOptions());
                        } else {
                            addTask(task);
                        }                
                    });
                    scope.subscribe("TaskDeleted", function (taskId) {
                        var task = getTask(taskId);
                        if (task) {
                            var service = renderService();
                            task.animate("bounce");
                            var index = scope.data.tasks.indexOf(task);
                            scope.data.tasks.splice(index, 1);
                            service().reCalculateRow(scope.data, task.rowCategoryId, getSelectedTimeOptions());
                        }
                    });
                    scope.subscribe('SendTaskRepeated', function (data) {
                        if(data && data.original && data.original.boardId === scope.board.id){
                            angular.forEach(data.repeatedTasks, function (task, key) {
                                if (!task.startTime) task.startTime = task.endTime;
                                task.startTime = moment.utc(task.startTime).local();
                                task.endTime = moment.utc(task.endTime).local();
                                addTask(task);
                            });
                        }
                    });
                    scope.subscribe('TasksDeleted', function (taskIds) {
                        angular.forEach(taskIds, function (id, key) {
                            var task = getTask(id);
                            if (task) {
                                var service = renderService();
                                task.animate("bounce");
                                var index = scope.data.tasks.indexOf(task);
                                scope.data.tasks.splice(index, 1);
                                service().reCalculateRow(scope.data, task.rowCategoryId, getSelectedTimeOptions());
                            }
                        });
                    });
                    scope.subscribe('TaskDurationUpdated', function (durationUpdated) {
                        var task = getTask(durationUpdated.taskId);
                        if (!task) return;
                        updateTaskDuration(task, durationUpdated);
                    });

                    scope.subscribe('TaskDateMoved', function (taskMoved) {
                        var task = getTask(taskMoved.taskId);
                        if (!task && scope.useActionList) {
                            smartActionListTasks
                            // Check if this a task that was just assigned a date
                            var actionListTasks = scope.smartActionListTasks;
                            var taskExistInActionList = $filter('filter')(actionListTasks, { id: taskMoved.taskId });
                            if (taskExistInActionList[0]) {
                                addTask(taskExistInActionList[0]);
                            }
                            return;
                        }
                        if (!task) return;
                        var startDate = task.startTime;
                        if (!startDate) startDate = taskMoved.boardDate;
                        var durationUpdated = {
                            taskId: taskMoved.taskId,
                            startTime: startDate,
                            endTime: taskMoved.boardDate
                        }
                        updateTaskDuration(task, durationUpdated);
                    });
                    scope.subscribe('TaskStartDateUpdated', function (taskMoved) {
                        var task = getTask(taskMoved.taskId);
                        if (!task) return;
                        var startDate = taskMoved.startTime;
                        if (!startDate) startDate = task.endTime;
                        var durationUpdated = {
                            taskId: taskMoved.taskId,
                            startTime: startDate,
                            endTime: task.endTime
                        }
                        updateTaskDuration(task, durationUpdated);
                    });

                    function updateTaskDuration(task, durationUpdated) {
                        var start = moment(durationUpdated.startTime).toDate().getTime();
                        var end = moment(durationUpdated.endTime).toDate().getTime();
                        var durationStart = moment(durationUpdated.startTime);
                        var durationEnd = moment(durationUpdated.endTime);
                        var service = renderService();
                        // If task is moved out from time scope we need to remove it
                        if ((durationStart < scope.currentTimePeriod.from && durationEnd < scope.currentTimePeriod.from) ||
                            (durationStart > scope.currentTimePeriod.to && durationEnd > scope.currentTimePeriod.to)) {
                            task.animate("slideOutRight");
                            setTimeout(function () {
                                var index = scope.data.tasks.indexOf(task)
                                scope.data.tasks.splice(index, 1);
                                service().calculateDuration(task, getSelectedTimeOptions());
                                service().reCalculateRow(scope.data, task.rowCategoryId, getSelectedTimeOptions());
                            }, 1000);
                            // return;
                        } else if (task.startTime.getTime() != start || task.endTime.getTime() != end) {
                            task.startTime = moment(durationUpdated.startTime);
                            task.endTime = moment(durationUpdated.endTime);
                            task.duration = timeService.getDurationInDays(task.startTime, task.endTime);
                            task.animate("rubberBand");
                            // We need to check if task has changed timeperiod out of current scope

                            service().calculateDuration(task, getSelectedTimeOptions());
                            service().reCalculateRow(scope.data, task.rowCategoryId, getSelectedTimeOptions());


                        }
                    }
                    scope.subscribe('TaskMovedRow', function (updateRowPosition) {
                        var task = getTask(updateRowPosition.taskId);
                        if (task) {
                            var service = renderService();
                            task.rowCategoryId = updateRowPosition.toRowId;
                            task.animate("bounce");
                            service().reCalculateRow(scope.data, updateRowPosition.fromRowId, getSelectedTimeOptions());
                            service().reCalculateRow(scope.data, updateRowPosition.toRowId, getSelectedTimeOptions());
                        }
                    });


                    scope.editable = false;

                    function checkBoardSettings(settingsAsString) {
                        if (settingsAsString) {
                            scope.settings = JSON.parse(settingsAsString);
                            if (scope.settings && scope.settings.boardOptions) {
                                var viewMode = scope.settings.boardOptions.viewModeSelection;
                                if (viewMode == "year") {
                                    scope.selectedViewMode = yearlyViewModeProvider;
                                    scope.taskDetailLevel = "compact";
                                }
                                if (viewMode == "month") {
                                    setMonthSelection(scope.selectedMonth);
                                    scope.selectedViewMode = monthlyViewModeProvider;
                                }
                                if (viewMode == "week") {
                                    setWeekSelection(scope.selectedWeek);
                                    scope.selectedViewMode = weeklyViewModeProvider;
                                    scope.taskDetailLevel = "normal";
                                }
                                if (scope.settings.boardOptions.taskDetailLevel) {
                                    scope.taskDetailLevel = scope.settings.boardOptions.taskDetailLevel;
                                }
                                if (scope.settings.showPostIts) {
                                    scope.showPostIts = scope.settings.showPostIts;
                                    scope.postIts = scope.settings.PostIts;
                                }
                                scope.viewModeSelection = viewMode;
                                currentViewMode = viewMode;
                            }
                        }
                    }
                    scope.$watch("model", function (model) {
                        if (scope.model == null) return;
                        scope.board = scope.model.board;
                        scope.title = scope.board.name;
                        checkBoardSettings(scope.board.settings);
                        scope.isViewer = navigationService().isViewer(scope.model.board.assetId);
                        boardService.canEdit(scope.board.id).then(function (canEdit) {
                            scope.editable = canEdit;
                        });
                        navigationService().isBoardAdmin(scope.board.id).then(function (isAdmin) {
                            scope.hasBoardDesignerPrivileges = isAdmin;
                        });
                        // Set categories
                        scope.categories = rowProvider().createCategories(model);
                        scope.selectedCategory = scope.categories[0];
                        // Set users selection
                        if (model.users) {
                            scope.users = model.users
                        } else {
                            boardService.getUserListForBoard(scope.board.id).then(function (data) {
                                scope.users = data;
                            });
                        }
                        checkFavorite();
                        if(!scope.showArchive){ // prevent duplicate tasks for archived tasks
                            scope.createLayoutAndLoad();
                        }
                    });

                    function getAllUsers() {
                        boardService.getUserListForBoard(scope.board.id).then(function (data) {
                            scope.users = data;
                        });
                    }

                    scope.openSettings = function () {
                        var options = {
                            targetType : "Board",
                            targetId : scope.board.id,
                        }
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'boardSettings.html',
                            controller: 'boardSettingsController',
                            resolve: {
                                options: function () {
                                    return options;
                                }
                            }
                        });
                        modalInstance.result.then(function (result) {
                            scope.title = result.name;
                            getAllUsers();
                            scope.refreshUsers = true;
                        });                       
                    }
                    scope.predefinedPostIts = function (show) {
                        scope.showPostIts = show;
                        if (!scope.settings) {
                            var boardOptions = {
                                IsWeeklyBoard: false,
                                viewModeSelection: "year"
                            }
                            scope.settings = {
                                boardOptions : boardOptions
                            };
                        }
                        scope.settings.showPostIts = scope.showPostIts;
                        if (scope.showPostIts) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'postitPredefinedTags.html',
                                controller: 'postitPredefinedTags',
                                resolve: {
                                    labels: function () {
                                        return scope.settings.PostIts;
                                    }
                                }
                            });

                            modalInstance.result.then(function (result) {
                                scope.settings.PostIts = result;
                                boardService.updateSettings(scope.board.id, scope.settings).then(function () {
                                });

                            });
                        } else {
                            boardService.updateSettings(scope.board.id, scope.settings).then(function () {
                            });
                        }
                    }

                    scope.subscribe("BoardSettingUpdated", function (boardsettinginfo) {
                        if (boardsettinginfo.boardId === scope.board.id) {
                            checkBoardSettings(boardsettinginfo.settings);
                        }
                    });
                    scope.createLayoutAndLoad = function () {
                        scope.data.rows = rowProvider().createRows(scope.model, scope.selectedCategory);
                        scope.selectedViewMode.load();
                    }

                    scope.previousYear = function () {
                        selectYear(scope.selectedYear - 1);
                    }
                    scope.nextYear = function () {
                        selectYear(scope.selectedYear + 1);
                    }
                    scope.previousMonth = function (refreshData) {
                        var previousMonth = scope.selectedMonth.id - 1;
                        if (previousMonth == 0) {
                            previousMonth = 12;
                            scope.selectedYear -= 1;
                        }
                        var previousMonthIndex = previousMonth - 1; // subtract index
                        var month = scope.months[previousMonthIndex];
                        month.weeks = timeService.getWeeksInMonth(scope.selectedYear, month.id);
                        scope.selectMonth(month, refreshData);
                    }
                    scope.nextMonth = function (refreshData) {
                        var nextMonth = scope.selectedMonth.id + 1;
                        if (nextMonth == 13) {
                            nextMonth = 1;
                            scope.selectedYear += 1;
                        }
                        var nextMonthIndex = nextMonth - 1; // subtract for index
                        var month = scope.months[nextMonthIndex];
                        month.weeks = timeService.getWeeksInMonth(scope.selectedYear, month.id);
                        scope.selectMonth(month, refreshData);
                    }
                    scope.previousWeek = function () {
                        var currentWeekIndex = scope.selectedMonth.weeks.indexOf(scope.selectedWeek);
                        var currentWeekNumber = scope.selectedWeek.number;
                        var previousWeekIndex = currentWeekIndex - 1;
                        if (currentWeekIndex == 0) { // First week, so we need to go back a month
                            scope.previousMonth(false);
                            previousWeekIndex = scope.selectedMonth.weeks.length - 1;
                        }
                        var previousWeek = scope.selectedMonth.weeks[previousWeekIndex];
                        if (previousWeekIndex == (scope.selectedMonth.weeks.length - 1) && previousWeek.number == currentWeekNumber) {
                            previousWeek = scope.selectedMonth.weeks[previousWeekIndex - 1];
                        }
                        scope.selectWeek(previousWeek, true);
                    }
                    scope.nextWeek = function () {
                        var currentWeekIndex = scope.selectedMonth.weeks.indexOf(scope.selectedWeek);
                        var currentWeekNumber = scope.selectedWeek.number;
                        var nextWeekIndex = currentWeekIndex + 1;
                        if (nextWeekIndex > (scope.selectedMonth.weeks.length - 1)) { // Last week of month, so we need to go forward a month
                            scope.nextMonth(false);
                            nextWeekIndex = 0;
                        }
                        var nextWeek = scope.selectedMonth.weeks[nextWeekIndex];
                        // If week is share between two months, we need to move it to the next
                        if (nextWeekIndex == 0 && nextWeek.number == currentWeekNumber) {
                            nextWeek = scope.selectedMonth.weeks[1];
                        }
                        scope.selectWeek(nextWeek, true);
                    }

                    var selectYear = function (year) {
                        scope.selectedYear = year;
                        // Adjust month
                        var month = scope.months[0];
                        month.weeks = timeService.getWeeksInMonth(scope.selectedYear, month.id);
                        scope.selectedMonth = month;
                        setMonthSelection(month);
                        scope.selectedWeek = month.weeks[0];
                        setWeekSelection(scope.selectedWeek);

                        scope.selectedViewMode.load();
                    }

                    var initTask = function (task) {
                        // Add animation function
                        task.animate = function (animation) {
                            task.animationClass = "animated " + animation;
                            setTimeout(function () {
                                task.animationClass = "";
                                // Make sure animated class is removed
                                $(elem).find(".task[data-id=" + task.id + "]").removeClass("animated");
                            }, 2000);
                        };
                    }

                    scope.createTask = function ($event, row, targetMode, targetPeriodIndex, taskOptions) {
                        if (!scope.editable) return;
                        var targetCell = $(".drop-row[data-rowindex='" + row.index + "'] td[data-cell-id='" + targetPeriodIndex + "']");
                        $(targetCell).addClass("active-cell");

                        if (!taskOptions && scope.postIts) {
                            taskOptions = {
                                postIts: scope.postIts
                            }
                        }
                        var rowId = null;
                        if (!scope.useActionList) {
                            rowId = row.id;
                        }
                        var startTime, endTime;

                        if (targetMode == "month") {
                            // TargetPeriod = month object
                            // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
                            // array is 'year', 'month', 'day', etc
                            startTime = moment([scope.selectedYear, targetPeriodIndex - 1]).toDate();
                            endTime = moment(startTime).endOf('month').toDate();
                        }
                        if (targetMode == "week") {
                            // TargetPeriod = month object
                            var selectedWeek = $filter('filter')(scope.selectedMonth.weeks, { number: targetPeriodIndex })[0];
                            //var selectedWeek = scope.selectedMonth.weeks[targetPeriodIndex -1];
                            startTime = selectedWeek.startDate,
                                endTime = selectedWeek.endDate
                        }
                        if (targetMode == "day") {
                            // TargetPeriod = day object

                            var day = scope.selectedWeek.days[targetPeriodIndex - 1];
                            startTime = day.date,
                                endTime = day.date
                        }

                        var task = {
                            boardId: scope.board.id,
                            id: 0,
                            rowCategoryId: rowId,
                            startTime: startTime,
                            endTime: endTime,
                            title: "",
                            status: 'blank',
                            tags: ""
                        }

                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newYearlyTask.html',
                            controller: 'newYearlyTaskController',
                            resolve: {
                                task: function () {
                                    return task;
                                },
                                options: function () {
                                    return taskOptions;
                                },
                                users: function () {
                                    return scope.users;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            // publish update
                            $(targetCell).removeClass("active-cell");
                        }, function () {
                            $(targetCell).removeClass("active-cell");
                        });

                    }

                    scope.taskDurationChangedHandler = function (task) {
                        var service = renderService();
                        clearAnimations();
                        task.animate("rubberBand");
                        service().calculateDuration(task, getSelectedTimeOptions());
                        service().reCalculateRow(scope.data, task.rowCategoryId, getSelectedTimeOptions());
                        //if(task.subTaskCount >0)
                        //updateSubTaskDueDate(task, 30);

                    };

                    function couldMoveSubTasks(task) {
                        if (task.subTaskCount > 0 && task.subTaskCount > task.subTaskCompletedCount) {
                            return true;
                        }
                        return false;
                    }

                    function updateSubTaskDueDate(task, days) {
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'moveSubTask.html',
                            controller: 'moveSubTaskController',
                            resolve: {
                                parenttask: function () {
                                    return task;
                                },
                                days: function () {
                                    return days;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                        }, function () {
                        });
                    }

                    var renderTasks = function (tasks, renderOptions) {

                        for (let index = 0; index < tasks.length; index++) {
                            const task = tasks[index];
                            renderTask(task, renderOptions);
                        }
                    }

                    var renderTask = function (task, renderOptions) {
                        var actionListTasks = [];
                        if (scope.useActionList) {
                            actionListTasks = scope.smartActionListTasks;
                        }
                        if (!task.startTime) {
                            task.startTime = task.endTime;
                        }
                        initTask(task);
                        task.users = scope.users;
                        if (renderOptions && renderOptions.animationClass) {
                            task.animate(renderOptions.animationClass);
                        }
                        var service = renderService();
                        service().calculateDuration(task, getSelectedTimeOptions());
                        // if using action list, we need to merge cellModels to the task
                        if (scope.useActionList) {
                            // Find 
                            var row = scope.data.rows[0];
                            var actionListTask = $filter('filter')(actionListTasks, { id: task.id });
                            if (actionListTask.length > 0) {
                                // extend task with attributes
                                task.attributes = actionListTask[0].cellModels;
                                row = rowProvider().getRowForTask(scope.model, scope.selectedCategory, scope.data.rows, task);
                            }


                            service().addTaskToRow(task, row, getSelectedTimeOptions());

                        } else {
                            // Find correct category
                            var category = $filter('filter')(scope.data.rows, { id: task.rowCategoryId });
                            if (category.length > 0) {
                                var row = category[0];
                                service().addTaskToRow(task, row, getSelectedTimeOptions());
                            }
                        }
                    }

                    var clearAnimations = function () {
                        for (let index = 0; index < scope.data.tasks.length; index++) {
                            const task = scope.data.tasks[index];
                            task.animationClass = "";
                        }
                    }

                    function loadTasks(renderOptions){
                        if(scope.showArchive){
                            boardService.getArchivedTimeTasksForPeriod(scope.board.id, scope.currentTimePeriod).then(function (tasks) {
                                scope.data.tasks = tasks;
                                renderTasks(scope.data.tasks, renderOptions);
                                for (let index = 0; index < scope.data.rows.length; index++) {
                                    const row = scope.data.rows[index];
                                    row.isRecalculating = false;
                                }
                            });
                        } else {
                            boardService.getTasksForPeriod(scope.board.id, scope.currentTimePeriod).then(function (tasks) {
                                scope.data.tasks = tasks;
                                renderTasks(scope.data.tasks, renderOptions);
                                for (let index = 0; index < scope.data.rows.length; index++) {
                                    const row = scope.data.rows[index];
                                    row.isRecalculating = false;
                                }
                            });
                        }
                        
                    }

                    scope.currentTimePeriod = null;
                    var loadYear = function () {
                        if (scope.board && scope.board.id) {
                            var displayYear = getDisplayPeriods().year;
                            var year = scope.selectedYear;
                            var renderOptions = {
                                animationClass: "slideInLeft"
                            }
                            if (year && year > displayYear) {
                                renderOptions.animationClass = "slideInRight";
                            }
                            scope.data.tasks = [];
                            for (let index = 0; index < scope.data.rows.length; index++) {
                                const row = scope.data.rows[index];
                                row.taskRows = [];
                                row.isRecalculating = true;
                            }

                            var startTime = moment([year, 1]).toDate();
                            startTime = moment(startTime).startOf('year').toDate();
                            var endTime = moment(startTime).endOf('year').toDate();

                            var timePeriod = {
                                from: startTime,
                                to: endTime
                            };
                            scope.currentTimePeriod = timePeriod;
                            loadTasks(renderOptions);
                        }

                    }

                    var loadMonth = function () {
                        if (scope.board && scope.board.id) {
                            var displayMonth = getDisplayPeriods().month;
                            var month = scope.selectedMonth;
                            var startTime = month.weeks[0].startDate;
                            var endTime = month.weeks[month.weeks.length - 1].endDate;
                            var timePeriod = {
                                from: startTime,
                                to: endTime
                            };

                            var renderOptions = {
                                animationClass: "slideInLeft"
                            }
                            if (month && month > displayMonth) {
                                renderOptions.animationClass = "slideInRight";
                            }
                            scope.data.tasks = [];
                            for (let index = 0; index < scope.data.rows.length; index++) {
                                const row = scope.data.rows[index];
                                row.taskRows = [];
                                row.isRecalculating = true;
                            }

                            scope.currentTimePeriod = timePeriod;
                            loadTasks(renderOptions);
                        }
                    }
                    var loadWeek = function () {
                        if (scope.board && scope.board.id) {
                            var displaWeek = getDisplayPeriods().week;
                            var week = scope.selectedWeek;
                            var renderOptions = {
                                animationClass: "slideInLeft"
                            }
                            if (week && week > displaWeek) {
                                renderOptions.animationClass = "slideInRight";
                            }
                            scope.data.tasks = [];
                            for (let index = 0; index < scope.data.rows.length; index++) {
                                const row = scope.data.rows[index];
                                row.taskRows = [];
                                row.isRecalculating = true;
                            }

                            var timePeriod = {
                                from: scope.selectedWeek.startDate,
                                to: scope.selectedWeek.endDate
                            };
                            scope.currentTimePeriod = timePeriod;
                            loadTasks(renderOptions);
                        }
                    }
                    var getTask = function (taskId) {
                        var task = $filter('filter')(scope.data.tasks, { id: taskId });
                        if (task.length === 1) return task[0];
                        return null;
                    }
                    var getRowById = function (rowId) {
                        return $filter('filter')(scope.data.rows, { id: rowId })[0];
                    }

                    var getRowByIndex = function (index) {
                        return $filter('filter')(scope.data.rows, { index: index })[0];
                    }
                    //::::::::::::::::::::::
                    // DRAG AND DROP 
                    //::::::::::::::::::::::

                    dragulaService.options(scope, 'task-container-bag', {
                        revertOnSpill: true,
                        removeOnSpill: true,
                        copy: true,
                        accepts: function (el, target, source, sibling) {
                            var canAccept = $(target).hasClass("drop-row-cell");
                            // console.log(canAccept);
                            return canAccept;
                        },
                        moves: function (el, target, source, sibling) {
                            return scope.editable;
                        },

                    });
                    scope.$on('task-container-bag.drag', function (container, source, el) {
                        $(".drop-row").css('z-index', 10);
                        $(".gu-mirror").css('width', 1000);
                        el.removeClass('animated');
                    });
                    scope.$on('task-container-bag.drop', function (container, source, el) {
                            var targetOptions = {
                                targetYear: parseInt(el.attr("data-year")),
                                targetMonth: parseInt(el.attr("data-month")),
                                targetYearEnd: parseInt(el.attr("data-year-end")),
                                targetWeek: parseInt(el.attr("data-week-index")),
                                targetDay: parseInt(el.attr("data-day"))
                            }
                            var dropRowElement = $(source).closest(".drop-row");
                            var rowIndex = parseInt(dropRowElement.attr("data-rowindex"));
                            var targetRow = getRowByIndex(rowIndex);
                            var targetCellId = parseInt(el.attr("data-cell-id"))

                            // Check if post it
                            var postItElement = $(source).hasClass("post-it");
                            if (postItElement) {
                                var taskOptions = {
                                    color: $(source).attr("data-color"),
                                    postIts: scope.postIts
                                }
                                // If we use smartactionlist we need to apply row attributes
                                if (scope.useActionList) {
                                    var attribute = targetRow.getAttribute();
                                    taskOptions.attribute = attribute;
                                }

                                var targetMode = "month";
                                if (scope.selectedViewMode.viewMode == "month") targetMode = "week";
                                if (scope.selectedViewMode.viewMode == "week") targetMode = "day";
                                // Create Task
                                scope.createTask(null, targetRow, targetMode, targetCellId, taskOptions);
                                // Reset drop
                                $(".drop-row").css('z-index', 1);
                                $(".drop-row .post-it").remove();
                                return;
                            }

                            // Find task being dropped
                            var taskElement = $(source).find(".task");
                            var taskId = parseInt(taskElement.attr("data-id"));
                            var task = getTask(taskId);
                            if (!task) return;
                            var origionalEndTime = task.endTime;
                            var origionalBoardDate = task.boardDate;
                            var previousRowIndex = task.rowIndex;
                            targetRow.isRecalculating = true;
                            var targetCategoryId = parseInt(el.attr("data-category-id"));
                            task.animate("bounce");
                            // Gonna use a timeout for smoother redrawing
                            setTimeout(function () {
                                // Render layout again to adapt drop
                                var previousRowId = task.rowCategoryId;
                                task.rowCategoryId = targetCategoryId;
                                task.rowIndex = rowIndex;
                                // move task in structure
                                var service = renderService();
                                if (scope.useActionList) {
                                    // update cell according to row(smartcolumn)
                                    targetRow.updateTask(scope.selectedCategory, task);
                                }
                                service().moveTask(task, targetOptions, getSelectedTimeOptions());
                                service().reCalculateRowByIndex(scope.data, previousRowIndex, getSelectedTimeOptions());
                                service().reCalculateRowByIndex(scope.data, rowIndex, getSelectedTimeOptions());

                                scope.commitDurationChanged(task);
                                if (couldMoveSubTasks(task)) {
                                    var changedDuration = timeService.getDurationInDays(origionalEndTime, task.endTime);
                                    if (changedDuration != 0) {
                                        updateSubTaskDueDate(task, changedDuration);
                                    }
                                }
                                if (!scope.useActionList && previousRowId != targetCategoryId) {
                                    scope.commitRowPositionUpdatedDebounced(task.id, previousRowId, targetCategoryId);
                                }
                                // Clean up drop row
                                $(".drop-row").css('z-index', 1);
                                $(".drop-row .task-container").remove();
                                targetRow.isRecalculating = false;
                            }, 600);
                        
                    });


                    scope.$on('task-container-bag.over', function (container, source, el) {
                        // highlight drop cell
                        el.addClass('drag-over');
                        var taskElement = $(source.context).find(".task");
                        var taskId = parseInt(taskElement.attr("data-id"));
                        var task = getTask(taskId);
                        if (!task) return;
                        var targetMonthNumber = parseInt(el.attr("data-month"));
                        var targetWeekNumber = parseInt(el.attr("data-week-index"));
                        var targetDayNumber = parseInt(el.attr("data-day"));

                        if (scope.selectedViewMode.viewMode == "year") {
                            for (let index = targetMonthNumber; index < (targetMonthNumber + task.durationInMonthsCurrentYear); index++) {
                                $(el).siblings("[data-month='" + index + "']").addClass('drag-over');
                            }
                        }
                        if (scope.selectedViewMode.viewMode == "month") {
                            for (let index = targetWeekNumber; index < (targetWeekNumber + task.durationInWeeksCurrentMonth); index++) {
                                $(el).siblings("[data-week-index='" + index + "']").addClass('drag-over');
                            }
                        }
                        if (scope.selectedViewMode.viewMode == "week") {
                            for (let index = targetDayNumber; index < (targetDayNumber + task.durationInDaysCurrentWeek); index++) {
                                $(el).siblings("[data-day='" + index + "']").addClass('drag-over');
                            }
                        }

                    })
                    scope.$on('task-container-bag.out', function (container, source, el) {
                        // remove highlight of target cell
                        el.removeClass('drag-over');
                        el.siblings().removeClass('drag-over');
                    });


                    scope.updateRowPosition = function (taskId, fromRowId, toRowId) {
                        var position = {
                            taskId: taskId,
                            fromRowId: fromRowId,
                            toRowId: toRowId
                        }
                        boardTaskService().updateRowPosition(position).then(function (updateTask) {

                        });
                    }
                    scope.saveDuration = function (task) {
                        var duration = {
                            startTime: task.startTime,
                            endTime: task.endTime
                        }
                        boardTaskService().updateDuration(task.id, duration).then(function (updateTask) {

                        });
                    }

                    // Debounce function so that save is called to often.
                    scope.commitRowPositionUpdatedDebounced = debounce(scope.updateRowPosition, 1000, false);
                    scope.commitDurationChanged = debounce(scope.saveDuration, 1000, false);

                    scope.saveAsTemplate = function () {
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newboardtemplate.html',
                            controller: 'newboardtemplateController',
                            resolve: {
                                board: function () {
                                    return scope.model.board;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {

                        });
                    }
                    scope.deleteBoard = function () {
                        if (!scope.hasBoardDesignerPrivileges) return;
                        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_DELETED_BOARD', 'ADMIN_DELETED_BOARD_DESCRIPTION']).then(function (translations) {
                            var modalOptions = {
                                closeButtonText: translations.COMMON_CANCEL,
                                actionButtonText: translations.ADMIN_DELETED_BOARD,
                                headerText: translations.COMMON_DELETE + ' ' + scope.title + '?',
                                bodyText: translations.ADMIN_DELETED_BOARD_DESCRIPTION
                            };

                            modalService.showModal({}, modalOptions).then(function (result) {
                                boardService.deleteBoard(scope.model.board.id).then(function () {
                                    $state.go('myspace');
                                    scope.publish("SendBoardDeleted", scope.model.board.id);
                                });
                            });

                        });
                    }

                    scope.addToFavorites = function () {
                        scope.isStar = true;
                        var board = {
                            id: scope.model.board.id,
                            name: scope.model.board.name,
                            boardType: scope.model.board.boardType
                        };
                        scope.quickAccess.push(board);
                        saveQuickAccess();
                    }
                    scope.removeFromFavorites = function () {
                        scope.isStar = false;
                        var isFavorite = $filter('filter')(scope.quickAccess, { id: scope.model.board.id })
                        if (isFavorite.length > 0) {
                            var currentIndex = scope.quickAccess.indexOf(isFavorite[0]);
                            scope.quickAccess.splice(currentIndex, 1);
                            saveQuickAccess();
                        }
                    }
                    function saveQuickAccess() {
                        employeeService.updateUserPreference("FlexiboardFavoriteBoards", scope.quickAccess).then(function (settings) {
                            scope.publish("FlexiboardFavoriteBoardsChanged", scope.quickAccess);
                        });
                    }

                    function checkFavorite(){
                        employeeService.getUserPreference("FlexiboardFavoriteBoards").then(function (settings) {
                            if (settings && settings.value) {
                                angular.forEach(settings.value, function (board) {
                                    if (board) {
                                        if(board.id == scope.model.board.id){
                                            scope.isStar = true;
                                        }
                                        scope.quickAccess.push(board);
                                    }
                                });
                            }
                        });
                    }
                }
            }
        }]);

﻿import Dropzone from "dropzone";
import { getAuthUser } from "@common/stores/userStore"
import envConfig from "@common/envConfig";

angular
    .module('DigiLean')
    .directive("dropzoneLocal",
        function () {
            return {
                restrict: 'A',
                scope: {
                    'uploadedfile': '=',
                    'fileParsedHandler': '&',
                    'acceptedFiles': '@',
                    'uploadPath': '@',
                },
                link: function (scope, element, attrs) {
                    const authUser = getAuthUser()
                    angular.element(document).ready(function () {

                        var uploadUrl = envConfig.webApiUrl;
                        
                        Dropzone.autoDiscover = false;
                        if (scope.uploadPath) {
                            uploadUrl = envConfig.webApiUrl + scope.uploadPath;
                        }
                        var config = {
                            url: uploadUrl,
                            maxFilesize: 100,
                            paramName: "uploadfile",
                            maxThumbnailFilesize: 8,
                            uploadMultiple: false,
                            autoProcessQueue: true,
                            withCredentials: true,
                            maxFiles: 10,
                            acceptedFiles: scope.acceptedFiles,
                            headers: {
                                'Authorization': 'Bearer ' + authUser.token
                            }
                        };

                        var eventHandlers = {

                            'success': function (file, response) {
                                // response can have more than one filinfo, so we need to match the file of the event

                                scope.fileParsedHandler({ data: response });

                                setTimeout(function () {
                                    dropzone.removeFile(file);
                                }, 1000);


                            },
                            // 'complete': function (file, response) {
                            //     if (file.status =="error") {

                            //         var errorResponse = file.xhr.response;
                            //         var errorMessage = errorResponse.exceptionMessage;
                            //         console.log('error: ' + errorMessage);
                            //     }
                            //}
                            //,
                            'addedfile': function (file, response) {
                                console.log('added file');
                            },
                            
                            'drop': function (file, response) {
                                console.log('dropped file');
                            }
                        };

                        var dropzone = new Dropzone(element[0], config);

                        // add paste event listener to the page
                        document.onpaste = function (event) {
                            var items = (event.clipboardData || event.originalEvent.clipboardData).items;
                            for (var index in items) {
                                var item = items[index];
                                if (item.kind === 'file') {
                                    // adds the file to your dropzone instance
                                    dropzone.addFile(item.getAsFile())
                                }
                            }
                        }

                        angular.forEach(eventHandlers, function (handler, event) {
                            dropzone.on(event, handler);
                        });

                    });

                    scope.processDropzone = function () {
                        dropzone.processQueue();
                    };

                    scope.resetDropzone = function () {
                        dropzone.removeAllFiles();
                    }
                }
            }
        });

import type { AspNetErrorResponse } from "@common/model/aspNetErrorResponse"

export function handleErrorsAspNet(res: AspNetErrorResponse) {
    const allErrorMsgs: string[] = []
    if (res.errors) {
        for (let key in res.errors) {
            let errors = res.errors[key]
            if (errors && Array.isArray(errors))
                allErrorMsgs.push(...errors)
        }
    }
    if (res.title)
        allErrorMsgs.push(res.title)
    return allErrorMsgs
}

export function handleThrownErrorMsg(error: Error) {
    let msgs: string[] = []
    if (error.message) {
        if (error.message.includes(","))
            msgs = error.message.split(",")
        else
            msgs.push(error.message)
    }
    else if (error.name) {
        msgs.push(error.name)
    }
    return msgs
}
import envConfig from "@common/envConfig";


angular.module("DigiLean").factory('smarttableService', ['$http', '$translate',
    function ($http, $translate) {
        function smarttableService() {
            var resource = "/smarttable";
            var resourceUrl = envConfig.webApiUrl + resource;

            return {
                getIcons: function () {
                    return [
                        {
                            id:0,
                            icon: "fa-long-arrow-alt-up",
                            title: $translate.instant('COMMON_UP'), // green
                            color: "#8CC152",
                        },
                        {
                            id:1,
                            icon: "fa-long-arrow-alt-right",
                            title: $translate.instant('COMMON_FLAT'), // orange           
                            color: "#fcbb42",
                        },
                        {
                            id:2,
                            icon: "fa-long-arrow-alt-down",
                            title: $translate.instant('COMMON_DOWN'), // red
                            color: "#E9573F",
                        }
                    ];
                },
                createSmartTable: function(smartTableTemplate) {
                    var url = resourceUrl + "/createSmartTable/";
                    return $http.post(url, smartTableTemplate).then(function (result) {
                        return result.data;
                    });
                },
                get: function (id) {
                    var url = resourceUrl + "/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
             
                addRow: function (row) {
                    var url = resourceUrl + "/addRow/";
                    return $http.post(url, row).then(function (result) {
                        return result.data;
                    });
                },
                deleteRow: function(id) {
                    var url = resourceUrl + "/deleteRow/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                addColumn: function (column) {
                    var url = resourceUrl + "/addColumn/";
                    return $http.post(url, column).then(function (result) {
                        return result.data;
                    });
                },
 
                deleteColumn: function(id) {
                    var url = resourceUrl + "/deleteColumn/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                updateColumnSortOrder: function (boardId, sortOrder) {
                    var url = resourceUrl + "/UpdateColumnSortOrder/" + boardId ;
                    return $http.put(url, sortOrder).then(function (result) {
                        return result.data;
                    });
                },
                updateRowSortOrder: function (boardId, sortOrder) {
                    var url = resourceUrl + "/UpdateRowSortOrder/" + boardId ;
                    return $http.put(url, sortOrder).then(function (result) {
                        return result.data;
                    });
                },
                updateCell: function (cell) {
                    var url = resourceUrl + "/updateCell/";
                    return $http.put(url, cell).then(function (result) {
                        return result.data;
                    });
                },
                updateName: function (id, name) {
                    var row =  {
                        id: id,
                        name: name
                    }
                    var url = resourceUrl + "/updateRowName/";
                    return $http.put(url,row).then(function (result) {
                        return result.data;
                    });
                },
                updateStartTime: function (id, startTime) {
                    var row =  {
                        id: id,
                        startTime: startTime
                    }
                    var url = resourceUrl + "/updateRowStartTime/";
                    return $http.put(url,row).then(function (result) {
                        return result.data;
                    });
                },
                updateEndTime: function (id, endTime) {
                    var row =  {
                        id: id,
                        endTime: endTime
                    }
                    var url = resourceUrl + "/updateRowEndTime/";
                    return $http.put(url,row).then(function (result) {
                        return result.data;
                    });
                },
                updateColumn: function (col) {
                    var updated = {
                        id: col.id,
                        name: col.name,
                        boardId: col.boardId
                    }
                    if (col.settings) {  
                        updated.settings = JSON.stringify(col.settings);
                    }
                    var url = resourceUrl + "/updateColumn/";
                    return $http.put(url, updated).then(function (result) {
                        return result.data;
                    });
                },
                updateOwner: function (rowId, user) {
                    var row =  {
                        id: rowId,
                        owner: user.fullName,
                        ownerUserId : user.userId,
                        ownerDisplayName: user.displayName
                    }
                    var url = resourceUrl + "/updateOwner/";
                    return $http.put(url,row).then(function (result) {
                        return result.data;
                    });
                },
                getPostIts: function () {
                    return [
                        {
                            title: "default",
                            fillColor: "#ffffff",
                            background: "#E6E9ED"
                        },
                        {
                            title: "four", // orange
                            // fillColor: "#FFCE54",
                            fillColor: "#FFF6DF",
                            background: "#FCBB42"
                        },
                        {
                            title: "three", // green
                            // fillColor: "#A0D468",
                            fillColor: "#EDF7E3",
                            background: "#8CC152"
                        },
                        {
                            title: "two", //blue
                            // fillColor: "#4FC1E9",
                            fillColor: "#DFF3FB",
                            background: "#3BAFDA"
                        },
                        {
                            title: "one", // red
                            // fillColor: "#FC6E51",
                            fillColor: "#FEE4DF",
                            background: "#E9573F"
                        },
                        {
                            title: "five", // pink
                            // fillColor: "#EC87C0",
                            fillColor: "#FBE9F3", 
                            background: "#D770AD"
                        }

                    ];
                },
            };
        }
        return smarttableService;
    }]);
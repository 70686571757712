﻿import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('movetaskController', ['$scope','$uibModalInstance', 'boardTaskService', 'task',
    function ($scope, $uibModalInstance, boardTaskService, task) {
        $scope.selectedBoard = null;
        $scope.task = task;
        $scope.selectedColumn = null;

        $scope.includepersonalboard = true;
        
        

        boardTaskService().get($scope.task.id).then(function (original) {
            if(original.boardDate){
                $scope.selectedBoardDate = moment(original.boardDate).toDate();
            } else {
                $scope.selectedBoardDate = moment().toDate();
            }
            
            $scope.dayOfWeek = moment(original.boardDate).format('dddd');
            mapDateToColumn();
            mapDateToRow();
        });


        $scope.columns = [];
        $scope.rows = [];

        $scope.hasRows = false
        $scope.hasColumns = false

        $scope.selectedDateChanged = function ($event) {
            const date = $event.originalEvent.detail
            $scope.selectedBoardDate = date
            $scope.dayOfWeek = moment($scope.selectedBoardDate).format('d')
            mapDateToColumn()
            mapDateToRow()
        }

        var mapDateToRow = function () {
            if ($scope.rows.length == 0) return;
            if (!$scope.selectedBoardDate) return;

            // Try to select correct row
            angular.forEach($scope.rows, function (row) {
                if (row.weekdayName && (row.weekdayName ==  timeService.getWeekDayNameFromDate($scope.selectedBoardDate))) {
                    $scope.selectedRow = row;
                }
            });
        }

        var mapDateToColumn = function () {
            if ($scope.columns.length == 0) return;
            if (!$scope.selectedBoardDate) return;

            // Try to select correct row
            angular.forEach($scope.columns, function (column) {
                if (column.weekdayName && (column.weekdayName ==  timeService.getWeekDayNameFromDate($scope.selectedBoardDate))) {
                    $scope.selectedColumn = column;
                }
            });
        }
        var mapResponsibleToColumn = function (task) {
            if (!$scope.columns || $scope.columns.length == 0) return;
            if (!task.responsibleUserId) return;

            // Try to select correct row
            angular.forEach($scope.columns, function (column) {
                if (column.responsibleUserId == task.responsibleUserId) {
                    $scope.selectedColumn = column;
                }
            });
        }
        var mapResponsibleToRow = function (task) {
            if (!$scope.rows || $scope.rows.length == 0) return;
            if (!task.responsibleUserId) return;

            // Try to select correct row
            angular.forEach($scope.rows, function (row) {
                if (row.responsibleUserId == task.responsibleUserId) {
                    $scope.selectedRow = row;
                }
            });
        }
        $scope.changeBoard = function (board) {
            $scope.selectedBoard = board;
            $scope.hasRows = false;
            $scope.hasColumns = false;

            if (board.boardType == "SmartActionList" || 
                board.boardType =='PERSONAL' ||
                board.boardType == '3C') {
                return;
            } 
            $scope.showDate = true;
            boardService.getBoardCategories(board.id).then(function (data) {

                angular.forEach(data.columns, function (column) {
                    if (column.responsibleUserId) {
                        column.displayName = column.name + " (" + column.responsibleDisplayName + ")";
                    } else {
                        column.displayName = column.name;
                    }

                });
                angular.forEach(data.rows, function (row) {
                    if (row.responsibleUserId) {
                        row.displayName = row.name + " (" + row.responsibleDisplayName + ")";
                    } else {
                        row.displayName = row.name;
                    }
                });
                if (data.columns.length > 0) {
                    $scope.columns = data.columns;
                    $scope.selectedColumn = data.columns[0];
                    $scope.hasColumns = true;
                }
                if (data.rows.length > 0) {
                    $scope.rows = data.rows;
                    $scope.selectedRow = data.rows[0];
                    $scope.hasRows = true;
                }
                mapDateToColumn();
                mapDateToRow();
                mapResponsibleToColumn($scope.task);
                mapResponsibleToRow($scope.task);
            });
        }
        $scope.ok = function () {
            if($scope.selectedBoard){        
                if ($scope.selectedBoard.id) {
                    moveTaskToRowColumn($scope.task);
                } else {
                    var change = {
                        taskId: $scope.task.id,
                        fromBoardId: $scope.task.boardId === null ? 0 : $scope.task.boardId,
                        toBoardId: $scope.selectedBoard.id === null ? 0 : $scope.selectedBoard.id
                    };
                    boardTaskService().moveTaskToPersonalBoard(task.id).then(function (result) {
                        $scope.publish("SendTaskChangedBoard", change);
                        $uibModalInstance.close(result);
                    });
                }
            } else {
                $scope.cancel();
            }
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function moveTaskToRowColumn (task) {
            //task.isActionItem = false;
            var rowId = 0;
            var columnId = 0;
            if ($scope.selectedRow && $scope.selectedRow.id) {
                rowId = $scope.selectedRow.id;
            }
            if ($scope.selectedColumn && $scope.selectedColumn.id) {
                columnId = $scope.selectedColumn.id;
            }

            var change = {
                id: task.id,
                boardId: $scope.selectedBoard.id,
                boardDate: $scope.selectedBoardDate,//task.boardDate,
                rowCategoryId: rowId,
                columnCategoryId: columnId
            };

            if(task.cause || task.counterMeasure)
            {
                change.causeId = task.causeCellId;
                change.causeValue = task.cause;
                change.counterMeasureId = task.counterMeasureCellId;        
                change.counterMeasureValue = task.counterMeasure;        
            }

            boardTaskService().moveActionItemToTask(change).then(function (task) {
                $scope.publish("SendActionItemMovedToTask", task);
                $uibModalInstance.close(task);
            });
        }

        $scope.selectedDateChanged = function () {
            $scope.dayOfWeek = moment($scope.selectedBoardDate).format('d');
            mapDateToColumn();
            mapDateToRow();
        }
    }]);
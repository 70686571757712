﻿import envConfig from "@common/envConfig";

angular.module('DigiLean').factory('actionlistService', ['$rootScope', '$http', '$translate',
    function ($rootScope, $http, $translate) {

        function actionlistService(){
            var resource = "/actionlist";
            var resourceUrl = envConfig.webApiUrl + resource;
            return {
                getActionTasks: function(actionTaskQuery) {
                    var url = resourceUrl + "/getActionTasks";
                    return $http.post(url, actionTaskQuery).then(function (result) {
                        return result.data;
                    });
                },
                getTaskDateStyle: function (task) {
                    if (!task.boardDate) return "";
                    // Overdue check
                    
                    var overdue = "";
                    var duration = moment.duration(moment().diff(moment(task.boardDate)));
                    var days = duration.asDays();
                    if (days > 1 && task.status.toUpperCase() !== "OK") {
                        overdue = "overdue";
                    }
                    return overdue;
                },


                createDevationActionItem: function (deviationId, action) {
                    var url = resourceUrl + "/CreateDeviationActionItem/" + deviationId;
                    return $http.post(url, action).then(function (result) {
                        return result.data;
                    });
                },

                createImprovementActionItem: function (suggestionId, action) {
                    var url = resourceUrl + "/CreateImprovementActionItem/" + suggestionId;
                    return $http.post(url, action).then(function (result) {
                        return result.data;
                    });
                },

                getActionListForDeviation: function (deviationId) {
                    var url = resourceUrl + "/getActionListForDeviation/" + deviationId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getActionListForImprovementSuggestion: function (suggestionId) {
                    var url = resourceUrl + "/GetActionListForImprovementSuggestion/" + suggestionId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                deleteActionItem: function (id) {
                    var url = resourceUrl + "/DeleteActionItem/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                updateItemDescription: function (itemId, taskDescription) {
                    var url = resourceUrl + "/updateItemDescription/" + itemId;
                    return $http.put(url, taskDescription).then(function (result) {
                        return result.data;
                    });
                },

                updateItemActionDate: function (actionDate) {
                    var url = resourceUrl + "/UpdateActionDate/" + actionDate.taskId;
                    return $http.put(url, actionDate).then(function (result) {
                        return result.data;
                    });
                },

                assignActionListItemUser: function (itemId, user) {
                    var url = resourceUrl + "/assignActionListItemUser/" + itemId;
                    return $http.put(url, user).then(function (result) {
                        return result.data;
                    });
                },

                updateStatus: function (status) {
                    var url = resourceUrl + "/updateStatus/" + status.taskId;
                    return $http.put(url, status).then(function (result) {
                        return result.data;
                    });
                }
            }
        }
        return actionlistService;
    }]);
import envConfig from "@common/envConfig";


angular.module("DigiLean").factory('importExportService', ['$rootScope', '$filter', '$http',
    function ($rootScope, $filter, $http) {
        function dataService() {
            var resource = "/importexport";
            var resourceUrl = envConfig.webApiUrl + resource;

            return {
                parse: function (file) {
                    // var url = resourceUrl + "/parse";
                    // return $http.post(url, value).then(function (result) {
                    //     return result.data;
                    // });

                    var fd = new FormData();
                    fd.append('file', file);
                    fd.append('title', file.name);
                    return $http({
                        url: resourceUrl + "/parse",
                        method: 'POST',
                        data: fd,
                        headers: {'Content-Type': undefined },
                        transformRequest: angular.identity,
                     }).then(function(result){
                         return result.data;
                     });

                },

            }
        }
        return dataService;
    }]);
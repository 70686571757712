import moment from "moment"
import * as boardService from "@common/services/boardService"
import * as timeService from "@common/services/timeService"
import { hasDefaultAccess } from "@common/components/dashboard/designer/common/userAccessService"

angular.module("DigiLean")
    .directive('threeCTable', ['NgTableParams', '$filter', '$translate', '$uibModal', 'threeCService', 'boardTaskService',
        function (NgTableParams, $filter, $translate, $uibModal, threeCService, boardTaskService) {
            return {
                templateUrl: "threeCTable.html",
                restrict: "E",
                scope: {
                    "options": "<",
                    'isAdminMode': '<',
                    'userAccess': '<?',
                },
                link: function (scope, elem, attrs) {
                    scope.columns = [
                        {
                            name: $translate.instant("DASHBOARD_3C_RAISED"),
                            subHeader: $translate.instant("COMMON_DATE"),
                            cssClass: "date-raised",
                            field: "createdDate",
                            sort: "desc"
                        },
                        {
                            name: $translate.instant("DASHBOARD_3C_CONCERN"),
                            subHeader: $translate.instant("DASHBOARD_3C_CONCERN_SUBHEADER"),
                            cssClass: "concern",
                            field: "Text", // Needs to be task.Property at the moment.
                            sort: "none"
                        },
                        {
                            name: $translate.instant("DASHBOARD_3C_CAUSE"),
                            subHeader: $translate.instant("DASHBOARD_3C_CAUSE_SUBHEADER"),
                            cssClass: "cause",
                            field: "cause",
                            sort: "none"
                        },
                        {
                            name: $translate.instant("DASHBOARD_3C_COUNTERMEASURE"),
                            subHeader: $translate.instant("DASHBOARD_3C_COUNTERMEASURE_SUBHEADER"),
                            cssClass: "countermeasure",
                            field: "counterMeasure",
                            sort: "none"
                        },
                        {
                            name: $translate.instant("COMMON_OWNER"),
                            cssClass: "owner",
                            field: "responsibleUser",
                            sort: "none"
                        },
                        {
                            name: $translate.instant("DASHBOARD_3C_DUEDATE"),
                            subHeader: $translate.instant("COMMON_DATE"),
                            cssClass: "due-date",
                            field: "dueDate",
                            sort: "none"
                        },
                        {
                            name: $translate.instant("DASHBOARD_3C_PDCA"),
                            cssClass: "pdca",
                            field: "pdca",
                            sort: "none"
                        }
                    ];
                    scope.items = [];
                    scope.boardId = null;

                    scope.canEdit = true;

                    scope.$watch('userAccess', function (userAccess) {
                        scope.canEdit = hasDefaultAccess(userAccess);
                    });

                    scope.subscribe("ThreeCLogAssetUpdated", (board) => {
                        if(scope.boardId == board.id){
                            threeCService().getLogInfo(scope.boardId).then(function (info) {
                                scope.boardInfo = info;
                            });
                            getAllUsers(scope.boardId);
                        }
                     })

                    // Filter handling
                    scope.$watch('options', function (options) {
                        if (options) {
                            if (options.boardId) {
                                scope.boardId = options.boardId;
                                scope.isStandAlone = options.isStandAlone
                                // Load board
                                threeCService().getLogInfo(scope.boardId).then(function (info) {
                                    scope.boardInfo = info;
                                });
                                getAllUsers(scope.boardId);
                            }
                        }
                    });

                    // load users
                    function getAllUsers() {
                        boardService.getUserListForBoard(scope.boardId).then(function (data) {
                            scope.users = data;
                            scope.getValues();
                        });
                    }

                    scope.updateName = function () {
                        var info = {
                            id: scope.boardId,
                            name: scope.boardInfo.name
                        }
                        boardService.updateName(scope.boardId, info).then(function () {
                            //
                        })
                    }

                    scope.getValues = function () {
                        // scope.buildColumns();
                        scope.tableParams = new NgTableParams({
                            sorting: { Id: "desc" },
                            count: 10,
                        }, {
                            getData: scope.getTableValues
                        });

                    }



                    scope.sorting = "";
                    scope.getTableValues = function (params) {

                        var sorting = [];
                        // Build sortExpression for database to be able to deserialize, becuase NgTableParams.sorting is a dynamic object.
                        for (var propt in params._params.sorting) {
                            sorting.push({
                                property: propt,
                                direction: params._params.sorting[propt]
                            });
                        }
                        scope.sorting = sorting;
                        // We will build params based on the built-in NgTableParams + our sorting array
                        var dbParams = {
                            page: params._params.page,
                            count: params._params.count,
                            sorting: scope.sorting,
                            filters: scope.filter
                        }
                        return threeCService().getItems(scope.boardId, dbParams).then(function (data) {
                            scope.totalMatchingRecords = data.total;
                            params.total(data.total); // recal. page nav controls
                            // Map values
                            data.values.forEach(value => {
                                initializeItem(value)
                            });
                            scope.items = data.values;
                            return data.values;

                        });
                    }

                    var initializeItem = function (item) {
                        if (item.createdDate) {
                            item.createdDate = moment(item.createdDate).toDate();
                        }
                        if (item.dueDate) {
                            item.dueDate = moment(item.dueDate).toDate();
                            if(item.pdca != "100"){
                                item.dateStyleClass = timeService.getDateStyle(item.dueDate);
                            }     
                        }
                        item.owner = {
                            userId: item.responsibleUserId
                        };
                        item.concernChanged = function (concern) {
                            var concernValue = {
                                boardTaskId: item.id,
                                concern: concern
                            }
                            threeCService().updateConcern(item.id, concernValue).then(function (data) { })
                        };
                        item.causeChanged = function (content) {
                            var cell = {
                                id: item.causeCellId,
                                value: content,
                                smartTableColumnId: scope.boardInfo.causeId,
                                boardTaskId: item.id
                            };
                        
                            console.log("Initial cell:", cell);
                            console.log("scope.items:", scope.items);
                        
                            for (let x of scope.items) {
                                console.log("Checking item:", x);
                                if (x.id == item.id) {
                                    cell.id = x.causeCellId;
                                    break;
                                }
                            }
                        
                            console.log("Updated cell:", cell);
                        
                            threeCService().updateCell(cell).then(function (data) {
                                // Handle response if needed
                            });
                        };
                        item.counterMeasureChanged = function (content) {
                            var cell = {
                                id: item.counterMeasureCellId,
                                value: content,
                                smartTableColumnId: scope.boardInfo.counterMeasureId,
                                boardTaskId: item.id
                            }
                            threeCService().updateCell(cell).then(function (data) { })
                        };
                        item.ownerChanged = function (owner) {
                            this.owner = owner;
                            var taskAssigned =
                            {
                                taskId: item.id,
                                userId: owner.id,
                                userName: owner.fullName,
                                userDisplayName: owner.displayName,
                            };
                            var options = {
                                taskId: item.id,
                                user: taskAssigned
                            };
                            if (owner) {
                                boardTaskService().assignUser(options).then(function (data) { });
                            }
                            // Sync status when owner changed or cleared
                            if (owner.userId != "") {
                                if (this.pdca == null || this.pdca == "0") {
                                    this.statusChanged("25");
                                }
                            }
                            else {
                                if (this.pdca == "25") {
                                    this.statusChanged("0");
                                }
                            }
                        };
                        item.dueDateChanged = function (date) {
                            this.dueDate = date;
                            var duration = {
                                startTime: date,
                                endTime: date
                            }
                            boardTaskService().updateDuration(item.id, duration).then(function () {
                            });
                        };
                        item.statusChanged = function (status) {
                            this.pdca = status.toString();
                            var itemStatus = {
                                boardTaskId: item.id,
                                status: item.pdca
                            }
                            threeCService().updateStatus(item.id, itemStatus).then(function (data) {
                            });
                        };
                    }

                    scope.addTask = function () {
                        var item = {
                            createdDate: new Date(),
                            dueDate: new Date(),
                            concern: "",
                            cause: "",
                            counterMeasure: "",
                            pdca: "0",
                            responsibleUserId: "",
                            responsibleUser: "",
                        }
                        threeCService().createItem(scope.boardId, item).then(function (task) {
                            
                        })

                    }
                    scope.deleteTask = function (task) {
                        // confirmation, then delete item
                        boardTaskService().deleteTask(task.id).then(function () {
                            
                        });

                    }

                    scope.moveTask = function (task) {
                        $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'movetask.html',
                            controller: 'movetaskController',
                            resolve: {
                                task: function () {
                                    return task;
                                }
                            }
                        });
                    }

                    var animateRow = function (item, cssClass) {
                        var css = "row-change"; // default 
                        if (cssClass) css = cssClass; // if set
                        item.cssClass = css
                        setTimeout(() => {
                            item.cssClass = "";
                        }, 1000);
                    }
                    // Event subscription
                    var isPartOfTasks = function (taskId) {
                        var task = $filter('filter')(scope.items, { id: taskId }, true);
                        if (task.length > 0) {
                            return task[0];
                        }
                    }
                    scope.subscribe("ThreeCItemCreated", function (data) {
                        if (scope.boardId == data.boardId) {
                            var task = data.item;
                            initializeItem(task);
                            scope.items.unshift(task); // Insert first in list
                            animateRow(task, "row-added");
                            // TODO: Should only be activated by user who adds item.
                            setTimeout(function () {
                                // Activate
                                $(elem).find(".three-c-text-cell")[0].focus();
                            }, 200);
                        }
                    });

                    scope.subscribe("SmartActionListCellUpdated", function (cell) {
                        var task = isPartOfTasks(cell.boardTaskId);
                        if (task) {
                            if (cell.smartTableColumnId == scope.boardInfo.causeId) {
                                task.cause = cell.value;
                                animateRow(task);
                            }
                            if (cell.smartTableColumnId == scope.boardInfo.counterMeasureId) {
                                task.counterMeasure = cell.value;
                                animateRow(task);
                            }
                        }
                    });
                    scope.subscribe("ThreeCItemConcernUpdated", function (status) {
                        var task = isPartOfTasks(status.boardTaskId);
                        if (task) {
                            task.concern = status.concern;
                            animateRow(task);
                        }
                    });
                    scope.subscribe("ThreeCItemStatusUpdated", function (status) {
                        var task = isPartOfTasks(status.boardTaskId);
                        if (task) {
                            task.pdca = status.status;
                            if(task.dueDate && task.pdca != "100"){
                                task.dateStyleClass = timeService.getDateStyle(task.dueDate);
                            } else {
                                task.dateStyleClass = "";
                            }
                            animateRow(task);
                        }
                    });
                    scope.subscribe("TaskAssigned", function (cell) {
                        var task = isPartOfTasks(cell.taskId);
                        if (task) {
                            task.responsibleUserId = cell.userId;
                            animateRow(task);
                        }
                    });
                    scope.subscribe("TaskDurationUpdated", function (cell) {
                        var task = isPartOfTasks(cell.taskId);
                        if (task) {
                            task.dueDate = moment(cell.endTime).toDate();
                            if(task.status != "100"){
                                task.dateStyleClass = timeService.getDateStyle(task.dueDate);
                            }
                            animateRow(task);
                        }
                    });
                    scope.subscribe("TaskDeleted", function (id) {
                        removeTaskFromBoard(id);
                    });

                    function removeTaskFromBoard(taskId) {
                        var task = isPartOfTasks(taskId);
                        if (task) {
                            var index = scope.items.indexOf(task);
                            animateRow(task, "row-deleted");
                            setTimeout(() => {
                                scope.items.splice(index, 1);
                            }, 1000);
                        }
                    }

                    scope.subscribe('ActionItemMovedToTask', function (task) {
                        if (task.taskType == "3C" && task.boardId !== scope.boardId) {
                            removeTaskFromBoard(task.id)
                        } else if (task.boardId == scope.boardId) {
                            var isPartOfTasks = scope.items.filter(t => t.id == task.id);
                            if (isPartOfTasks.length == 0) {
                                threeCService().getItem(task.id).then(function (task) {
                                    scope.items.push(task);
                                    animateRow(task, "row-added");
                                })         
                            }
                        }
                    });
                }
            }
        }]);
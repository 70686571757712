import envConfig from "@common/envConfig";

angular
    .module('DigiLean')
    .directive("dashboardCard", ['$filter', 'authService',
        function ($filter, authService) {
            return {
                templateUrl: 'dashboardCard.html',
                restrict: 'E',
                scope: {
                    "board" : "<",
                },
                link: function (scope, elem, attrs) {
                    scope.model = null;
                    scope.isLoading = true;
                    
                    scope.$watch('board', function (board) {
                        if (!board) return;
                        var customerId = board.customerId;
                        if (!customerId || customerId == 0) {
                            customerId = authService.getCurrentUser().customerId;
                        }
                        scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                            customerId = authService.getCurrentUser().customerId;
                        });

                        var fileName = "dashboard_" + customerId + "_" + board.id + ".jpg"; 
                        scope.previewUrl =  envConfig.fileStoreUrl + "/preview-images/" + fileName;
                        scope.isLoading = false;
                    });
                }
            }
        }]);

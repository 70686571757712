﻿import * as customerService from "@common/services/customers/customerService"
import * as profileService from "@common/services/user/profileService"
import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"
import * as translationService from "@common/services/translationService"
import * as profileImageService from "@common/services/user/profileImageService"
import { getColStyle } from "@common/services/settingsService"
import envConfig from "@common/envConfig"

angular
    .module('DigiLean')
    .directive("a3Table", ['$translate', 'a3TableService', '$uibModal', 'navigationService', 'modalService', 'orderByFilter', 'taskDrawingService', 'excelService',
        function ($translate, a3TableService, $uibModal, navigationService, modalService, orderByFilter, taskDrawingService, excelService) {
            return {
                templateUrl: 'a3Table.html',
                restrict: 'E',
                scope: {
                    'model': '<',
                    'isAdminMode': '<',
                    'options': '<?'
                },
                link: function ($scope, elem, attrs) {
                    $scope.editable = false;
                    $scope.a3s = [];
                    $scope.canEdit = false;
                    $scope.showtitle = true;
                    $scope.query = "";

                    $scope.showFileGenerators = true;
                    if (envConfig.isTeamsMode) {
                        $scope.showFileGenerators = false;
                    }

                    // 
                    $scope.sortColumnIndex = null;
                    $scope.isAscedning = true;
                    $scope.sortColumn = null;
                    $scope.orderBy = function (column) {
                        if ($scope.sortColumn && $scope.sortColumn != column) {
                            $scope.sortColumn.sort = "none";
                        }
                        if (!column.sort) column.sort = "none";
                        column.sort = getNextSortOrder(column.sort);
                        $scope.sortColumn = column;
                        $scope.sortColumnIndex = $scope.a3Columns.indexOf(column);
                        if (column.sort == "none") {
                            $scope.a3s = orderByFilter($scope.a3s, 'sortOrder');
                        }
                        if (column.sort == "asc") {
                            $scope.a3s = orderByFilter($scope.a3s, getSortValue, false);
                        }
                        if (column.sort == "desc") {
                            $scope.a3s = orderByFilter($scope.a3s, getSortValue, true);
                        }
                    }

                    var getNextSortOrder = function (currentSortOrder) {
                        if (currentSortOrder == "none") return "asc";
                        if (currentSortOrder == "asc") return "desc";
                        if (currentSortOrder == "desc") return "none";
                    }

                    var getSortValue = function (a3) {
                        if (a3.cellModels) {
                            var cell = a3.cellModels[$scope.sortColumnIndex];
                            return cell.getSortValue();
                        }
                        return a3.name;

                    }
                    // load users
                    async function getAllUsers() {
                        $scope.users = await profileImageService.getAllUsersIncludeProfileImage()
                    }
                    getAllUsers();

                    $scope.$watch('query', function () {
                        $scope.searchQuery = angular.copy($scope.query);
                    });

                    $scope.search = function (query) {
                        //$scope.searchPerformed = false;
                        $scope.searchQuery = angular.copy(query);
                        // $scope.searchResult = true;
                    }

                    $scope.showMoreColumns = function () {

                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            templateUrl: 'commonCellSelection.html',
                            controller: 'commonCellSelectionController',
                            windowClass: 'fullDeviation-modal-window',
                            resolve: {
                                type: function () {
                                    return "a3"
                                }

                            }
                        });
                        modalInstance.result.then(function (result) {
                            if(result){
                                $scope.addColumn(result);
                            }
                        });
                    };

                    // Drag and drop of rows events
                    $scope.$on('row-header-bag.drop-model', function (e, el) {
                        var rowOrder = $scope.a3s;
                        var sortOrder = [];
                        for (var i = 0; i < $scope.a3s.length; i++) {
                            var row = $scope.a3s[i];
                            var rowOrder = {
                                a3TableRowId: row.id,
                                sortOrder: i
                            }
                            sortOrder.push(rowOrder)
                        }
                        a3TableService().updateRowSortOrder($scope.board.id, sortOrder)

                    });
                    // Drag and drop of columns events
                    $scope.$on('column-header-bag.drop-model', function (e, el) {
                        var columnOrder = $scope.a3Columns;
                        var sortOrder = [];
                        for (var i = 0; i < $scope.a3Columns.length; i++) {
                            var column = $scope.a3Columns[i];
                            var columnOrder = {
                                a3TableColumnId: column.id,
                                sortOrder: i
                            }
                            sortOrder.push(columnOrder)
                        }
                        a3TableService().updateColumnSortOrder($scope.board.id, sortOrder).then(function () {

                        });

                    });
                    $scope.$on('column-header-bag.drag', function (e, el) {
                        $(el).find(".drag-drop-icon").hide();
                        $(el).find(".option-icon").hide();
                        el.addClass('drag-moved');
                    });

                    $scope.$on('column-header-bag.drop', function (e, el) {
                        $(el).find(".drag-drop-icon").show();
                        $(el).find(".option-icon").show();
                        el.removeClass('drag-moved');
                    });

                    $scope.$on('column-header-bag.dragend', function (e, el) {
                        $(el).find(".drag-drop-icon").show();
                        $(el).find(".option-icon").show();
                    });

                    $scope.$on('column-header-bag.over', function (e, el) {
                        el.addClass('drag-over');
                    })
                    $scope.$on('column-header-bag.out', function (e, el) {
                        el.removeClass('drag-over');
                    });

                    $scope.$watch("model", function () {
                        if (!$scope.model || !$scope.model.board)
                            return;
                        
                        $scope.board = $scope.model.board;

                        boardService.canEdit($scope.board.id).then(function (canEdit) {
                            $scope.canEdit = canEdit;
                            $scope.editable = canEdit;
                        });

                        navigationService().isBoardAdmin($scope.board.id).then(function (isAdmin) {
                            $scope.hasBoardDesignerPrivileges = isAdmin;
                        });

                        $scope.a3Columns = $scope.model.columns;
                        $scope.a3s = $scope.model.a3s;
                        $scope.title = $scope.board.name;
                        if ($scope.a3s) {
                            for (var i = 0; i < $scope.a3s.length; i++) {
                                var a3 = $scope.a3s[i];
                                a3.cellModels = [];
                                calculateA3Cells(a3, $scope.a3Columns)
                            }
                        }
                    });

                    $scope.subscribe("BoardNameUpdated", function (boardname) {
                        if (boardname.boardId === $scope.board.id) {
                            $scope.title = boardname.name;
                        }
                    });



                    $scope.subscribe("A3TableCellUpdated", function (tablecell) {
                        if (tablecell.boardId === $scope.board.id) {
                            var column = $scope.a3Columns.find(c => c.id == tablecell.id);
                            if (column) {
                                if (column.name !== tablecell.name) {
                                    column.name = tablecell.name;
                                }
                                //TODO: antar vi kan legge til endring i kolonnerekkefølgen også på dette tidspunktet
                            }
                        }
                    });


                    $scope.subscribe("A3AddedToTable", function (a3TableItem) {
                        if (a3TableItem.boardId === $scope.board.id) {
                            var isPartOfBoard = $scope.a3s.filter(a => a.a3TableId == a3TableItem.id);
                            if (isPartOfBoard.length === 0) {
                                var a3 = {
                                    a3: a3TableItem.a3,
                                    a3Id: a3TableItem.a3.id,
                                    cells: [],
                                    a3TableId: a3TableItem.id,
                                    boardId: a3TableItem.boardId
                                }
                                a3.cellModels = [];
                                calculateA3Cells(a3, $scope.a3Columns);
                                $scope.a3s.push(a3);
                            }
                        }
                    });
                    $scope.subscribe("A3TableColumnUpdated", function (updatedColumn) {
                        if (updatedColumn.boardId === $scope.board.id) {
                            var column = $scope.a3Columns.find(c => c.id == updatedColumn.id);
                            if (column) {
                                if (column.name !== updatedColumn.name) {
                                    column.name = updatedColumn.name;
                                }
                                if (updatedColumn.settings) {
                                    column.settings = JSON.parse(updatedColumn.settings);
                                }
                            }
                        }
                    });
                    
                    $scope.subscribe("A3TableSortOrderChanged", function (payLoad) {
                        if (payLoad.boardId === $scope.board.id) {
                            a3TableService().get(payLoad.boardId).then(function (model) {
                                $scope.model = model;
                            })
                        }
                    });

                    $scope.subscribe("A3TableRowSortOrderChanged", function (payLoad) {
                        if (payLoad.boardId === $scope.board.id) {
                            a3TableService().get(payLoad.boardId).then(function (model) {
                                $scope.model = model;
                            });
                        }
                    });
                    $scope.subscribe("A3Deleted", function (payLoad) {
                        var found = null;
                        for (var p = 0; p < $scope.a3s.length; p++) {
                            if ($scope.a3s[p].id === payLoad.id) {
                                found = $scope.a3s[p];
                                found.cssState = "animated slideOutRight";
                                $scope.a3s.splice(p, 1);
                                break;
                            }
                        }
                    });
                    $scope.subscribe("A3TableRowDeleted", function (payload) {
                        if (!$scope.a3s || $scope.a3s.length == 0)
                            return

                        for (let i = 0; i < $scope.a3s.length; i++) {
                            let a3Row = $scope.a3s[i]
                            if (a3Row.id == payload.a3Id && a3Row.boardId == payload.boardId) { // must check boardId also
                                a3Row.cssState = "animated slideOutRight";
                                $scope.a3s.splice(i, 1);
                                break;
                            }
                        }
                    });

                    function calculateA3Cells(a3, columns) {
                        a3.id = a3.a3.id;
                        for (let index = 0; index < columns.length; index++) {
                            const column = columns[index];
                            var cellModel = {
                                a3: a3.a3,
                                column: column,
                                columnId: column.id,
                                a3TableCell: null
                            }
                            var hasCell = a3.cells.find(c => c.a3TableColumnId == column.id);
                            if (hasCell) {
                                var a3TableCell = hasCell;
                                if (a3TableCell.value) {
                                    a3TableCell.value = JSON.parse(a3TableCell.value);
                                }
                                cellModel.a3TableCell = a3TableCell;
                            } else {
                                // Create default 
                                cellModel.a3TableCell = {
                                    id: 0,
                                    a3Id: a3.id,
                                    a3TableColumnId: column.id,
                                    value: null
                                }
                            }
                            a3.cellModels.push(cellModel)
                        }
                    }

                    function addColumnToA3s(column) {
                        for (let index = 0; index < $scope.a3s.length; index++) {
                            var a3 = $scope.a3s[index];
                            var cellModel = {
                                a3: a3.a3,
                                column: column,
                                columnId: column.id,
                                a3TableCell: null
                            }
                            // Create default 
                            cellModel.column = {
                                id: 0,
                                a3Id: a3.a3.id,
                                a3TableColumnId: column.id,
                                value: null
                            }
                            a3.cellModels.push(cellModel)
                        }
                    }

                    $scope.exportToExcel = function () {
                        excelService().exportA3Table($scope.board.id);
                    }

                    $scope.changeTheme = function (theme) {
                        $scope.theme = theme;
                        $scope.updateSettings();
                    }

                    $scope.toggleAdminMode = function () {
                        $scope.isAdminMode = !$scope.isAdminMode;
                    }
                    $scope.columnNameUpdated = function (column) {
                        a3TableService().updateColumn(column)
                    }
                    $scope.titleUpdated = function (title) {
                        var boardName = {
                            boardId: $scope.board.id,
                            name: title
                        }
                        boardService.updateName($scope.board.id, boardName)
                    }
                    
                    $scope.columnWidthChangeHandler = function(col){
                        if(col){
                            a3TableService().updateColumn(col)
                        }
                    }
                    
                    $scope.addColumn = function (type) {
                        if (type === "numberAutomatic") {
                            addNumberAutomaticColumn(type);
                        }  else {
                            var headertext = translationService.getTranslatedSmartColumnName(type);
                            if (headertext === "") {
                                headertext = type.charAt(0).toUpperCase() + type.slice(1);
                            }

                            var column = {
                                boardId: $scope.board.id,
                                name: headertext,
                                columnType: type,
                                sortOrder: $scope.a3Columns.length - 1
                            }
                            a3TableService().addColumn(column)
                        }
                    }

                    $scope.editTimeframe = function (col) {
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'timeframeSelectorModal.html',
                            controller: 'timeframeSelectorModal',
                            resolve: {
                                timePeriod: function () {
                                    return col.settings.timePeriod;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            col.settings.timePeriod = result; //result = timePeriod.timeframe e.g."all"
                            var payload = {
                                columnId: col.id,
                                timePeriod: col.settings.timePeriod
                            }
                            $scope.publish("A3NumberCellAutomaticTimeframeUpdated", payload);

                            a3TableService().updateColumn(col)

                        });

                    }
                    $scope.getTimeframeLabel = function (timeframe) {
                        return timeService.getTimeframeLabel(timeframe);
                    }
                    $scope.predefinedLabels = function (col) {
                        if (col.settings.usePredefinedLabels) {
                            var dataSource = null;
                            var filters = null;
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'predefinedLabels.html',
                                controller: 'predefinedLabels',
                                resolve: {
                                    labels: function () {
                                        return col.settings.predefinedLabels;
                                    }
                                }
                            });

                            modalInstance.result.then(function (result) {
                                col.settings.predefinedLabels = result;
                                var payload = {
                                    type : 'a3',
                                    columnId: col.id,
                                    predefinedLabels: result,
                                    usePredefinedLabels: col.settings.usePredefinedLabels
                                }
                                $scope.publish("SmartCommonTextCellLabelUpdated", payload);

                                a3TableService().updateColumn(col)

                            });
                        } else {
                            col.settings.usePredefinedLabels = false;
                            var payload = {
                                type : 'a3',
                                columnId: col.id,
                                predefinedLabels: col.settings.predefinedLabels,
                                usePredefinedLabels: col.settings.usePredefinedLabels
                            }
                            $scope.publish("SmartCommonTextCellLabelUpdated", payload);

                            a3TableService().updateColumn(col)
                        }
                    }

                    $scope.deleteColumn = function (column) {
                        a3TableService().deleteColumn(column.id);
                    }

                    $scope.updateAggregateType = function (col) {
                        var payload = {
                            type: 'a3',
                            columnId: col.id,
                            aggregateType: col.settings.aggregateType
                        }
                        $scope.publish("SmartCommonNumberCellAggregateTypeUpdated", payload);

                        a3TableService().updateColumn(col)
                    };
                    $scope.updateUnit = function (col) {
                        var payload = {
                            type: 'a3',
                            columnId: col.id,
                            unit: col.settings.unit
                        }
                        $scope.publish("SmartCommonNumberCellUnitUpdated", payload);

                        a3TableService().updateColumn(col)
                    };
                    $scope.updateUnitPlacement = function (col) {
                        var payload = {
                            type: 'a3',
                            columnId: col.id,
                            unitPlacement: col.settings.unitPlacement
                        }
                        $scope.publish("SmartCommonNumberCellUnitPlacementUpdated", payload);

                        a3TableService().updateColumn(col)
                    };
                    $scope.updateDecimals = function (col) {
                        var payload = {
                            type: 'a3',
                            columnId: col.id,
                            decimals: col.settings.decimals
                        }
                        $scope.publish("SmartCommonNumberCellDecimalUpdated", payload);

                        a3TableService().updateColumn(col)
                    };



                    $scope.subscribe("A3TableColumnAdded", function (column) {
                        if (column.boardId === $scope.board.id) {
                            var hasColumn = $scope.a3Columns.find(c => c.id == column.id);
                            if (!hasColumn) {
                                if (column.settings) {
                                    column.settings = JSON.parse(column.settings);
                                }
                                addColumnToA3s(column);
                                $scope.a3Columns.push(column);
                            }
                        }
                    });
                    $scope.subscribe("A3TableColumnDeleted", function (column) {
                        
                        if (column.boardId === $scope.board.id) {
                            a3TableService().get(column.boardId).then(function (model) {
                                $scope.model = model;
                            });
                        }
                    });

                    $scope.a3CellChangeHandler = function (a3TableCell) {
                        if (a3TableCell.value) {
                            a3TableCell.value = JSON.stringify(a3TableCell.value);
                        }

                        a3TableService().updateCell(a3TableCell).then(function (savedCell) {
                            // Parse value again
                            a3TableCell.id = savedCell.id;
                            if (savedCell.value) {
                                a3TableCell.value = JSON.parse(savedCell.value);
                            }
                        });
                    }

                    $scope.addExisting = function (type) {

                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'a3SelectorModal.html',
                            controller: 'a3SelectorController',
                            resolve: {
                                existing: function () {
                                    return $scope.a3s;
                                }
                            }
                        });

                        modalInstance.result.then(function (existing) {
                            if (existing) {
                                a3TableService().addA3ToTable(existing.id, $scope.model.board.id).then(function (a3Table) {
                                });
                            }
                        }, function () {
                        });


                    }

                    $scope.addSmarttableRow = function () {
                        var newItem = {
                            name: $translate.instant('COMMON_NEW'),
                            category: "SmartTable"
                        };

                        a3TableService().addA3(newItem).then(function (createdA3) {
                            a3TableService().addA3ToTable(createdA3.id, $scope.model.board.id)
                        });
                    }

                    $scope.delete = function (a3Model) {
                        var modalOptions = {
                            closeButtonText: $translate.instant('COMMON_CANCEL'),
                            actionButtonText: $translate.instant('COMMON_DELETE'),
                            headerText: a3Model.a3.title,
                            bodyText: $translate.instant('ADMIN_MESSAGE_DELETE_ROW_CONFIRMATION')   
                        };
        
                        modalService.showModal({}, modalOptions).then(function (result) {
                            var a3TableRow = {
                                a3Id: a3Model.a3Id,
                                boardId: a3Model.boardId,
                                id: 0
                            }
                            a3TableService().deleteRow(a3TableRow);
                        });
                        
                    }


                    $scope.calculateNumberCellTotal = function (colDef) {
                        var total = 0;
                        for (var i = 0; i < colDef.a3TableCell.length; i++) {
                            var value = colDef.a3TableCell[i].value;
                            var valueObject = JSON.parse(value);
                            var cellNumber = valueObject.number;
                            if (cellNumber) {
                                total = total + cellNumber;
                            }
                        }
                        return total;
                    }

                    $scope.getColStyle = getColStyle
                }
            }
        }]);
import * as customerService from "@common/services/customers/customerService"
import { getUserProfile } from "@common/stores/userStore"

angular
    .module('DigiLean')
    .directive("deviationUpdatedStatusMessage", ['$uibModal', 'deviationService',
        function ($uibModal, deviationService) {
            return {
                templateUrl: 'deviationUpdatedStatusMessage.html',
                restrict: 'E',
                scope: {
                    "payLoad": "<",
                },
                link: function (scope, elem, attrs) {
                    scope.deviation = null;
                    scope.isLoading = true;
                    scope.users = [];
                    scope.selectedCommentId = 0;
                    scope.$watch('payLoad', function (payLoad) {
                        if (!payLoad) return;
                        scope.selectedCommentId = payLoad.Id;
                        deviationService().get(payLoad.DeviationId).then(function (deviation) {
                            scope.deviation = deviation;
                            if (scope.deviation.followUpAssetId) {
                                customerService.getUsersForAsset(scope.deviation.followUpAssetId).then(function (users) {
                                    scope.users = users;
                                });
                            }
                        });
                        // Get current user + users for commenting
                        
                        scope.currentUser = getUserProfile();
                        deviationService().getComments(payLoad.DeviationId).then(function (model) {
                            scope.comments = model.comments;
                        });
               
                    });
                    
                    scope.saveComment = function(comment) {
                        return deviationService().addComment(scope.deviation.id, comment);
                    };

                    scope.deleteComment = function(comment) {
                        return deviationService().deleteComment(scope.deviation.id, comment.id);
                    };

                    scope.openDeviation = function (activeTab) {
                        if (!activeTab) activeTab = "Info";
                        
                        $uibModal.open({ backdrop: 'static',
                            templateUrl: 'fullDeviation.html',
                            controller: 'fullDeviationController',
                            windowClass: 'full-screen',
                            resolve: {
                                deviation: function () {
                                    return scope.deviation;
                                },
                                activeTab: function () {
                                    return activeTab;
                                }
                            }
                        });

                    }
                }
            }
        }]);
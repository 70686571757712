﻿import envConfig from "@common/envConfig";


angular.module('DigiLean').factory('customerAccountService', ['$http', '$translate',
    function ($http, $translate) {
        function customerAccountService() {
            var resource = "/customeraccount";
            var resourceUrl = envConfig.webApiUrl + resource;
            return {
                createInvoice: function (invoice) {
                    var url = resourceUrl + "/createInvoice";
                    return $http.post(url, invoice).then(function (result) {
                        return result.data;
                    });
                },
                getInvoiceSummaryForPeriod: function (period) {
                    var url = resourceUrl + "/getInvoiceSummaryForPeriod?from=" + period.from + "&to=" + period.to;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getProductOrders: function () {
                    var url = resourceUrl + "/getProductOrders";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getInvoicesForPeriod: function (period) {
                    var url = resourceUrl + "/getInvoicesForPeriod?from=" + period.from + "&to=" + period.to;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getRecurringInvoice: function () {
                    var url = resourceUrl + "/getRecurringInvoice";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getRecurringInvoiceFromInputData: function (account) {
                    var url = resourceUrl + "/getRecurringInvoiceFromInputData";
                    return $http.post(url, account).then(function (result) {
                        return result.data;
                    });
                },
                getTrialPeriod: function () {
                    return 14;
                },
                getSubscription: function () {
                    var url = resourceUrl + "/getSubscription";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getPaymentDetails: function () {
                    var url = resourceUrl + "/getPaymentDetails";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getCustomerAccount: function () {
                    var url = resourceUrl + "/getCustomerAccount";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getStandardModules: function () {
                    return [
                        {
                            id: "IMPROVEMENT",
                            title: $translate.instant('COMMON_IMPROVEMENT'),
                            icon: "fal fa-lightbulb",
                            desc: $translate.instant('COMMON_IMPROVEMENT_DESC'),
                            moduleId: 3
                        },
                        {
                            id: "DEVIATION",
                            title: $translate.instant('COMMON_DEVIATION'),
                            icon: "fa fa-exclamation-triangle",
                            desc: $translate.instant('COMMON_DEVIATION_DESC'),
                            moduleId: 4
                        },
                        {
                            id: "PROJECT",
                            title: $translate.instant('COMMON_PROJECT'),
                            icon: "far fa-calendar-alt",
                            desc: $translate.instant('COMMON_PROJECT_DESC'),
                            moduleId: 6
                        },
                        {
                            id: "A3",
                            title: $translate.instant('COMMON_A3'),
                            icon: "far fa-file",
                            desc: $translate.instant('COMMON_A3_DESC'),
                            moduleId: 5
                        }
                    ];
                },
                getProfessionalModules: function () {
                    return [
                        // {
                        //     id:"STRATEGY",
                        //     title: $translate.instant('STRATEGY_STRATEGY'),
                        //     icon: "fas fa-rocket"
                        // },
                        {
                            id: "LEARNING",
                            title: $translate.instant('LEARNING_LEARNING_CENTER'),
                            icon: "fas fa-graduation-cap",
                            desc: $translate.instant('COMMON_LEARNING_DESC'),
                            moduleId: 11
                        }
                    ];
                },
                cancelSubscription: function (endDate) {
                    var url = resourceUrl + "/cancelSubscription/";
                    return $http.put(url, endDate).then(function (result) {
                        return result.data;
                    });
                },
                updateCustomerProductOrder: function (order) {
                    var url = resourceUrl + "/updateCustomerProductOrder/";
                    return $http.put(url, order).then(function (result) {
                        return result.data;
                    });
                },
                updateCustomerAccount: function (account) {
                    var url = resourceUrl + "/updateCustomerAccount/";
                    return $http.put(url, account).then(function (result) {
                        return result.data;
                    });
                },
                updatePaymentDetails: function (customerPaymentDetails) {
                    var url = resourceUrl + "/UpdatePaymentDetails/";
                    return $http.put(url, customerPaymentDetails).then(function (result) {
                        return result.data;
                    });
                },
                reactivateAccount: function () {
                    var url = resourceUrl + "/reactivateAccount/";
                    return $http.put(url).then(function (result) {
                        return result.data;
                    });
                },

            }
        }
        return customerAccountService;
    }]);


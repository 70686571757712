﻿import envConfig from "@common/envConfig";

angular.module('DigiLean').factory('qualityService', ['$rootScope', '$http',
  function ($rootScope, $http) {

       function qualityService() {
          var resource = "/quality";
          var resourceUrl = envConfig.webApiUrl + resource;

          return {
            getStandardVisualizationTypes(){
                return [
                    {
                        type: "DYNAMIC",
                        title: "Dynamic"
                    },
                    {
                        type: "5S",
                        title: "5S"
                    },
                    {
                        type: "GREENCROSS",
                        title: "Green cross"
                    },
                    {
                        type: "A",
                        title: "A"
                    },
                    {
                        type: "C",
                        title: "C"
                    },
                    {
                        type: "D",
                        title: "D"
                    },
                    {
                        type: "I",
                        title: "I"
                    },
                    {
                        type: "K",
                        title: "K"
                    },
                    {
                        type: "L",
                        title: "L"
                    },
                    {
                        type: "P",
                        title: "P"
                    },
                    {
                        type: "Q",
                        title: "Q"
                    },
                ];
            },
            updateDateStatus: function (dateStatus) {
                var url = resourceUrl + "/UpdateDateStatus";
                return $http.put(url, dateStatus).then(function (result) {
                    return result.data;
                });
            },
            deleteDateStatus: function (id) {
                var url = resourceUrl + "/DeleteDateStatus/" + id;
                return $http.delete(url).then(function (result) {
                    return result.data;
                });
            },
            deleteVisualizationType: function (visualizationTypeId) {
                var url = resourceUrl + "/DeleteVisualizationType/" + visualizationTypeId;
                return $http.delete(url).then(function (result) {
                    return result.data;
                });
            },
            getAllStatusValuesForPeriod: function (visualizationType, timePeriod, filters) {
                var url = resourceUrl + "/getAllStatusValuesForPeriod";
                var visualizationPeriodQuery = {
                    visualizationType: visualizationType,
                    timePeriod: timePeriod,
                    filters:filters
                };
                return $http.post(url, visualizationPeriodQuery).then(function (result) {
                    return result.data;
                });
            },

            getStatusValuesForYear: function (visualizationType, areaId, projectId, assetId,year, filters) {
                var url = resourceUrl + "/GetStatusValuesForYear";
                var visualizationYearQuery = {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    visualizationType: visualizationType,
                    areaId: areaId,
                    projectId: projectId,
                    assetId: assetId,
                    year: year,
                    filters: filters
                };
                return $http.post(url, visualizationYearQuery).then(function (result) {
                    return result.data;
                });
            },

            
            getStatusDeviationValuesForYear: function (deviationTypeId, assetId, year, filters) {
                var url = resourceUrl + "/GetStatusDeviationValuesForYear";
                var visualizationDeviationYearQuery = {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    deviationTypeId: deviationTypeId,
                    assetId:assetId,
                    year: year,
                    filters: filters
                };
                return $http.post(url, visualizationDeviationYearQuery).then(function (result) {
                    return result.data;
                });
            },
            
            getStatusDeviationValuesForMonth: function (deviationTypeId, assetId, month, year, filters) {
                var url = resourceUrl + "/GetStatusDeviationValuesForMonth";
                var visualizationYearQuery = {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    deviationTypeId: deviationTypeId,
                    assetId:assetId,
                    year: year,
                    month: month,
                    filters: filters
                };
                return $http.post(url, visualizationYearQuery).then(function (result) {
                    return result.data;
                });
            },
            getStatusValuesForMonth: function (visualizationType, areaId, projectId, assetId,month, year, filters) {
                var url = resourceUrl + "/GetStatusValuesForMonth";
                var visualizationYearQuery = {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    visualizationType: visualizationType,
                    areaId: areaId,
                    projectId: projectId,
                    assetId: assetId,
                    year: year,
                    month: month,
                    filters: filters
                };
                return $http.post(url, visualizationYearQuery).then(function (result) {
                    return result.data;
                });
            },
            getLatestProjectStatusValue: function (visualizationType, projectId) {
                var url = resourceUrl + "/GetLatestProjectStatusValue";
                var visualizationProjectQuery = {
                    visualizationType: visualizationType,
                    projectId: projectId
                };
                return $http.post(url, visualizationProjectQuery).then(function (result) {
                    return result.data;
                });
            },
            getVisualizationTypeVariants: function (visualizationType) {
                var url = resourceUrl + "/GetVisualizationTypeVariants";
                var visualizationProjectQuery = {
                    visualizationType: visualizationType
                };
                return $http.post(url, visualizationProjectQuery).then(function (result) {
                    return result.data;
                });
            },
            getVisualizationTypesForProjects: function () {
                var url = resourceUrl + "/getVisualizationTypesForProjects";
               
                return $http.get(url).then(function (result) {
                    return result.data;
                });
            },
            getTableValues: function (visualizationType, tableParams) {
                var url = resourceUrl + "/getTableValues/" + visualizationType;
                return $http.post(url, tableParams).then(function (result) {
                    return result.data;
                });
            },
            getActiveVisualizationTypes: function () {
                var url = resourceUrl + "/GetActiveVisualizationTypes";
                return $http.get(url).then(function (result) {
                    return result.data;
                });
            },
          }
      }
      return qualityService;
  }]);
import * as assetService from "@common/services/assetService"
import fa from "@common/thirdparty/fullcalendar/locale/fa";
import { filter, get } from "lodash";

angular
    .module('DigiLean')
    .directive("improvementPriorityFilter", ['$uibModal', '$filter', 'navigationService', 'strategyService', 'suggestionService', 'projectService','debounce', 'dataFilterService',
        function ($uibModal, $filter, navigationService, strategyService, suggestionService, projectService, debounce, dataFilterService) {
            return {
                templateUrl: 'improvementPriorityFilter.html',
                restrict: 'E',
                scope: {
                    'filter': '<',
                    'filterChangedHandler': '&',
                    'suggestionsList': '=',
                    'suggestionStatus1': '=',
                    'suggestionStatus2': '=',
                    'suggestionStatus3': '=',
                    'suggestionStatus4': '=',
                    'timePeriod': '=',
                    'suggestionsCount': '=',
                    'suggestionStatus1Count': '=', 
                    'suggestionStatus2Count': '=', 
                    'suggestionStatus3Count': '=', 
                    'suggestionStatus4Count': '=', 
                },
                link: function (scope, elem, attrs) {
                    scope.isFilterActive = false;
                    scope.likesFilterActive = false;
                    scope.assets = [];
                    scope.categories = [];
                    scope.projects = [];
                    scope.goals = [];
                    scope.currentFilter = {};
                    scope.alreadyUpdated = false;
                    scope.initialLoad = false;
                    var lockCountListenersFromRecievingEvents = false;
                    var firstTimeFilterLoad = true;

                    //When suggestions are loaded, count variables are initialized to -1
                    //Those counts are meant to be temporary placeholders for the ones that are displayed in the UI
                    //This is to avoid counting/displaying the suggestion number when they are loaded for the first time
                    //or when the numbers change rapidly (to not make the user confused)
                    var SuggestionsTotalCount = -1;
                    var SuggestionStatus1TotalCount = -1;
                    var SuggestionStatus2TotalCount = -1;
                    var SuggestionStatus3TotalCount = -1;
                    var SuggestionStatus4TotalCount = -1;

                    scope.remainingSuggestions = 0;

                    // TODO Hvorfor er dette kommentert ut? Hva omm kunden tidligere hadde prosjektmodul og nå har tatt den bort...
                    // Dessuten gjør vi et ekstra backendkall som ikke er nødvendig.
                    // scope.hasProjectModule = navigationService().hasModule("PROJECT");
                    // scope.subscribe("UserAuthenticatedAndReady", function(profile) {
                    //     scope.hasProjectModule = navigationService().hasModule("PROJECT"); 
                    // });
                    scope.hasStrategyModule = navigationService().hasModule("STRATEGY");
                    scope.subscribe("UserAuthenticatedAndReady", function(profile) {
                        scope.hasStrategyModule = navigationService().hasModule("STRATEGY"); 
                    });

                    scope.$watch('filter', function (filter) {
                        if (filter) {
                                scope.filterPreferences = filter;
                                if(firstTimeFilterLoad){
                                    getAllData();
                                    firstTimeFilterLoad = false;
                                }}});


                    function getAllData() {
                        lockCountListenersFromRecievingEvents = true;
                        applyUserFilterPreferences();
                        var listStatus1 = [];
                        var listStatus2 = [];
                        var listStatus3 = [];
                        var listStatus4 = [];
                        var listSuggestions = [];

                        scope.currentFilter = createFilter();

                        var dbParams = {
                            page: 1,
                            count: 100,
                            sorting: [
                            {property:"lastModified", direction: "desc"}],
                            filters: scope.currentFilter,
                            timePeriod: scope.timePeriod
                        }
                        suggestionService().getPriorityTableValues(dbParams).then(function (suggestions) {
                            var suggestionList = [];
                            suggestions.values.forEach(function (suggestion) {
                                if(suggestion.status === "Suggested"){
                                    suggestion.activeComment = "";
                                    suggestionList.push(suggestion);
                                }else if(suggestion.status === "Planned"){
                                    switch (suggestion.priorityStatus) {
                                        case 1:
                                            listStatus1.push(suggestion);
                                            break;
                                        case 2:
                                            listStatus2.push(suggestion);
                                            break;
                                        case 3:
                                            listStatus3.push(suggestion);
                                            break;
                                        case 4:
                                            listStatus4.push(suggestion);
                                            break;
                                    }
                                }
                            });
                            
                            return new Promise(function (resolve) {
                                scope.suggestionStatus1 = listStatus1;
                                scope.suggestionStatus2 = listStatus2;
                                scope.suggestionStatus3 = listStatus3;
                                scope.suggestionStatus4 = listStatus4;
                                scope.suggestionsList = suggestionList;
                                scope.suggestionsCount = suggestions.suggestedCount;
                                scope.suggestionStatus1Count = suggestions.priorityStatus1Count;
                                scope.suggestionStatus2Count = suggestions.priorityStatus2Count;
                                scope.suggestionStatus3Count = suggestions.priorityStatus3Count;
                                scope.suggestionStatus4Count = suggestions.priorityStatus4Count;
                                resolve();
                            }).then(function () {
                                // Proceed with the rest of the updates
                                SuggestionsTotalCount = scope.suggestionsList.length;
                                SuggestionStatus1TotalCount = scope.suggestionStatus1.length;
                                SuggestionStatus2TotalCount = scope.suggestionStatus2.length;
                                SuggestionStatus3TotalCount = scope.suggestionStatus3.length;
                                SuggestionStatus4TotalCount = scope.suggestionStatus4.length;
                                lockCountListenersFromRecievingEvents = false;
                                firstTimeFilterLoad = false;
                                updateFilterPreferences();
                            });
                        })};

                        function applyUserFilterPreferences() {
                            if (scope.filterPreferences) {
                                if (scope.filterPreferences.asset) {
                                    setAssetFilterPreferences(scope.filterPreferences.asset);
                                }
                                if (scope.filterPreferences.project) {
                                    setProjectFilterPreferences(scope.filterPreferences.project);
                                }
                                if (scope.filterPreferences.goal) {
                                    setGoalFilterPreferences(scope.filterPreferences.goal);
                                }
                                if (scope.filterPreferences.category) {
                                    setCategoryFilterPreferences(scope.filterPreferences.category);
                                }
                                if (scope.filterPreferences.status) {
                                    setStatusFilterPreferences(scope.filterPreferences.status);
                                }
                                if (scope.filterPreferences.likes) {
                                    scope.likesFilterActive = true;
                                }
                            }
                        }

                    scope.$watch('suggestionsList', function () {
                        if (scope.suggestionsList) {
                            loadAssets();
                            loadCategories();
                            loadProjects();
                            loadGoals();
                        }
                    });

                    function initializeAssets(){
                        if (!scope.assets || scope.assets.length === 0) return;

                        for (var k = 0; k < scope.assets.length; k++) {
                            scope.assets[k].cssLevel = "level-" + scope.assets[k].level;                     
                        }
                    }
                    scope.openGroupFilter = function () {
                        var copyOfAssets = scope.assets ? JSON.parse(JSON.stringify(scope.assets)) : [];
                        var modalInstance = $uibModal.open({
                            templateUrl: 'assetMultiSelectorForm.html',
                            controller: 'assetMultiSelectorController',
                            windowClass: 'assetparticipants-modal-window',
                            resolve: {
                                assets: function () {
                                    return copyOfAssets;
                                }
                            }
                        });
                        modalInstance.result.then(function (filteredAssets) {
                            scope.assets = filteredAssets;
                            scope.selectedAssets = scope.assets.filter(a => a.filteredAndInResultList === true)
                            filterAsset();
                        });
                    }

                    // Events
                    scope.subscribe("NewImprovement", function (newSuggestion) {
                            suggestionService().getPriority(newSuggestion.id).then(function (suggestion) {
                                removeAnmiation(suggestion);
                                if (scope.isFilterActive) {
                                    var filterMatches = dataFilterService().matchesFilters(suggestion, scope.currentFilter);
                                    if(filterMatches)
                                    {
                                        suggestion.cssState = "animated slideInDown"; // Add animation
                                        scope.suggestionsList.unshift(suggestion);
                                    }
                                }
                                else 
                                {
                                    suggestion.cssState = "animated slideInDown"; // Add animation
                                    scope.suggestionsList.unshift(suggestion);
                                }
                            });
                    });

                    function removeAnmiation(suggestion) {
                        setTimeout(function () {
                            suggestion.cssState = "";
                        }, 500);
                    }
                    scope.subscribe("ImprovementDeleted", function (improvement) {
                        var id = improvement.id;
                        removeFromList(scope.suggestionStatus1, id);
                        removeFromList(scope.suggestionStatus2, id);
                        removeFromList(scope.suggestionStatus3, id);
                        removeFromList(scope.suggestionStatus4, id);

                        var existingSuggestions = $filter('filter')(scope.suggestionsList, { id: id }, true);
                        if (existingSuggestions.length > 0) {
                            // Apply animation
                            var index = scope.suggestionsList.indexOf(existingSuggestions[0]);
                            existingSuggestions[0].cssClass = "animated slideOutLeft"; // Add animation
                            scope.suggestionsList[index] = existingSuggestions[0];                  

                            // Then remove
                            setTimeout(function () {
                                scope.suggestionsList.splice(index, 1);
                                var existingfilteredSuggestions = $filter('filter')(scope.suggestionsList, { id: id }, true);
                            if (existingfilteredSuggestions.length > 0) {
                                // Apply animation
                                var indexfiltered = scope.suggestionsList.indexOf(existingfilteredSuggestions[0]);
                                existingfilteredSuggestions[0].cssClass = "animated slideOutLeft"; // Add animation
                                scope.suggestionsList[indexfiltered] = existingfilteredSuggestions[0];
                                // Then remove
                                setTimeout(function () {
                                    scope.suggestionsList.splice(indexfiltered, 1);
                                }, 500);
                            }
                            }, 500);
                        }
                    });

                    scope.subscribe("ImprovementGoalChanged", function (item) {
                        var id = item.improvementSuggestionId;
                        function updateGoals(suggestionList) {
                            var existingSuggestion = $filter('filter')(suggestionList, { id: id }, true);
                            if (existingSuggestion.length === 1) {
                                    existingSuggestion[0].goals = item.goals;
                                    return;
                            }
                        }
                    
                        updateGoals(scope.suggestionsList);
                        updateGoals(scope.suggestionStatus1);
                        updateGoals(scope.suggestionStatus2);
                        updateGoals(scope.suggestionStatus3);
                        updateGoals(scope.suggestionStatus4);
                    });

                    scope.subscribe("ImprovementChangedStatus", function (statusData) {
                        Promise.all([
                            removeFromList(scope.suggestionsList, statusData.suggestionId),
                            removeFromList(scope.suggestionStatus1, statusData.suggestionId),
                            removeFromList(scope.suggestionStatus2, statusData.suggestionId),
                            removeFromList(scope.suggestionStatus3, statusData.suggestionId),
                            removeFromList(scope.suggestionStatus4, statusData.suggestionId)
                        ]).then(function () {
                            if (statusData.status === "Suggested" || statusData.status === "Planned") {
                                addToSuggestionList(statusData);
                            }
                        });
                    });

                    function addToSuggestionList(changedSuggestion) {
                        suggestionService().getPriority(changedSuggestion.suggestionId).then(function (suggestion) {
                            suggestion.cssState = "animated slideInDown"; // Add animation
                            if (changedSuggestion.status === "Suggested") {
                                addIfNotAlreadyInList(scope.suggestionsList, suggestion);
                            } else {
                                if (suggestion.priorityStatus === 1) {
                                    addIfNotAlreadyInList(scope.suggestionStatus1, suggestion);
                                } else if (suggestion.priorityStatus === 2) {
                                    addIfNotAlreadyInList(scope.suggestionStatus2, suggestion);
                                } else if (suggestion.priorityStatus === 3) {
                                    addIfNotAlreadyInList(scope.suggestionStatus3, suggestion);
                                } else if (suggestion.priorityStatus === 4) {
                                    addIfNotAlreadyInList(scope.suggestionStatus4, suggestion);
                                }
                            }
                            removeAnmiation(suggestion);
                        });
                    }

                    function addIfNotAlreadyInList(list, suggestion) {
                        var inList = $filter('filter')(list, { id: suggestion.id }, true);
                        if (inList.length === 0) {
                            list.unshift(suggestion);
                        }
                    }
                    scope.subscribe("ImprovementPriorityChanged", function (priority) {
                        var existInSuggestedList = $filter('filter')(scope.suggestionList, { id: priority.suggestionId }, true);
                        if (existInSuggestedList && existInSuggestedList.length === 1) {
                            removeFromList(scope.suggestionList, priority.suggestionId);
                            return;
                        }

                        suggestionService().getPriority(priority.suggestionId).then(function (suggestion) {
                            suggestion.cssState = "animated slideInDown"; 
                            return Promise.all([
                                removeFromList(scope.suggestionStatus1, priority.suggestionId),
                                removeFromList(scope.suggestionStatus2, priority.suggestionId),
                                removeFromList(scope.suggestionStatus3, priority.suggestionId),
                                removeFromList(scope.suggestionStatus4, priority.suggestionId)
                            ]).then(function () {
                                switch (priority.newPriority) {
                                    case 1:
                                        addIfNotAlreadyInList(scope.suggestionStatus1, suggestion);
                                        break;
                                    case 2:
                                        addIfNotAlreadyInList(scope.suggestionStatus2, suggestion);
                                        break;
                                    case 3:
                                        addIfNotAlreadyInList(scope.suggestionStatus3, suggestion);
                                        break;
                                    case 4:
                                        addIfNotAlreadyInList(scope.suggestionStatus4, suggestion);
                                        break;
                                }
                                removeAnmiation(suggestion);
                            });
                        });
                    });

                    function removeFromList(container, id) {
                        var isPartOfContainer = $filter('filter')(container, { id: id }, true);
                        if (isPartOfContainer.length > 0) {
                            var currentSuggestion = isPartOfContainer[0];
                            var index = container.indexOf(currentSuggestion);
                            container.splice(index, 1);
                        }
                    }


                    const filterDebounce = debounce(getAllData, 1000, false)

                    scope.filterProject = function (project) {
                        project.filteredAndInResultList = !project.filteredAndInResultList;
                        if (project.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.project) {
                                scope.filterPreferences.project.push(project.id);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.project = [project.id];
                                } else {                                    scope.filterPreferences = {
                                        project: [project.id]
                                    };
                                }
                                scope.filterPreferences.project = [project.id];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.project) {
                                var index = scope.filterPreferences.project.indexOf(project.id);
                                scope.filterPreferences.project.splice(index, 1);
                            }
                        }
                        filterDebounce();
                    }

                    scope.filterGoal = function (goal) {
                        goal.filteredAndInResultList = !goal.filteredAndInResultList;
                        if (goal.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.goal) {
                                scope.filterPreferences.goal.push(goal.id);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.goal = [goal.id];
                                } else {
                                    scope.filterPreferences = {
                                        goal: [goal.id]
                                    };
                                }
                                scope.filterPreferences.goal = [goal.id];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.goal) {
                                var index = scope.filterPreferences.goal.indexOf(goal.id);
                                scope.filterPreferences.goal.splice(index, 1);
                            }
                        }
                        filterDebounce();
                    }

                    function setAssetFilterPreferences(preferences) {
                        scope.selectedAssets = [];
                        angular.forEach(preferences, function (filteredAssetId) {
                            for (var a = 0; a < scope.assets.length; a++) {
                                if (scope.assets[a].id === filteredAssetId) {
                                    scope.assets[a].filteredAndInResultList = true;
                                    scope.selectedAssets.push(scope.assets[a]);
                                    break;
                                }
                            }
                        });
                    }
                    function setProjectFilterPreferences(preferences) {
                        angular.forEach(preferences, function (projectId) {
                            for (var t = 0; t < scope.projects.length; t++) {
                                if (scope.projects[t].id === projectId) {
                                    scope.projects[t].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }
                    function setGoalFilterPreferences(preferences) {
                        angular.forEach(preferences, function (id) {
                            for (var t = 0; t < scope.goals.length; t++) {
                                if (scope.goals[t].id === id) {
                                    scope.goals[t].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }
                    function setCategoryFilterPreferences(preferences) {
                        angular.forEach(preferences, function (filteredId) {
                            for (var i = 0; i < scope.categories.length; i++) {
                                if (scope.categories[i].id === filteredId) {
                                    scope.categories[i].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }

                    scope.$watch('suggestionsList.length', function(newLength) {
                        if(lockCountListenersFromRecievingEvents || SuggestionsTotalCount === -1) return;
                        if(newLength > SuggestionsTotalCount)
                        {
                            scope.suggestionsCount++;
                        }
                        else if(newLength < SuggestionsTotalCount)
                        {
                            scope.suggestionsCount--;
                        }
                        SuggestionsTotalCount = newLength;
                    });

                    scope.$watch('suggestionStatus1.length', function(newLength) {
                        if(lockCountListenersFromRecievingEvents || SuggestionStatus1TotalCount === -1) return;
                        if(newLength > SuggestionStatus1TotalCount)
                        {
                            scope.suggestionStatus1Count++;
                        }
                        else if(newLength < SuggestionStatus1TotalCount)
                        {
                            scope.suggestionStatus1Count--;
                        }
                        SuggestionStatus1TotalCount = newLength;
                    });

                    scope.$watch('suggestionStatus2.length', function(newLength) {
                        if(lockCountListenersFromRecievingEvents || SuggestionStatus2TotalCount === -1) return;
                        if(newLength > SuggestionStatus2TotalCount)
                        {
                            scope.suggestionStatus2Count++;
                        }
                        else if(newLength < SuggestionStatus2TotalCount)
                        {
                            scope.suggestionStatus2Count--;
                        }
                        SuggestionStatus2TotalCount = newLength;
                    });

                    scope.$watch('suggestionStatus3.length', function(newLength) {
                        if(lockCountListenersFromRecievingEvents || SuggestionStatus3TotalCount === -1) return;
                        if(newLength > SuggestionStatus3TotalCount)
                        {
                            scope.suggestionStatus3Count++;
                        }
                        else if(newLength < SuggestionStatus3TotalCount)
                        {
                            scope.suggestionStatus3Count--;
                        }
                        SuggestionStatus3TotalCount = newLength;
                    });

                    scope.$watch('suggestionStatus4.length', function(newLength) {
                        if(lockCountListenersFromRecievingEvents || SuggestionStatus4TotalCount === -1) return;
                        if(newLength > SuggestionStatus4TotalCount)
                        {
                            scope.suggestionStatus4Count++;
                        }
                        else if(newLength < SuggestionStatus4TotalCount)
                        {
                            scope.suggestionStatus4Count--;
                        }
                        SuggestionStatus4TotalCount = newLength;
                    });

                    scope.statuses = [scope.suggestionsList, scope.planned, scope.inprogress, scope.implemented, scope.evaluated];

                    function loadAssets() {
                        assetService.getAllAssets().then(function (assets) {
                            scope.assets = assets;
                            resetAssetFilter();
                            applyUserFilterPreferences();
                        });
                    }
                    function loadProjects() {
                        projectService().getProjectListWithSuggestion().then(function (items) {
                            scope.projects = items;
                            resetProjectFilter();
                            applyUserFilterPreferences();
                        });
                    }
                    function loadCategories() {
                        suggestionService().getCategories().then(function (items) {
                            scope.categories = items;
                            resetCategoryFilter();
                            applyUserFilterPreferences();
                        });
                    }
                    function loadGoals() {
                        strategyService().getBreakthroughObjectives().then(function (items) {
                            scope.goals = items;
                            resetGoalFilter();
                            applyUserFilterPreferences();
                        });
                    }

                    scope.toggleLikesFilter = function () {
                        scope.likesFilterActive = !scope.likesFilterActive;
                        if (scope.likesFilterActive) {
                            if (scope.filterPreferences) {
                                scope.filterPreferences.likes = true;
                            } else {
                                scope.filterPreferences = {
                                    likes: true
                                };
                            }
                        } else { // remove from filter
                            scope.filterPreferences.likes = null;
                        }
                        filterDebounce();
                    }


                    function resetAssetFilter() {
                        angular.forEach(scope.assets, function (asset) {
                            asset.filteredAndInResultList = false;
                        });
                        scope.selectedAssets = [];
                    }
                    function resetProjectFilter() {
                        angular.forEach(scope.projects, function (project) {
                            project.filteredAndInResultList = false;
                        });
                    }
                    function resetGoalFilter() {
                        angular.forEach(scope.goals, function (goal) {
                            goal.filteredAndInResultList = false;
                        });
                    }

                    function resetCategoryFilter() {
                        angular.forEach(scope.categories, function (category) {
                            category.filteredAndInResultList = false;
                        });
                    }

                    function filterAsset() {
                        if (!scope.filterPreferences) {
                            scope.filterPreferences = { asset: [] };
                        } else if (!scope.filterPreferences.asset) {
                            scope.filterPreferences.asset = [];
                        }

                        scope.filterPreferences.asset = scope.selectedAssets.map(item => item.id);
                        filterDebounce();
                    }

                    scope.filterCategory = function (category) {
                        category.filteredAndInResultList = !category.filteredAndInResultList;
                        if (category.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.category) {
                                scope.filterPreferences.category.push(category.id);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.category = [category.id];
                                } else {
                                    scope.filterPreferences = {
                                        category: [category.id]
                                    };
                                }
                                scope.filterPreferences.category = [category.id];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.category) {
                                var index = scope.filterPreferences.category.indexOf(category.id);
                                scope.filterPreferences.category.splice(index, 1);
                            }
                        }
                        filterDebounce();
                    }

                    function updateFilterPreferences() {
                        scope.filterChangedHandler({ filter: scope.filterPreferences });
                    }

                    scope.removeFilter = function () {
                        resetAssetFilter();
                        resetProjectFilter();
                        resetGoalFilter();
                        resetCategoryFilter();
                        scope.likesFilterActive = false;
                        resetFilterPreferences();
                        getAllData();
                    };

                    function resetFilterPreferences() {
                        scope.filterPreferences = [];
                        SuggestionsTotalCount = -1;
                        SuggestionStatus1TotalCount = -1;
                        SuggestionStatus2TotalCount = -1;
                        SuggestionStatus3TotalCount = -1;
                        SuggestionStatus4TotalCount = -1;
                        updateFilterPreferences();
                    }

                    function createFilter() {
                        var assets = [];
                        var iFilter = [];
                        var filterList = {
                            asset: [],
                            project: [],
                            goal: [],
                            category: [],
                            likes : false
                        };
                        var assetsToShow = scope.filterPreferences.asset;
                        if (assetsToShow && assetsToShow.length > 0) {
                            var i = iFilter.push({
                                sourceColumn: "assetId",
                                operator: "InList",
                                items: []
                            })
                            iFilter[i - 1].items = []; // Initialize the items array for the newly added filter
                            assetsToShow.forEach(element => {
                                iFilter[i - 1].items.push(element);
                                filterList.asset.push(element);
                            });
                        }

                        var projectsToShow = scope.filterPreferences.project;
                        if (projectsToShow && projectsToShow.length > 0) {
                            var i = iFilter.push({
                                sourceColumn: "projectId",
                                operator: "InList",
                                    items: []
                            })
                            iFilter[i - 1].items = []; // Initialize the items array for the newly added filter
                            projectsToShow.forEach(element => {
                                iFilter[i - 1].items.push(element);
                                filterList.project.push(element);
                            });
                        }

                        var goalsToShow = scope.filterPreferences.goal;
                        if (goalsToShow && goalsToShow.length > 0) {
                            var i = iFilter.push({
                                sourceColumn: "Goals.StrategyGoalId",
                                operator: "InList",
                                items: []
                            })
                            iFilter[i - 1].items = []; // Initialize the items array for the newly added filter
                            goalsToShow.forEach(element => {
                                iFilter[i - 1].items.push(element);
                                filterList.goal.push(element);
                            });
                        }

                        var categoryToShow = scope.filterPreferences.category;
                        if (categoryToShow && categoryToShow.length > 0) {
                            var i = iFilter.push({
                                sourceColumn: "categoryId",
                                operator: "InList",
                                items: []
                            })
                            iFilter[i - 1].items = []; // Initialize the items array for the newly added filter
                            categoryToShow.forEach(element => {
                                iFilter[i - 1].items.push(element);
                                filterList.category.push(element);
                            });
                        }

                        if (scope.likesFilterActive) {
                            var i = iFilter.push({
                                sourceColumn: "Likes.Id",
                                operator: "InList",
                                items: []
                            })
                            filterList.likes = true;
                        }

                        if(iFilter.length > 0){
                            scope.isFilterActive = true;
                        }
                        else
                        {
                            scope.isFilterActive = false;
                        }

                        iFilter.push({ 
                            items: ["Suggested", "Planned"],
                            sourceColumn: "status",
                            operator: "InList"
                        });

                        scope.filterPreferences = filterList;
                        return iFilter;
                    }

                    function hasValuesInListFilter(property) {
                        return function (item) {
                            if (item[property] && item[property].length > 0) return true;
                            return false;
                        }
                    }
                    function getFilteredListByAssets(list, assets) {
                        if (assets.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var suggestion = list[i];
                            for (var j = 0; j < assets.length; j++) {
                                var asset = assets[j];
                                if (suggestion.assetId === asset.id) {
                                    filteredList.push(suggestion);
                                }
                            }
                        }
                        return filteredList;
                    }
                    function getFilteredListByProjects(list, projects) {
                        if (projects.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var suggestion = list[i];
                            for (var j = 0; j < projects.length; j++) {
                                var project = projects[j];
                                if (suggestion.projectId === project.id) {
                                    filteredList.push(suggestion);
                                }
                            }
                        }
                        return filteredList;
                    }
                    function getFilteredListByGoals(list, goals) {
                        if (goals.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var suggestion = list[i];
                            for (var j = 0; j < goals.length; j++) {
                                var goal = goals[j];
                                if(suggestion.goals){
                                    for (var k = 0; k < suggestion.goals.length; k++) {
                                        if (suggestion.goals[k].strategyGoalId  === goal.strategyGoalId) {
                                            filteredList.push(suggestion);
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                        return filteredList;
                    }
                    function getFilteredListByCategories(list, categories) {
                        if (categories.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var suggestion = list[i];
                            for (var j = 0; j < categories.length; j++) {
                                var category = categories[j];
                                if (suggestion.categoryId === category.id) {
                                    filteredList.push(suggestion);
                                }
                            }
                        }
                        return filteredList;
                    }
                }
            }
        }]);

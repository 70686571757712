﻿import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import "./livetrend.css"
import * as staticFileService from "@common/shared/staticFileService"

angular
    .module('DigiLean')
    .directive("liveTrend", ['dataFilterService', 'dataService', '$uibModal', 'orderByFilter',
        function (dataFilterService, dataService, $uibModal, orderByFilter) {
            return {
                templateUrl: 'livetrend.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'settingsChangeHandler': '&',
                    'layoutFactor': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.theme = getDefaultAppTheme()
                    scope.themeChangedEvent = function($event) {
                        scope.theme = $event.detail
                        scope.updateSettings()
                    }
                    
                    scope.appStyle = getDefaultAppStyle()
                    function calcStyles() {
                        scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                    }
                    calcStyles()
                    scope.$watch('layoutFactor', calcStyles)
                    
                    scope.title = ""
                    scope.titleChangedEvent = function($event) {
                        scope.title = $event.detail
                        scope.updateSettings()
                    }

                    scope.timeframe = "day";
                    scope.filters = [];


                    // Dashboard Settings handling
                    scope.$watch('settings', function (settings) {
                        // apply
                        if (settings) {
                            scope.dataSource = settings.dataSource;
                            if (settings.timeframe) {
                                scope.timeframe = settings.timeframe;
                            }
                            if (settings.filters) {
                                scope.filters = settings.filters;
                            }
                            if (settings.theme) {
                                scope.theme = settings.theme;
                            }
                            drawChart(scope.dataSource);
                            loadValuesForDataSource(scope.dataSource.id);
                        }
                    });

                    scope.imageUrl = staticFileService.image('livetrend.jpg');
                    scope.currentValue = null;
                    scope.previousValue = null;
                    scope.valueChange = {
                        change: 0,
                        isPositive: false
                    }

                    scope.$on("widget-resized", function (event, args) {
                        resizeChart();
                    });

                    // Subscribe to component related event
                    scope.subscribe("DataValueAdded", function (value) {
                        if (!scope.dataSource) return;
                        if (scope.dataSource.id === value.dataSourceId && dataFilterService().matchesFilters(value, scope.filters)) {
                            updateValue(value, true);
                        }
                    });

                    scope.subscribe("DataValueUpdated", function (dataValue) {
                        if (dataValue.dataSourceId === scope.dataSource.id) {
                            loadValuesForDataSource(scope.dataSource.id);
                        }
                    });

                    scope.updateSettings = function () {
                        var componentSettings = {
                            timeframe: scope.timeframe,
                            dataSource: scope.dataSource,
                            theme: scope.theme
                        };
                        scope.settingsChangeHandler({ settings: componentSettings });
                    }
                    scope.updateSettings = function () {
                        var componentSettings = {
                            title: scope.title,
                            timeframe: scope.timeframe,
                            dataSource: scope.dataSource,
                            filters: scope.filters,
                            theme: scope.theme
                        };
                        scope.settingsChangeHandler({ settings: componentSettings });
                    };

                    scope.selectDataSource = function () {
                        var hasDataSerie = false;
                        if (scope.dataSource) {
                            hasDataSerie = true;
                        }
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'dataSourceSingleSelector.html',
                            controller: 'dataSourceSingleSelector',
                            resolve: {
                                hasDataSerie: function () {
                                    return hasDataSerie;
                                },
                                dataSource: function () {
                                    return scope.dataSource;
                                },
                                filters: function () {
                                    return scope.filters;
                                },
                                withTarget: function () {
                                    return false;
                                },
                                type: function () {
                                    return null;
                                },
                                externalOnly: function () {
                                    return true;
                                }

                            }
                        });

                        modalInstance.result.then(function (result) {
                            if (result.dataSource && (hasDataSerie == false || scope.dataSource.id != result.dataSource.id)) {
                                scope.title = result.dataSource.title;
                            }
                            scope.dataSource = result.dataSource;
                            scope.filters = result.filters;
                            scope.updateSettings();
                            drawChart(scope.dataSource);
                            loadValuesForDataSource(scope.dataSource.id);
                        });
                    };

                    scope.changeTheme = function (theme) {
                        scope.theme = theme;
                        scope.updateSettings();
                    }

                    var loadValuesForDataSource = function (dataSourceId) {
                        var options = {
                            numberOfValues: 50,
                            sortOrder: "DESC",
                            filters: scope.filters
                        }
                        dataService().getLatestValues(dataSourceId, options).then(function (values) {
                            if (values) {
                                // Make sure values are sorted by date asc
                                values = orderByFilter(values, function (value) {
                                    return moment(value.valueDate).valueOf();
                                }, false);
                                drawValues(values);
                                if (values.length > 0) {
                                    updateValue(values[values.length - 1], false);
                                }
                            }
                        });
                    }

                    // element to update
                    var updateValue = function (newValue, shouldTrendBeUpdated) {
                        scope.previousValue = scope.currentValue;
                        scope.currentValue = newValue;
                        if (scope.previousValue) {
                            var change = scope.currentValue.value - scope.previousValue.value;
                            if (change > 0) change = "+" + change;
                            var isPostiveChange = scope.currentValue.value > scope.previousValue.value;
                            var animateClass = "animated bounce";
                            if (!isPostiveChange) animateClass = "animated headShake";
                            scope.valueChange = {
                                change: change,
                                isPositive: isPostiveChange,
                                animateClass: animateClass
                            }
                            setTimeout(function () { scope.valueChange.animateClass = "" }, 900);
                        }
                        if (shouldTrendBeUpdated) {
                            updateTrend(newValue);
                        }
                        else {
                            setTimeout(function () {
                                resizeChart()
                            }, 900)
                        }
                    }



                    let chart;
                    var updateTrend = function (newDataValue) {
                        var series = chart.series[0],
                            shift = series.data.length > 200; // shift if the series is longer than 200
                        var point = [moment(newDataValue.valueDate).valueOf(), newDataValue.value];
                        // add the point
                        chart.series[0].addPoint(point, true, shift);
                    }

                    var drawValues = function (values) {

                        // map dataset
                        var data = values.map(function (dataValue) {
                            var valueDate = moment(dataValue.valueDate).valueOf()
                            return [valueDate, dataValue.value]
                        })
                        chart.series[0].setData(data)
                    }

                    function drawChart(dataSource) {
                        var renderElement = $(elem).find(".livetrendChart")[0];
                        chart = new Highcharts.Chart({
                            chart: {
                                renderTo: renderElement,
                                defaultSeriesType: 'spline',
                                style: {
                                    fontSize: "0.9em"
                                }
                            },
                            title: {
                                text: ''
                            },
                            credits: {
                                enabled: false //remove link to highcharts.com
                            },
                            exporting: {
                                enabled: false //remove context menu
                            },
                            xAxis: {
                                type: 'datetime',
                                tickPixelInterval: 150,
                                maxZoom: 20 * 1000,
                                labels: {
                                    style: {
                                        fontSize: "0.9em"
                                    }
                                }
                            },
                            yAxis: {
                                minPadding: 0.2,
                                maxPadding: 0.2,
                                title: {
                                    text: ''
                                },
                                labels: {
                                    style: {
                                        fontSize: "0.9em"
                                    }
                                }
                            },
                            series: [{
                                name: dataSource.title,
                                findNearestPointBy: 'xy',
                                data: []
                            }]
                        });
                        resizeChart();
                    };
                    
                    function resizeChart() {
                        if (!elem) return
                        let appEl = elem[0]

                        let width = appEl.offsetWidth
                        let height = appEl.offsetHeight

                        let headerEl = appEl.querySelector(".app-header")
                        if (headerEl)
                            height = height - headerEl.offsetHeight

                        let liveTrendValueEl = appEl.querySelector(".live-trend-value")
                        if (liveTrendValueEl)
                            height = height - liveTrendValueEl.offsetHeight
                        
                        var chartContainer = $(elem).find(".highcharts-container").parent()
                        if (chartContainer.length === 0) return

                        if (chart)
                            chart.setSize(width, height, true)
                    }
                }
            }
        }]);

﻿import envConfig from "@common/envConfig";

angular.module("DigiLean").factory('notificationService', ['$http',
    function ($http) {
        function notificationService() {
            var resource = "/notifications";
            var resourceUrl = envConfig.webApiUrl + resource;
            return {
                getNotificationActions: function() {
                    return [
                        { action: "TaskAssigned", actionSymbol: "fad fa-user-tag", translateLabel: "NOTIFICATION_TASK_ASSIGNED" },
                        { action: "TaskComment", actionSymbol: "fad fa-comment-lines", translateLabel: "NOTIFICATION_TASK_COMMENT" },
                        { action: "TaskCommentReply",actionSymbol: "fad fa-reply", translateLabel: "NOTIFICATION_TASK_COMMENT_REPLY" },
                        { action: "TaskCommentMention", actionSymbol: "fad fa-at", translateLabel: "NOTIFICATION_TASK_COMMENT_MENTION" },
                        { action: "UpdatedResponsibleDeviation",actionSymbol: "fad fa-user-tag", translateLabel: "NOTIFICATION_UPDATED_RESPONSIBLE_DEVIATION" },
                        { action: "NewDeviation",actionSymbol: "fad fa-exclamation-triangle", translateLabel: "NOTIFICATION_DEVIATION_NEW" },
                        { action: "DeviationComment",actionSymbol: "fad fa-comment-lines", translateLabel: "NOTIFICATION_DEVIATION_COMMENT" },
                        { action: "DeviationCommentReply",actionSymbol: "fad fa-reply", translateLabel: "NOTIFICATION_DEVIATION_COMMENT_REPLY" },
                        { action: "DeviationCommentMention",actionSymbol: "fad fa-at", translateLabel: "NOTIFICATION_DEVIATION_COMMENT_MENTION" },
                        { action: "UpdatedStatusDeviation",actionSymbol: "fad fa-sync-alt", translateLabel: "DEVIATION_NOTIFICATION_UPDATED_STATUS" },
                        { action: "UpdatedResponsibleSuggestion", actionSymbol: "fad fa-user-tag", translateLabel: "NOTIFICATION_UPDATED_RESPONSIBLE_IMPROVEMENT" },
                        { action: "ImprovementCommentMention",actionSymbol: "fad fa-at", translateLabel: "NOTIFICATION_IMPROVEMENT_COMMENT_MENTION" },
                        { action: "ImprovementCommentReply",actionSymbol: "fad fa-reply", translateLabel: "NOTIFICATION_IMPROVEMENT_COMMENT_REPLY" },
                        { action: "ImprovementComment",actionSymbol: "fad fa-comment-lines", translateLabel: "NOTIFICATION_IMPROVEMENT_COMMENT" },
                        { action: "ImprovementChangedStatus",actionSymbol: "fad fa-sync-alt", translateLabel: "IMPROVEMENT_NOTIFICATION_SUGGESTION_STATUS" },
                        { action: "PageCommentAdded", actionSymbol: "fad fa-comment-lines", translateLabel: "NOTIFICATION_PAGE_COMMENT" },
                        { action: "ProjectMessageAdded", actionSymbol: "fad fa-calendar-alt", translateLabel: "PROJECT_NOTIFICATION_MESSAGE_ADDED" },
                        { action: "LicensesChanged", actionSymbol: "fad fa-money-check-edit-alt", translateLabel: "NOTIFICATION_CUSTOMER_LICENSE_CHANGE" },
                        
                    ]
                },
                archiveNotification: function (id) {
                    var url = resourceUrl + "/archiveNotification/" + id;
                    return $http.put(url).then(function (result) {
                        return result.data;
                    });
                },
                seenNotification: function (id) {
                    var url = resourceUrl + "/seenNotification/" + id;
                    return $http.put(url).then(function (result) {
                        return result.data;
                    });
                },
                markAllAsSeen: function (id) {
                    var url = resourceUrl + "/markAllAsSeen";
                    return $http.put(url).then(function (result) {
                        return result.data;
                    });
                },
                getUnseenNotificationsCount: function () {
                    var url = resourceUrl + "/getUnseenNotificationsCount";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getMyNotifications: function (options) {
                    var url = resourceUrl + "/getMyNotifications";
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },
                getMyLatestNotifications: function (numberOfValues) {
                    var url = resourceUrl + "/getMyLatestNotifications/" + numberOfValues;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getActivityForNotification: function (activityLogItemId) {
                    var url = resourceUrl + "/getActivityForNotification/" + activityLogItemId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getNotificationsOverview: function () {
                    var url = resourceUrl + "/getNotificationsOverview";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getMyNotificationSettings: function () {
                    var url = resourceUrl + "/getMyNotificationSettings";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                addNotificationSettings: function (list) {
                    var url = resourceUrl + "/addNotificationSettings";
                    return $http.post(url, list).then(function (result) {
                        return result.data;
                    });
                },

                deleteNotificationSettings: function () {
                    var url = resourceUrl + "/deleteNotificationSettings/";
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
            }
        }
        return notificationService;
    }]);
﻿import * as employeeService from "@common/services/employeeService"
import tr from "@common/thirdparty/fullcalendar/locale/tr";

angular
    .module('DigiLean')
    .directive("priorityMatrix", ['$filter', 'authService', '$uibModal', 'suggestionService',
        function ($filter, authService, $uibModal, suggestionService) {
            return {
                templateUrl: 'priorityMatrix.html',
                restrict: 'E',
                scope: {
                    'filterSource': '@',
                    'widgetSettingsFilter': '<',
                    'filterChangedHandler': '&',
                    'context': '@'
                },
                link: function (scope, elem, attrs) {
                    scope.userid = authService.getCurrentUser().userId;

                    // if page is refreshed we need to refresh the access to modules
                    scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                        scope.userid = profile.user.id;
                    });

                    scope.filter = null;
                    scope.$watch('filterSource', function (filterSource) {
                        if (filterSource == "userpreference") {
                            employeeService.getUserPreference("ImprovementPriorityFilter").then(function (filterpreferences) {
                                if (filterpreferences && filterpreferences.value) {
                                    scope.filter = filterpreferences.value;
                                }
                            });
                        }
                    });
                    scope.$watch('widgetSettingsFilter', function (filter) {
                        if (scope.filterSource == "widget" && filter) {
                            scope.filter = filter;
                        }
                        else if (scope.filterSource == "widget" && filter == null) {
                            scope.filter = [];
                        }
                    });
                    scope.filterChanged = function (filter) {
                        if (scope.filterSource == "widget") {
                            // Pass on filter to widget
                            scope.filterChangedHandler({ filter: filter });
                        } else {
                            if (!filter) { // Clear filter
                                employeeService.deleteUserPreference("ImprovementPriorityFilter").then(function () {
                                });
                            } else { // update
                                employeeService.updateUserPreference("ImprovementPriorityFilter", filter).then(function () {

                                });
                            }
                        }
                    }
                    // Get all 3 years back
                    var startDate = moment().subtract(36, 'month').startOf("month");
                    var endDate = moment().endOf('month');
                    scope.timePeriod = {
                        from: startDate.utc().toDate().toJSON(),
                        to: endDate.utc().toDate().toJSON()
                    }


                    //those counts are used to show the number of suggestions in the matrix in the GUI
                    scope.suggestionsCount = 0;
                    scope.suggestionStatus1Count = 0;
                    scope.suggestionStatus2Count= 0;
                    scope.suggestionStatus3Count= 0;
                    scope.suggestionStatus4Count= 0;

                    scope.suggestionsList = [];
                    scope.suggestionStatus1 = [];
                    scope.suggestionStatus2 = [];
                    scope.suggestionStatus3 = [];
                    scope.suggestionStatus4 = [];
                    var lastComment = null;


                    // UI SORTABLE => Drag and drop, SuggestionList
                    scope.suggestionSortableOptions = {
                        connectWith: ".suggestioncontainer",
                        receive: changePriorityStatus
                    };
                    // Priority matrix options pr 
                    scope.matrixSortableOptions = {
                        connectWith: "#suggestionList, .suggestioncontainer",
                        receive: changePriorityStatus
                    };

                    function changePriorityStatus(e, ui) {
                        var target = ui.item.sortable.droptarget[0];
                        var prioritystatus = $(target).attr("data-prioritystatus");
                        var source = ui.item.sortable.source[0];
                        var sourceStatus = $(source).attr("data-prioritystatus");
                        var suggestion = ui.item.sortable.model;
                        // alert("from priority: " + sourceStatus + " to: "  + prioritystatus);
                        var status = "Suggested";
                        if (prioritystatus > 0) status = "Planned";
                        var statusData = {
                            suggestionId: suggestion.id,
                            status: status,
                            priorityStatus: parseInt(prioritystatus)
                        };
                        suggestionService().changePriorityStatus(statusData).then(function () {
                            // if status also has changed we need to notify

                            if (suggestion.status !== statusData.status) {
                                
                                suggestionService().updateStatus(statusData).then(function () {
                                });
                            }
                        });
                    };

                    scope.subscribe("ImprovementLikeAdded", function (like) {
                       var foundSuggestion = scope.suggestionsList.find(s => s.id === like.improvementSuggestionId);
                       if(foundSuggestion){
                        foundSuggestion.likeCount++
                        foundSuggestion.userLiked = true;
                          }
                    });          
                    
                    scope.subscribe("ImprovementLikeRemoved", function (like) {
                        var foundSuggestion = scope.suggestionsList.find(s => s.id === like.improvementSuggestionId);
                        if(foundSuggestion){
                            foundSuggestion.likeCount--;
                            foundSuggestion.userLiked = false;
                        }
                    });
                    
                    scope.subscribe("SuggestionCommentedOn", function (comment) {
                       var foundSuggestion = scope.suggestionsList.find(s => s.id === comment.improvementSuggestionId);
                       if(foundSuggestion && lastComment !== comment){
                            foundSuggestion.commentCount++;
                            lastComment = comment;
                        }
                    });                    
                    
                    scope.subscribe("SuggestionCommentDeleted", function (comment) {
                        var foundSuggestion = scope.suggestionsList.find(s => s.id === comment.improvementSuggestionId);
                        if(foundSuggestion){
                             foundSuggestion.commentCount--;
                         }
                     }); 

                    scope.subscribe("SuggestionAttachmentDeleted", function (attachment) {
                       var foundSuggestion = scope.suggestionsList.find(s => s.id === attachment.improvementSuggestionId);
                       if(foundSuggestion){
                            foundSuggestion.attachmentCount--;
                        }
                    });

                    scope.subscribe("SuggestionAttachmentAdded", function (attachment) {
                        var foundSuggestion = scope.suggestionsList.find(s => s.id === attachment.improvementSuggestionId);
                        if(foundSuggestion){
                             foundSuggestion.attachmentCount++;
                         }
                     });

                    scope.subscribe("ImprovementTitleChanged", function (improvementTitle) {
                        var existingSuggestions = $filter('filter')(scope.suggestionsList, { id: improvementTitle.suggestionId }, true);
                        if (existingSuggestions.length === 1) {
                            var index = scope.suggestionsList.indexOf(existingSuggestions[0]);
                            scope.suggestionsList[index].title = improvementTitle.newText;
                            scope.suggestionsList[index].cssClass = "animated bounce";
                        } else {
                            existingSuggestions = $filter('filter')(scope.suggestionStatus1, { id: improvementTitle.suggestionId }, true);
                            if (existingSuggestions.length === 1) {
                                var index = scope.suggestionStatus1.indexOf(existingSuggestions[0]);
                                scope.suggestionStatus1[index].title = improvementTitle.newText;
                                scope.suggestionStatus1[index].cssClass = "animated bounce";
                            } else {
                                existingSuggestions = $filter('filter')(scope.suggestionStatus2, { id: improvementTitle.suggestionId }, true);
                                if (existingSuggestions.length === 1) {
                                    var index = scope.suggestionStatus2.indexOf(existingSuggestions[0]);
                                    scope.suggestionStatus2[index].title = improvementTitle.newText;
                                    scope.suggestionStatus2[index].cssClass = "animated bounce";
                                } else {
                                    existingSuggestions = $filter('filter')(scope.suggestionStatus3, { id: improvementTitle.suggestionId }, true);
                                    if (existingSuggestions.length === 1) {
                                        var index = scope.suggestionStatus3.indexOf(existingSuggestions[0]);
                                        scope.suggestionStatus3[index].title = improvementTitle.newText;
                                        scope.suggestionStatus3[index].cssClass = "animated bounce";
                                    } else {
                                        existingSuggestions = $filter('filter')(scope.suggestionStatus4, { id: improvementTitle.suggestionId }, true);
                                        if (existingSuggestions.length === 1) {
                                            var index = scope.suggestionStatus4.indexOf(existingSuggestions[0]);
                                            scope.suggestionStatus4[index].title = improvementTitle.newText;
                                            scope.suggestionStatus4[index].cssClass = "animated bounce";
                                        }
                                    }   
                                }
                            }
                        }
                    });

                    scope.subscribe("ImprovementResponsibleChanged", function (improvementResponsible) {
                        // find suggestion by id
                        var existingSuggestions = $filter('filter')(scope.new.list, { id: improvementResponsible.suggestionId }, true);
                        if (existingSuggestions.length === 1) {
                            var index = scope.new.list.indexOf(existingSuggestions[0]);
                            scope.new.list[index].responsibleUserId = improvementResponsible.newResponsibleUserId;
                            scope.new.list[index].responsibleUser = improvementResponsible.newResponsibleUser;
                            scope.new.list[index].cssClass = "animated bounce"; // Add animation

                            var existingfilteredSuggestions = $filter('filter')(scope.suggestionsList, { id: improvementResponsible.suggestionId }, true);
                            if (existingfilteredSuggestions.length === 1) {
                                var index = scope.suggestionsList.indexOf(existingfilteredSuggestions[0]);
                                scope.suggestionsList[index].responsibleUserId = improvementResponsible.newResponsibleUserId;
                                scope.suggestionsList[index].responsibleUser = improvementResponsible.newResponsibleUser;
                                scope.suggestionsList[index].cssClass = "animated bounce"; // Add animation
                            }
                        }
                    });



                    function openSuggestionModal(suggestionInfo, activeTab) {
                        suggestionService().get(suggestionInfo.id).then(function (suggestion) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                animation: scope.animationsEnabled,
                                templateUrl: 'fullImprovementInfoForm.html',
                                controller: 'fullImprovementModalController',
                                windowClass: 'fullSuggestion-modal-window',
                                resolve: {
                                    suggestion: function () {
                                        return suggestion;
                                    },
                                    activeTab: function () {
                                        return activeTab;
                                    }
                                }
                            });
                            modalInstance.result.then(function (updatedSuggestion) {
                                suggestion.roi = updatedSuggestion.roi;
                            });
                        });
                    }

                    function removeAnmiation(suggestion) {
                        setTimeout(function () {
                            suggestion.cssState = "";
                        }, 1000);
                    }

                    scope.filterById = function (value) {
                        return (scope.filterValues.indexOf(value.id) !== -1);
                    };


                    scope.suggestionOpenForEdit = function (suggestionInfo) {
                        openSuggestionModal(suggestionInfo, "Info");
                    }


                    scope.showComments = function (suggestionInfo) {
                        openSuggestionModal(suggestionInfo, "Comments");
                    }

                    scope.showAttachments = function (suggestionInfo) {
                        openSuggestionModal(suggestionInfo, "Attachments");
                    }

                    scope.likedByMe = function (suggestion) {
                        if (suggestion.likes) {
                            for (var i = 0; i < suggestion.likes.length; i++) {
                                if (suggestion.likes[i].likedByUserId === scope.userid) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    }

                    scope.commentedByMe = function (suggestion) {
                        if (suggestion.comments) {
                            for (var i = 0; i < suggestion.comments.length; i++) {
                                if (suggestion.comments[i].commentedByUserId === scope.userid) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    }

                    scope.addComment = function (suggestion) {
                        if (typeof suggestion.comments === 'undefined') {
                            suggestion.comments = [];
                        }

                        var currentComment = {
                            suggestionId: suggestion.id,
                            comment: suggestion.activeComment
                        };
                        suggestionService().addComment(currentComment).then(function (savedComment) {
                            suggestion.comments.push(savedComment);
                        });
                        suggestion.activeComment = "";
                    }

                    scope.like = function (item) {
                        //item.isLiked = !item.isLiked;
                        if (item.userLiked === false) {
                            suggestionService().addLike(item.id).then(function (like) {
                                // Like event will increase counter
                            });
                        } else {
                            suggestionService().removeLike(item.id).then(function () {
                            });
                        }
                    }


                    // Add suggestion
                    scope.newItem = function () {
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newSuggestionForm.html',
                            controller: 'newSuggestionModalController',
                            resolve: {
                                suggestion: function () {
                                    return {
                                        id: 0,
                                        title: "",
                                        text: "",
                                        suggestedBy: "",
                                        suggestionDate: new Date(),
                                        showComments: false,
                                        likes: [],
                                        comments: [],
                                    }
                                }
                            }
                        });
                        // When user has created new suggestion
                        modalInstance.result.then(function (suggestion) {
                            suggestionService().addSuggestion(suggestion).then(function (savedsuggestion) {
                            });
                        }, function () {
                            //$log.info('Modal dismissed at: ' + new Date());
                        });

                    };
                }
            }

        }]);
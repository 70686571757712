import * as timeService from "@common/services/timeService"
import envConfig from "@common/envConfig";

angular.module("DigiLean").factory('dataService', ['$http',
    function ($http) {
        function dataService() {
            var resource = "/values";
            var resourceUrl = envConfig.webApiUrl + resource;

            return {
                add: function (id, value) {
                    var url = resourceUrl + "/Add/" + id;
                    return $http.post(url, value).then(function (result) {
                        return result.data;
                    });
                },

                addList: function (id, values) {
                    var url = resourceUrl + "/AddList/" + id;
                    return $http.post(url, values).then(function (result) {
                        return result.data;
                    });
                },

                update: function (id, value) {
                    var url = resourceUrl + "/update/" + id;
                    return $http.put(url, value).then(function (result) {
                        return result.data;
                    });
                },

                delete: function (id) {
                    var url = resourceUrl + "/Delete/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                deleteAllValues: function (id) {
                    var url = resourceUrl + "/DeleteAllValues/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                getLatestValues: function (id, options) {
                    var url = resourceUrl + "/GetLatestValues/" + id;
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },

                getTableValues: function (id, tableParams) {
                    var url = resourceUrl + "/getTableValues/" + id;
                    return $http.post(url, tableParams).then(function (result) {
                        return result.data;
                    });
                },

                getValuesForPeriod: function (id, options) {
                    var url = resourceUrl + "/GetValuesForPeriod/" + id;
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },

                getSumForPeriod: function (id, options) {
                    var url = resourceUrl + "/getSumForPeriod/" + id;
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },

                getNumberOfValuesForPeriod: function (id, options) {
                      var url = resourceUrl + "/getNumberOfValuesForPeriod/" + id;
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },

                getGroupedSumForPeriod: function (id, options) {
                    var url = resourceUrl + "/GetGroupedSumForPeriod/" + id;
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },

                getStackedGroupedSum: function (id, options) {
                    var url = resourceUrl + "/getStackedGroupedSum/" + id;
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },

                getTargetValuesForPeriod: function(id, options){
                    var url = resourceUrl + "/GetTargetValuesForPeriod/" + id;
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },

                getManualTargetValuesForPeriod: function(id, options){
                    var url = resourceUrl + "/GetManualTargetValuesForPeriod/" + id;
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },

                getFirstDate: function (id, timePeriod, filter) {
                    var url = resourceUrl + "/GetFirstDate/" + id;
                    var query = {
                        timeZone: timeService.getUserTimeZone(), // Which timezone we would like the result to be calculated in.
                        timePeriod: timePeriod,
                        filters: filter
                    }
                    return $http.post(url, query).then(function (result) {
                        return result.data;
                    });
                },

                getLastDate: function (id, timePeriod, filter) {
                    var url = resourceUrl + "/getLastDate/" + id;
                    var query = {
                        timeZone: timeService.getUserTimeZone(), // Which timezone we would like the result to be calculated in.
                        timePeriod: timePeriod,
                        filters: filter
                    }
                    return $http.post(url, query).then(function (result) {
                        return result.data;
                    });
                },
                
                getAverageForPeriod: function (id, timePeriod) {
                    var url = resourceUrl + "/getAverageForPeriod/" + id;
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                }


            }
        }
        return dataService;
    }]);
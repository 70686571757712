angular.module('DigiLean').factory('dataFilterService', ['$filter',
    function ($filter) {
        function dataFilterService() {

            function inList(value, list) {
                for (let index = 0; index < list.length; index++) {
                    const element = list[index];
                    if (value.toString() == element.toString()) {
                        return true;
                    }
                } 
                return false;
            }

            function matchesFilter(value, filter) {
                let propertyValue = value[filter.sourceColumn];
            
                // Handle undefined propertyValue
                if (propertyValue === undefined) {
                    if (filter.sourceColumn === "Goals.StrategyGoalId" && value.goals) {
                        propertyValue = value.goals.map(goal => goal.id);
                        return filter.items.some(item => propertyValue.includes(item));
                    }
                    return false;
                }
            
                // Handle filter operators
                switch (filter.operator) {
                    case "InList":
                        return Array.isArray(filter.items) && filter.items.includes(propertyValue);
                    default:
                        console.warn(`Unhandled filter operator: ${filter.operator}`);
                        return false; // Default to false for unhandled operators
                }
            }
            return {
                /* value = DataValue, filters = Array[FilterElment] */
                matchesFilters: function (value, filters) {
                    if (!filters) return true;
                    if (filters.length == 0) return true;
                    var status = true;
                    filters.forEach(element => {
                        if (!matchesFilter(value, element)) {
                            status = false;
                        } 
                    });
                    return status;
                },


            };
        }
        return dataFilterService;
    }]);

